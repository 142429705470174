import React, { useState } from 'react';
// @material-ui/core components
// @material-ui/icons
// core components
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/analyticsPage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import config from '../../config/config.json';
import Tooltip from '@mui/material/Tooltip';
import NavPills from '../../components/NavPills/NavPills';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SupplierAnalytics } from './components/SupplierAnalytics';
import { SellerAnalytics } from './components/SellerAnalytics';
import { NodeAnalytics } from './components/NodeAnalytics';
import MuleBubble from 'components/MuleBubble/MuleBubble';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles(styles);
export default function MehlbotSalesHistoryPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.analytics'));
  const { ...rest } = props;

  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  //hover
  function renderCellFunc(params) {
    let path = 'https://d2r-member.com/image/' + params.row.image_path;
    return (
      <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
        <div>{params.row.name}</div>
      </Tooltip>
    );
  }
  renderCellFunc.displayName = 'Cell Name';

  function renderCellFunc2(params) {
    let path = 'https://d2r-member.com/image/' + params.row.image_path;
    return (
      <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
        <div>{params.row.stats_description}</div>
      </Tooltip>
    );
  }
  renderCellFunc2.displayName = 'Cell Description';

  const [permSalesHistoryDataFGOwnedByMe, setPermSalesHistoryDataFGOwnedByMe] = useState([]);
  const [permSalesHistoryDataFGSoldByMe, setPermSalesHistoryDataFGSoldByMe] = useState([]);
  const [permSalesHistoryDataFGNodeByMe, setPermSalesHistoryDataFGNodeByMe] = useState([]);
  const [permSalesHistoryDataFiatSoldByMe, setPermSalesHistoryDataFiatSoldByMe] = useState([]);
  const [permSalesHistoryDataFiatOwnedByMe, setPermSalesHistoryDataFiatOwnedByMe] = useState([]);
  const [permSalesHistoryDataFiatNodeByMe, setPermSalesHistoryDataFiatNodeByMe] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  async function getPermSalesHistory() {
    const fetchingNotification = toast.loading(t('common.loading.fetchingData'));

    let url = config.backend_base_url + 'perm/sales/' + season;
    try {
      const response = await axios.get(url, { headers: { 'session-token': sessionToken } });

      if (response.data != null && response.data.items.constructor === Array) {
        const requestBy = response.data.request_by.toLowerCase();
        const fgData = response.data.items.filter((item) => item.currency_sold === 'fg');
        setPermSalesHistoryDataFGOwnedByMe(
          fgData.filter((item) => requestBy === item.shareholder_name.toLowerCase())
        );
        setPermSalesHistoryDataFGSoldByMe(
          fgData.filter((item) => requestBy === item.reseller_name.toLowerCase())
        );
        setPermSalesHistoryDataFGNodeByMe(
          fgData.filter((item) => requestBy === item.node_host_name.toLowerCase())
        );

        const fiatData = response.data.items.filter((item) => item.currency_sold !== 'fg');
        setPermSalesHistoryDataFiatOwnedByMe(
          fiatData.filter((item) => requestBy === item.shareholder_name.toLowerCase())
        );
        setPermSalesHistoryDataFiatSoldByMe(
          fiatData.filter((item) => requestBy === item.reseller_name.toLowerCase())
        );
        setPermSalesHistoryDataFiatNodeByMe(
          fiatData.filter((item) => requestBy === item.node_host_name.toLowerCase())
        );
      }
    } catch (error) {
      console.log('Error ========>', error);
      toast.error(t('common.errors.noDataFound'));
    }
    toast.dismiss(fetchingNotification);
  }

  async function getAvailableSeasons() {
    const loading = toast.loading(t('analytics.loading.availableSeasons'));
    let url = config.backend_base_url + 'seasons';
    try {
      const response = await axios.get(url, { headers: { 'session-token': sessionToken } });
      toast.dismiss(loading);
      if (response.data != null && response.data.constructor === Array) {
        setAvailableSeasons(response.data);
        setSeason(response.data[response.data.length - 1]);
      } else {
        setAvailableSeasons([]);
      }
    } catch (error) {
      toast.dismiss(loading);
      console.log('Error ========>', error);
      toast.error(t('analytics.errors.noSeasonsFound'));
    }
  }

  async function getProfile() {
    await axios
      .get(config.backend_base_url + 'profile', { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          //what to do
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('common.errors.refreshPage'));
      });
  }

  const [availableSeasons, setAvailableSeasons] = useState([0]);
  const [season, setSeason] = useState(0);
  const [oldSeason, setOldSeason] = useState(season);
  React.useEffect(() => {
    if (oldSeason !== season) {
      setOldSeason(season);
      getPermSalesHistory();
    }
  });

  if (loadingData) {
    const loading = toast.loading(t('analytics.loading.availableSeasons'));
    getAvailableSeasons();
    getProfile();
    setLoadingData(false);
    toast.dismiss(loading);
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            position: 'relative',
            maxWidth: '80vw',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 2,
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            fullWidth
            onChange={function () {}}
            color="primary"
            tabs={[
              {
                tabName: t('analytics.tabs.mySalesAnalytics'),
                tabButton: t('analytics.tabs.mySalesAnalytics'),
                tabContent: (
                  <div>
                    <Select
                      id="rerouteTradeNewRealm"
                      fullWidth={true}
                      value={season}
                      onChange={(params) => {
                        setSeason(params.target.value);
                      }}
                    >
                      {availableSeasons.map((season, key) => (
                        <MenuItem
                          key={key}
                          selected={key + 1 === availableSeasons.length}
                          value={season}
                        >
                          {t('salesHistory.season', { number: season })}
                        </MenuItem>
                      ))}
                    </Select>
                    <SellerAnalytics
                      analyticsData={[
                        ...permSalesHistoryDataFGSoldByMe,
                        ...permSalesHistoryDataFiatSoldByMe,
                      ]}
                      classes={classes}
                    />
                  </div>
                ),
              },
              {
                tabName: t('analytics.tabs.mySupplyAnalytics'),
                tabButton: t('analytics.tabs.mySupplyAnalytics'),
                tabContent: (
                  <div>
                    <Select
                      id="rerouteTradeNewRealm"
                      fullWidth={true}
                      value={season}
                      onChange={(params) => {
                        setSeason(params.target.value);
                      }}
                    >
                      {availableSeasons.map((season, key) => (
                        <MenuItem
                          key={key}
                          selected={key + 1 === availableSeasons.length}
                          value={season}
                        >
                          {t('salesHistory.season', { number: season })}
                        </MenuItem>
                      ))}
                    </Select>
                    <SupplierAnalytics
                      analyticsData={[
                        ...permSalesHistoryDataFGOwnedByMe,
                        ...permSalesHistoryDataFiatOwnedByMe,
                      ]}
                      classes={classes}
                      isLoading={loadingData}
                    />
                  </div>
                ),
              },
              {
                tabName: t('analytics.tabs.myNodeAnalytics'),
                tabButton: t('analytics.tabs.myNodeAnalytics'),
                tabContent: (
                  <div>
                    <Select
                      id="rerouteTradeNewRealm"
                      fullWidth={true}
                      value={season}
                      onChange={(params) => {
                        setSeason(params.target.value);
                      }}
                    >
                      {availableSeasons.map((season, key) => (
                        <MenuItem
                          key={key}
                          selected={key + 1 === availableSeasons.length}
                          value={season}
                        >
                          {t('salesHistory.season', { number: season })}
                        </MenuItem>
                      ))}
                    </Select>
                    <NodeAnalytics
                      analyticsData={[
                        ...permSalesHistoryDataFGNodeByMe,
                        ...permSalesHistoryDataFiatNodeByMe,
                      ]}
                      classes={classes}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <MuleBubble hasBottomBubble={false} />
    </div>
  );
}
