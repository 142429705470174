import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config/config.json';
import {
  ProductTracker,
  CreateProductTrackerRequest,
  DeleteProductTrackerRequest,
  DeleteProductTrackerByProductRequest,
} from '../types/tracker';
import toast from 'react-hot-toast';

interface TrackedProductsState {
  items: ProductTracker[];
  isLoading: boolean;
  error: string | null;
}

const initialState: TrackedProductsState = {
  items: [],
  isLoading: false,
  error: null,
};

const trackedProductsSlice = createSlice({
  name: 'trackedProducts',
  initialState,
  reducers: {
    setTrackedProducts: (state, action: PayloadAction<ProductTracker[]>) => {
      state.items = action.payload;
    },
    addTrackedProduct: (state, action: PayloadAction<ProductTracker>) => {
      state.items.push(action.payload);
    },
    removeTrackedProduct: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    removeTrackedProductByProductId: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => Number(item.product_id) === action.payload);
    },
  },
});

export const {
  setTrackedProducts,
  addTrackedProduct,
  removeTrackedProduct,
  removeTrackedProductByProductId,
} = trackedProductsSlice.actions;

// Helper function to transform response data
function transformTrackerData(data: any[]): ProductTracker[] {
  return (data || []).map((item: any) => {
    if (!item.product_id) {
      throw new Error('Product tracker must have a product_id');
    }
    return {
      id: item.id,
      user_id: item.user_id,
      product_id: parseInt(item.product_id, 10),
      skin_image_path: item.skin_image_path,
      image_path: item.image_path,
      name: item.name,
      shop_product_link: item.shop_product_link,
      created_at: item.created_at,
    };
  });
}

// Helper function to check if a product is tracked
export function isProductTracked(trackedProducts: ProductTracker[], productId: number): boolean {
  return trackedProducts.some((item) => Number(item.product_id) === Number(productId));
}

// Tanstack Query Hooks
export function useTrackedProducts() {
  const sessionToken = localStorage.getItem('session_token');

  return useQuery({
    queryKey: ['trackedProducts'],
    queryFn: async () => {
      try {
        const response = await axios.get(`${config.backend_base_url}api/product/trackers`, {
          headers: { 'session-token': sessionToken },
        });

        if (!response.data) {
          return [];
        }

        const transformedData = transformTrackerData(response.data);
        return transformedData;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.error('Failed to fetch tracked products');
        }
        throw error;
      }
    },
    initialData: [],
    enabled: !!sessionToken,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: 2,
  });
}

export function useCreateProductTracker() {
  const queryClient = useQueryClient();
  const sessionToken = localStorage.getItem('session_token');

  return useMutation({
    mutationFn: async (data: CreateProductTrackerRequest) => {
      const payload = {
        ...data,
        product_id: Number(data.product_id),
      };

      const response = await axios.post(`${config.backend_base_url}api/product/tracker`, payload, {
        headers: { 'session-token': sessionToken },
      });
      return {
        ...response.data,
        product_id: parseInt(response.data.product_id, 10),
      };
    },
    onSuccess: (data) => {
      queryClient.setQueryData<ProductTracker[]>(['trackedProducts'], (oldData) => {
        const currentData = oldData || [];
        return [...currentData, data];
      });

      queryClient.invalidateQueries({ queryKey: ['trackedProducts'] });
      toast.success('Product tracked successfully');
    },
    onError: () => {
      toast.error('Failed to track product');
    },
  });
}

export function useDeleteProductTracker() {
  const queryClient = useQueryClient();
  const sessionToken = localStorage.getItem('session_token');

  return useMutation({
    mutationFn: async (data: DeleteProductTrackerRequest) => {
      const response = await axios.delete(`${config.backend_base_url}api/product/tracker`, {
        headers: { 'session-token': sessionToken },
        data,
      });
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData<ProductTracker[]>(['trackedProducts'], (oldData) => {
        const currentData = oldData || [];
        return currentData.filter((item) => item.id !== variables.id);
      });

      queryClient.invalidateQueries({ queryKey: ['trackedProducts'] });
      toast.success('Product untracked successfully');
    },
    onError: () => {
      toast.error('Failed to untrack product');
    },
  });
}

export function useDeleteProductTrackerByProduct() {
  const queryClient = useQueryClient();
  const sessionToken = localStorage.getItem('session_token');

  return useMutation({
    mutationFn: async (data: DeleteProductTrackerByProductRequest) => {
      const response = await axios.delete(
        `${config.backend_base_url}api/product/tracker/by-product?product_id=${data.product_id}`,
        {
          headers: { 'session-token': sessionToken },
        }
      );
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData<ProductTracker[]>(['trackedProducts'], (oldData) => {
        const currentData = oldData || [];
        return currentData.filter(
          (item) => Number(item.product_id) !== Number(variables.product_id)
        );
      });

      queryClient.invalidateQueries({ queryKey: ['trackedProducts'] });
      toast.success('Product untracked successfully');
    },
    onError: () => {
      toast.error('Failed to untrack product');
    },
  });
}

export default trackedProductsSlice.reducer;
