import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import styles from 'assets/jss/material-kit-react/components/navPillsStyle.js';

const useStyles = makeStyles(styles);

export default function NavPills(props) {
  const classes = useStyles();
  const { tabs, color, horizontal, alignCenter, queryKey, onChange } = props;

  const getInitialActiveTab = () => {
    const params = new URLSearchParams(window.location.search);
    const tabName = params.get(queryKey);
    let tabIndex = tabs.findIndex((tab) => {
      if (tab.tabName && tabName && tab.tabName.toLowerCase() === tabName.toLowerCase()) {
        return true;
      }

      if (tab.tabButton && tab.tabButton.type === 'span' && tab.tabButton.props.children) {
        let found = false;
        React.Children.forEach(tab.tabButton.props.children, (child) => {
          if (child && child.type === 'img' && child.props.alt === tabName) {
            found = true;
          }
        });
        return found;
      }

      return false;
    });

    return tabIndex === -1 ? props.active : tabIndex;
  };

  const [active, setActive] = React.useState(getInitialActiveTab());

  useEffect(() => {
    const handleUrlChange = () => {
      const params = new URLSearchParams(window.location.search);
      const tabName = params.get(queryKey);
      const tabIndex = tabs.findIndex((tab) => tab.tabName && tab.tabName === tabName);
      if (tabIndex !== -1) {
        setActive(tabIndex);
      }
    };
    window.addEventListener('popstate', handleUrlChange);
    handleUrlChange(); // Check the initial URL on mount
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, [tabs, queryKey]);

  const handleTabChange = (event, newActive) => {
    if (onChange) {
      onChange(event);
    }

    setActive(newActive);
    let newTabName = tabs[newActive].tabName;

    if (!newTabName) {
      const tabButton = tabs[newActive].tabButton;
      if (tabButton && tabButton.type === 'span' && tabButton.props.children) {
        React.Children.forEach(tabButton.props.children, (child) => {
          if (child && child.type === 'img' && child.props.alt) {
            newTabName = child.props.alt;
          }
        });
      }
    }

    const params = new URLSearchParams(window.location.search);
    if (newTabName) {
      params.set(queryKey, newTabName.toLowerCase() || '');

      // Remove all parameters that come after the specified queryKey
      let foundQueryKey = false;
      const keysToDelete = [];
      params.forEach((value, key) => {
        if (foundQueryKey) {
          keysToDelete.push(key);
        }
        if (key === queryKey) {
          foundQueryKey = true;
        }
      });

      keysToDelete.forEach((key) => {
        params.delete(key);
      });

      let origin = window.location.origin;
      if (origin.endsWith('/')) {
        origin = origin.slice(0, -1);
      }
      const newUrl = `${origin}${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
  };

  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });

  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }}
      value={active}
      onChange={handleTabChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined,
        });

        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color],
              wrapper: classes.tabWrapper,
            }}
          />
        );
      })}
    </Tabs>
  );

  const tabContent = (
    <div className={classes.contentWrapper}>
      {tabs.map((prop, key) => {
        if (key === active) {
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        }
        return null;
      })}
    </div>
  );

  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary',
  level: 1,
  queryKey: 'tab',
};

NavPills.propTypes = {
  active: PropTypes.number,
  level: PropTypes.number,
  queryKey: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node,
      tabName: PropTypes.string,
    })
  ).isRequired,
  color: PropTypes.oneOf(['primary', 'warning', 'danger', 'success', 'info', 'rose']),
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
  alignCenter: PropTypes.bool,
};
