import React from 'react';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import Button from 'components/CustomButtons/Button.js';
import { styled } from '@mui/material/styles';

const DropdownWrapper = styled('div')({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
});

const CaretSpan = styled('b')<{ isOpen?: boolean }>(({ isOpen }) => ({
  transition: 'all 150ms ease-in',
  display: 'inline-block',
  width: 0,
  height: 0,
  marginLeft: '4px',
  verticalAlign: 'middle',
  borderTop: '4px solid',
  borderRight: '4px solid transparent',
  borderLeft: '4px solid transparent',
  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const DropdownContent = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  bottom: '100%',
  transform: 'translateX(-50%)',
  left: '50%',
  marginBottom: '48px',
  minWidth: '160px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  zIndex: 1301,
}));

const useStyles = makeStyles<Theme>(() => ({
  popper: {
    position: 'absolute',
    zIndex: 1301,
    '&[data-popper-placement*="bottom"]': {
      transform: 'none !important',
    },
    '&[data-popper-placement*="top"]': {
      transform: 'none !important',
    },
  },
  show: {
    pointerEvents: 'auto',
  },
  menuList: {
    padding: '0',
  },
  buttonWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

interface LanguageSelectorDropdownProps {
  buttonText?: React.ReactNode;
  buttonProps?: any;
  dropdownList: React.ReactNode[];
  noLiPadding?: boolean;
}

export default function LanguageSelectorDropdown(props: LanguageSelectorDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseWithCallback = (callback?: () => void) => {
    setAnchorEl(null);
    if (callback) {
      callback();
    }
  };

  const { buttonText, buttonProps, dropdownList, noLiPadding } = props;

  return (
    <DropdownWrapper>
      <Button
        aria-label="Language Selector"
        aria-owns={anchorEl ? 'menu-list' : undefined}
        aria-haspopup="true"
        {...buttonProps}
        onClick={handleClick}
      >
        <span className={classes.buttonWrapper}>
          {buttonText}
          <CaretSpan isOpen={Boolean(anchorEl)} />
        </span>
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="top"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 48],
            },
          },
          {
            name: 'flip',
            enabled: false,
          },
        ]}
        className={classNames(classes.popper, {
          [classes.show]: Boolean(anchorEl),
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }} timeout={200}>
            <DropdownContent>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu" className={classes.menuList}>
                  {React.Children.map(dropdownList, (child, index) => {
                    if (!React.isValidElement(child)) return null;

                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          if (child.props.onClick) {
                            handleCloseWithCallback(child.props.onClick);
                          } else {
                            handleClose();
                          }
                        }}
                        style={noLiPadding ? { padding: 0 } : undefined}
                      >
                        {child}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </DropdownContent>
          </Grow>
        )}
      </Popper>
    </DropdownWrapper>
  );
}
