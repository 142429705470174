/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
// nodejs library that concatenates classes
// material-ui core components
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';

import discordImg from 'assets/img/discord.png';
import { useTheme } from '../../context/ThemeContext';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    width: '100%',
    padding: '10px 0',
    backgroundColor: '#212121',
    color: '#ffffff',
    boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
    marginTop: '24px',
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '16px',
    '@media (min-width: 498px)': {
      paddingBottom: '0px',
    },
    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px',
      justifyContent: 'space-between',
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1140px',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  right: {
    padding: '8px 0',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    position: 'relative',
    zIndex: 10000,
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    zIndex: '10000',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  link: {
    color: theme?.palette?.mode === 'dark' ? '#ffffff' : '#142d4c',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    position: 'relative',
    zIndex: 10000,
    '&:hover': {
      opacity: 0.8,
    },
  },
  themeButton: {
    minWidth: 'unset',
    padding: '6px 8px',
  },
  '@global': {
    'html, body': {
      height: '100%',
      margin: 0,
      padding: 0,
    },
    body: {
      minHeight: '100vh',
    },
    '#root': {
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: '1',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LightModeIcon style={{ opacity: isDarkMode ? 0.5 : 1 }} />
                <Switch
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  color="default"
                  style={{ margin: '0 4px' }}
                />
                <DarkModeIcon style={{ opacity: isDarkMode ? 1 : 0.5 }} />
              </div>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <LanguageSelector />
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <a
            href="https://discord.gg/UQvyYd93Hz"
            className={classes.link}
            rel="noreferrer"
            target="_blank"
          >
            <img style={{ width: '86px' }} src={discordImg} alt="discord" />
          </a>
          {/* <div style={{ fontSize: '12px' }}>
            &copy; {1900 + new Date().getYear()}{' '}
            <a
              href="https://www.d2rs.io"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              d2rs.io
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
