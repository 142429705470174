import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import config from '../../config/config.json';

interface HeaderLinksContextType {
  isLoggedIn: boolean;
  isStaff: boolean;
  isD2RS: boolean;
  isNodeOwner: boolean;
  availableNodes: string[];
  currentNode: string;
  disablePlatform: boolean;
  currentContext: string;
  setCurrentContext: React.Dispatch<React.SetStateAction<string>>;
  userData: any;
}

const HeaderLinksContext = createContext<HeaderLinksContextType | undefined>(undefined);

export const HeaderLinksProvider = ({ children }) => {
  const { t } = useTranslation();
  const ladderDefault = localStorage.getItem('ladder');
  const hardcoreDefault = localStorage.getItem('hardcore');

  const laddString = ladderDefault === '0' ? 'Non-Ladder' : 'Ladder';
  const coreString = hardcoreDefault === '1' ? 'Hardcore' : 'Softcore';
  let nodeDefault = localStorage.getItem('nodename');
  if (!nodeDefault) {
    nodeDefault = t('game.all');
  }
  const sessionToken = localStorage.getItem('session_token');
  let loggedIn = false;
  if (sessionToken) {
    loggedIn = true;
  }
  const [isStaff, setIsStaff] = useState<boolean>(false);
  const [isD2RS, setIsD2RS] = useState<boolean>(false);
  const [isNodeOwner, setIsNodeOwner] = useState<boolean>(false);
  const [availableNodes, setAvailableNodes] = useState([nodeDefault]);
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
  const [currentNode, setCurrentNode] = useState(nodeDefault);
  const [userData, setUserData] = useState({});
  const [disablePlatform, setDisablePlatform] = useState<boolean>(false);
  const [currentContext, setCurrentContext] = useState(coreString + ' ' + laddString);

  const { data, error } = useQuery({
    queryKey: ['userType'],
    queryFn: async () => {
      const response = await axios.get(config.backend_base_url + 'user/type', {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    enabled: !!sessionToken,
  });

  // Replace the profile API call in useEffect with useQuery
  useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await axios.get(config.backend_base_url + 'profile', {
        headers: { 'session-token': sessionToken },
      });
      if (response.data != null) {
        setUserData(response.data);
      }
      return response.data;
    },
    enabled: !!sessionToken,
  });

  useEffect(() => {
    if (data) {
      if (data['deploying'] === 1) {
        toast.loading(t('deployment.inProgress'));
        setDisablePlatform(true);
      }
      if (data['node_name']) {
        localStorage.setItem('nodename', data['node_name']);
        setCurrentNode(data['node_name']);
      }

      const staffStatus = data['is_staff'] === 1;
      localStorage.setItem('isStaff', staffStatus.toString());
      setIsStaff(staffStatus);

      setIsD2RS(data['is_d2rs'] === 1);
      setIsNodeOwner(data['is_node_owner'] === 1);

      if (data['allowed_nodes']?.length > 0) {
        setAvailableNodes(data['allowed_nodes']);
      }

      const laddString = data['ladder'] === 1 ? 'Ladder' : 'Non-Ladder';
      const coreString = data['hardcore'] === 1 ? 'Hardcore' : 'Softcore';

      localStorage.setItem('ladder', data['ladder'] ? '1' : '0');
      localStorage.setItem('hardcore', data['hardcore'] ? '1' : '0');

      setCurrentContext(`${coreString} ${laddString}`);
    }
  }, [data, t]);

  useEffect(() => {
    if (error) {
      console.error('Query failed:', error);
      localStorage.clear();
      setIsLoggedIn(false);
    }
  }, [error]);

  return (
    <HeaderLinksContext.Provider
      value={{
        isLoggedIn,
        isStaff,
        isD2RS,
        isNodeOwner,
        availableNodes,
        currentNode,
        disablePlatform,
        currentContext,
        userData,
        setCurrentContext,
      }}
    >
      {children}
    </HeaderLinksContext.Provider>
  );
};

export const useHeaderLinks = () => {
  const context = useContext(HeaderLinksContext);
  if (!context) {
    throw new Error('useUser must be used within a HeaderLinksProvider');
  }
  return context;
};
