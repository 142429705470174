import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import { Navigate, useNavigate } from 'react-router-dom';
import Email from '@mui/icons-material/Email';
import People from '@mui/icons-material/People';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/Footer.js';
import GridItem from '../../components/Grid/GridItem';
import HelpBubble from 'components/HelpBubble/HelpBubble';
import UserService from '../../services/UserService';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SettingsService from '../../services/SettingsService';

import config from '../../config/config.json';

import styles from 'assets/jss/material-kit-react/views/loginPage.js';

import image from 'assets/img/bgt.png';
import axios from 'axios';
import d2rsIcon from '../../assets/img/d2rsiconblock.png';

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.login'));

  const navigate = useNavigate();

  async function login(event) {
    event.preventDefault();

    const name = document.getElementById('input.name').value;
    const password = document.getElementById('input.password').value;

    if (name === '' || password === '') {
      toast.error(t('login.errors.emptyFields'));
      return;
    }
    await axios
      .post(config.backend_base_url + 'login', {
        name: name,
        password: password,
      })
      .then(async (response) => {
        console.log('Success ========>', response);
        if (name.toUpperCase() === 'guest'.toUpperCase()) {
          localStorage.setItem('guest_token', response.data);
        } else {
          localStorage.removeItem('guest_token');
        }
        localStorage.setItem('session_token', response.data['session_token']);
        localStorage.setItem('has_hardcore_access', response.data['has_hardcore_access']);

        // Reset UserService state
        const userService = UserService.getInstance();
        userService.clearCache();

        // Sync settings
        const settingsService = SettingsService.getInstance();
        await settingsService.syncSettings();

        // Navigate after state reset and settings sync
        window.location.href = '/profile';
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('login.errors.failed'));
      });
  }

  const loggedIn = localStorage.getItem('session_token');
  if (loggedIn) {
    return <Navigate to="/profile" replace />;
  }
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'black',
          backgroundPosition: 'center',
        }}
      >
        <div className={classes.container}>
          <GridContainer style={{ background: 'transparent', justifyContent: 'center' }}>
            <GridItem xs={12} sm={12} md={4} style={{ background: 'transparent' }}>
              <Card>
                <form className={classes.form} id="loginForm" onSubmit={login}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <img style={{ width: '128px' }} src={d2rsIcon} alt="d2rs.io" />
                  </CardHeader>

                  <CardBody>
                    <CustomInput
                      labelText={t('login.username')}
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'name',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText={t('login.password')}
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                    />
                  </CardBody>

                  <CardFooter
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Button
                      style={{ marginBottom: '8px' }}
                      color="primary"
                      size="large"
                      type="submit"
                      fullWidth={true}
                      form="loginForm"
                    >
                      {t('login.loginButton')}
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <BubbleContainer pageKey="register" helpText={t('help.register')} />
    </div>
  );
}
