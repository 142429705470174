import React, { useEffect, useRef, useState } from 'react';
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import CustomInput from '../../components/CustomInput/CustomInput';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/icons-material/TextFields';
import { default as Autotextfield } from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import FormControl from '@mui/material/FormControl';

import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Close from '@mui/icons-material/Close';
import config from '../../config/config.json';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import NavPills from '../../components/NavPills/NavPills';
import { useTranslation } from 'react-i18next';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import UserService from '../../services/UserService';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import blueIcon from '../../assets/img/blue_icon.webp';
import yellowIcon from '../../assets/img/yellow_icon.png';

const useStyles = makeStyles(styles);

const DirectDropTooltip = React.memo(({ path, itemName, onDirectDrop }) => {
  const [tooltipGameDetails, setTooltipGameDetails] = useState({
    gameName: '',
    gamePassword: '',
    gameRealm: '',
  });

  const handleTooltipOpen = () => {
    // Get latest game details from localStorage when tooltip opens
    setTooltipGameDetails({
      gameName: localStorage.getItem('pg') || '',
      gamePassword: localStorage.getItem('pp') || '',
      gameRealm: localStorage.getItem('pr') || '',
    });
  };

  return (
    <Tooltip
      title={
        <div>
          Direct Drop this item to {tooltipGameDetails.gameName || ''}
          {tooltipGameDetails.gameName && '/'}
          {tooltipGameDetails.gamePassword || ''}
          {tooltipGameDetails.gamePassword && '/'}
          {tooltipGameDetails.gameRealm || ''}
          {(tooltipGameDetails.gameName ||
            tooltipGameDetails.gamePassword ||
            tooltipGameDetails.gameRealm) &&
            '. '}
          ⚠️prepricing is your responsibility!
          <br />
          <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={itemName} />
        </div>
      }
      arrow
      onOpen={handleTooltipOpen}
    >
      <div onClick={onDirectDrop}>💧</div>
    </Tooltip>
  );
});

DirectDropTooltip.displayName = 'DirectDropTooltip';

export default function NewItemPage(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.privateSearch'));
  const classes = useStyles();
  const { ...rest } = props;

  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  let settings = JSON.parse(localStorage.getItem('appSettings_items'));
  if (!settings) {
    settings = {};
  }

  let ladderDefault = localStorage.getItem('ladder');
  let hardcoreDefault = localStorage.getItem('hardcore');
  let laddString = 'Ladder';
  let ladd = parseInt(ladderDefault);
  let coreString = 'Softcore';
  let core = parseInt(hardcoreDefault);
  if (ladd === 0) {
    laddString = 'Non-Ladder';
  }
  if (core === 1) {
    coreString = 'Hardcore';
  }
  const [currentContext] = useState(coreString + ' ' + laddString);

  const location = useLocation();

  // Extract query parameter from URL
  const queryParams = new URLSearchParams(location.search);
  const qterm = queryParams.get('term') || ''; // Default to empty string if 'term' is not present

  const [totalPrice, setTotalPrice] = useState('0');
  const [cartSearchTerm, setCartSearchTerm] = useState(qterm);
  const [searchTerm, setSearchTerm] = useState(qterm);
  const [addRarityFilterID, setAddRarityFilterID] = useState('0');
  const [addTypeFilterID, setAddTypeFilterID] = useState('0');
  const [addNewItemsFilterID, setAddNewItemsFilterID] = useState('0');
  const [addEthItemsFilterID, setAddEthItemsFilterID] = useState('0');
  const [unidFilterID, setUnidFilterID] = useState('0');
  const [limitFilter, setLimitFilter] = useState(1000);
  const [addStatFilterID, setAddStatFilterID] = useState('0');

  const [oldCheck, setOldCheck] = useState(addStatFilterID);
  React.useEffect(() => {
    if (oldCheck !== addStatFilterID) {
      setOldCheck(addStatFilterID);
      addStatRequirementForSearch();
    }
  });

  const [addStatFilterMin, setAddStatFilterMin] = useState(1);
  const [addStatFilterMax, setAddStatFilterMax] = useState(1000);
  const [searchStatFilters, setSearchStatFilters] = useState([]);
  const [newSearchName, setNewSearchName] = useState('');
  const [searchNewItemsFilters] = useState([
    {
      id: 1,
      name: 'New Items only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude New Items',
      selected: false,
    },
  ]);
  const [searchEthItemsFilters] = useState([
    {
      id: 1,
      name: 'Eth Items Only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude Eth Items',
      selected: false,
    },
  ]);
  const [searchUnidItemsFilters] = useState([
    {
      id: 1,
      name: 'Unid Items Only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude Unid Items',
      selected: false,
    },
  ]);

  const [updatePrice, setUpdatePrice] = useState(0);
  const [updatedItemPrices, setUpdatedItemPrices] = useState([]);
  const [selectedSavedSearchesIDs, setSelectedSavedSearchesIDs] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [packItemData, setPackItemData] = useState([]);
  const [mlSelection, setMLSelection] = useState([]);
  const [selectedCartItemIDs, setSelectedCartItemIDs] = useState([]);
  const [cartItemData, setCartItemData] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);

  const handleChange = () => {
    setSelectedCartItemIDs([]);
    setSelectedSavedSearchesIDs([]);
    setSelectedItemPrices([]);
    setTotalPrice('0');
  };
  const handleKeywordChange = () => {
    setKeywords(event.target.value);
  };

  const [file, setFile] = useState();

  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  function renderTradeActions(params) {
    let infoText = 'ID: ' + params.row.id;
    infoText += '<br/>Game: ' + params.row.game_name;
    infoText += '<br/>Password: ' + params.row.game_password;
    infoText += '<br/>Realm: ' + params.row.realm;
    infoText += '<br/>Node: ' + params.row.node_name;
    infoText += '<br/>Context: ' + params.row.context;
    infoText += '<br/>Remaining: ' + params.row.remaining;
    infoText += '<br/>Completed: ' + params.row.completed;
    infoText += '<br/>Reroute Required: ' + params.row.reroute_required;

    const infoIcon = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: infoText }} />
          </div>
        }
        arrow
      >
        <div style={{ display: 'block', marginBottom: '8px', cursor: 'pointer' }}>🇮</div>
      </Tooltip>
    );

    const rerouteIcon = (
      <Tooltip
        title={
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: 'Reroute trade. If there seems to be connection issues, try another server',
              }}
            />
          </div>
        }
        arrow
      >
        <div
          style={{ display: 'block', marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            setNewIssueTradeID(params.row.id);
            setRerouteNewGameName(params.row.game_name);
            setRerouteNewGamePassword(params.row.game_password);
            setRerouteNewGameRealm(params.row.realm);
            tradeActionRerouteTradeFunction();
          }}
        >
          🔄
        </div>
      </Tooltip>
    );

    // Show halt icon if:
    // 1. Trade is not halted (is_halted is 0)
    // 2. Trade is not completed (completed is 0)
    // 3. Trade does not require rerouting (reroute_required is 0)
    const showHaltIcon =
      (!params.row.is_halted || params.row.is_halted === 0) &&
      (!params.row.completed || params.row.completed === 0) &&
      (!params.row.reroute_required || params.row.reroute_required === 0);

    const haltIcon = showHaltIcon ? (
      <Tooltip
        title={
          <div>
            <span>Halt this trade (reroute required for it to continue)</span>
          </div>
        }
        arrow
      >
        <div
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={() => haltTrade(params.row.id)}
        >
          🛑
        </div>
      </Tooltip>
    ) : null;

    return (
      <div>
        {infoIcon}
        {rerouteIcon}
        {haltIcon}
      </div>
    );
  }

  function renderDropOrderActions(params) {
    let infoText = 'ID: ' + params.row.id;
    infoText += '<br/>Node: ' + params.row.node_name;
    infoText += '<br/>Owner: ' + params.row.shareholder_name;
    infoText += '<br/>Seller: ' + params.row.reseller_name;
    infoText += '<br/>Account: ' + params.row.account_id;
    infoText += '<br/>Char: ' + params.row.char_name;
    infoText += '<br/>Status: ' + params.row.status;
    infoText += '<br/>Full Item: ' + params.row.ocr_string;

    let infoD = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: infoText }} />
          </div>
        }
        arrow
      >
        <div>🇮</div>
      </Tooltip>
    );

    return (
      <span>
        {infoD}
        <Tooltip
          title={
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: 'Report an issue with this drop (refunds, bugs, anything else)',
                }}
              />
            </div>
          }
          arrow
        >
          <div onClick={() => tradeActionCreateBugFunction(params.row.id)}>🐛</div>
        </Tooltip>
      </span>
    );
  }

  function handleGameDataSubmit(event) {
    event.preventDefault();
    const url = config.backend_base_url + 'ml/customers/create';
    const formData = new FormData();
    formData.append('create_customers', file);
    formData.append('create_customers_name', file.name);
    if (!file.name.includes('.json')) {
      toast.error('You need to select a valid json file');
      return;
    }
    const rconfig = {
      headers: {
        'content-type': 'multipart/form-data',
        'session-token': sessionToken,
      },
    };
    axios.post(url, formData, rconfig).then((response) => {
      console.log(response);
      toast.success('Successfully uploaded item packs');
      getMyCustomers();
    });
  }

  const handleSaveSettings = (event) => {
    event?.preventDefault();
    const settings = {
      gameName,
      gamePassword,
      gameRealm,
      keywords,
      includeUnpricedItems,
      includeUncartableItems,
      currency,
      onlyOwnStock,
      onlyListedByMe,
      toggleListedItems,
      showImagesInsteadOfStats,
      includeIncompletePacks,
      showImagesInsteadOfStatsCart,
      showImagesInsteadOfStatsPriceVerification,
      ftListDelimiter,
      skipRarityHeaders,
      rarityHeaderFontSize,
      skipTypeHeaders,
      typeHeaderFontSize,
      showPricesTextList,
      abbreviateStats,
      togglePricesInFTImageList,
      toggleIteratorInFTImageList,
      toggleTransparentBackgroundFTImageList,
      overlayFontSize,
      quickCart,
    };
    localStorage.setItem('appSettings_items', JSON.stringify(settings));
    toast.success('Settings saved!');
  };

  function handleItemPacksSubmit(event) {
    event.preventDefault();
    const url = config.backend_base_url + 'ml/pack/create';
    const formData = new FormData();
    formData.append('create_packs', file);
    formData.append('create_packs_name', file.name);
    if (!file.name.includes('.json')) {
      toast.error('You need to select a valid json file');
      return;
    }
    const rconfig = {
      headers: {
        'content-type': 'multipart/form-data',
        'session-token': sessionToken,
      },
    };
    axios.post(url, formData, rconfig).then((response) => {
      toast.success('Successfully uploaded item packs');
      console.log(response);
      getMyPacks();
    });
  }

  function updateItemPrices() {
    let filteredUpdatedItemPrices = updatedItemPrices.filter(function (el) {
      return el.id !== 0;
    });
    if (filteredUpdatedItemPrices.length === 0) {
      toast.error('You need to update Prices first');
      return;
    }
    let url = config.backend_base_url + 'ml/items/prices/update';
    axios
      .post(
        url,
        {
          new_prices: filteredUpdatedItemPrices,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully Updated prices');
          getItemCartPrices(selectedCartItemIDs);
          getCartItems();
          getTradeValue(selectedCartItemIDs);
          setUpdatedItemPrices([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemCartPrices(ids) {
    let url = config.backend_base_url + 'ml/items/prices/get';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          let newRelevantPrices = [];
          ids.forEach(function (item) {
            let selItem = response.data.filter(function (selIte) {
              return selIte.id === item;
            })[0];
            newRelevantPrices.push(selItem);
          });
          setSelectedItemPrices(newRelevantPrices);
          getTradeValue(ids);
        } else {
          setSelectedItemPrices([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function copyFTList() {
    let url = config.backend_base_url + 'ml/ft/selected/list';
    axios
      .post(
        url,
        {
          item_ids: selectedCartItemIDs,
          delimiter: ftListDelimiter,
          skip_rarity_header: parseInt(skipRarityHeaders),
          rarity_header_size: parseInt(rarityHeaderFontSize),
          skip_type_header: parseInt(skipTypeHeaders),
          type_header_size: parseInt(typeHeaderFontSize),
          show_prices: parseInt(showPricesTextList),
          abbreviate_stats: parseInt(abbreviateStats),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          navigator.clipboard.writeText(response.data);
          toast.success('FT List copied to clipboard!');
        } else {
          toast.error('No matches');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function copyFTPackList() {
    let input = [];
    packItemData.forEach(function (item) {
      input.push(item.id);
    });
    let url = config.backend_base_url + 'ml/ft/selected/list';
    axios
      .post(
        url,
        {
          item_ids: input,
          delimiter: ftListDelimiter,
          skip_rarity_header: parseInt(skipRarityHeaders),
          rarity_header_size: parseInt(rarityHeaderFontSize),
          skip_type_header: parseInt(skipTypeHeaders),
          type_header_size: parseInt(typeHeaderFontSize),
          show_prices: parseInt(showPricesTextList),
          abbreviate_stats: parseInt(abbreviateStats),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          navigator.clipboard.writeText(response.data);
          toast.success('FT List copied to clipboard!');
        } else {
          toast.error('No matches');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function copyFTImageList() {
    toast.success('Generating.. please wait..');
    let url = config.backend_base_url + 'ml/ft/selected/imagelist';
    axios
      .post(
        url,
        {
          item_ids: selectedCartItemIDs,
          show_prices: parseInt(togglePricesInFTImageList),
          show_iterator: parseInt(toggleIteratorInFTImageList),
          font_size: parseInt(overlayFontSize),
          transparent_background: parseInt(toggleTransparentBackgroundFTImageList),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          navigator.clipboard.writeText(response.data);
          setActiveCollage({ path: response.data });
          toast.success('FT List copied to clipboard!');
        } else {
          toast.error('No matches');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function bulkUpdatePrices() {
    if (updatePrice === 0) {
      toast.error('You need to set a price first');
      return;
    }
    let url = config.backend_base_url + 'ml/items/prices/bulk';
    axios
      .post(
        url,
        {
          item_ids: selectedCartItemIDs,
          currency: currency,
          price: parseFloat(updatePrice),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully Updated prices');
          getItemCartPrices(selectedCartItemIDs);
          getCartItems();
          getTradeValue(selectedCartItemIDs);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function copyFTListWithSearchParamsToClipboard() {
    let url = config.backend_base_url + 'ml/items/ftlist';
    axios
      .post(
        url,
        {
          search_term: searchTerm,
          rarity_filter_id: parseInt(addRarityFilterID),
          type_filter_id: parseInt(addTypeFilterID),
          new_items_filter: parseInt(addNewItemsFilterID),
          eth_items_filter: parseInt(addEthItemsFilterID),
          unid_filter: parseInt(unidFilterID),
          limit: parseInt(limitFilter),
          stat_filters: searchStatFilters,
          ft_delimiter: ftListDelimiter,
          ft_skip_rarity_header: parseInt(skipRarityHeaders),
          ft_rarity_header_size: parseInt(rarityHeaderFontSize),
          ft_skip_type_header: parseInt(skipTypeHeaders),
          ft_type_header_size: parseInt(typeHeaderFontSize),
          ft_show_prices: parseInt(showPricesTextList),
          ft_abbreviate_stats: parseInt(abbreviateStats),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          navigator.clipboard.writeText(response.data);
          toast.success('FT List copied to clipboard!');
        } else {
          toast.error('No matches');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getColorFromRarity(rarName) {
    const textColorMap = {
      WHITE: '#FFFFFF',
      SET: '#00FF00',
      MAGIC: '#4169E1',
      UNIQUE: '#A59263',
      CRAFTED: '#FFA800',
      RARE: '#FFFF00',
    };

    return textColorMap[rarName.toUpperCase()] || '#FFFFFF';
  }

  function renderCellFunc(params) {
    let path = config.backend_base_url + 'image/' + params.row.image_path;
    let skinpath = config.backend_base_url + 'skin/' + params.row.skin_image_path;
    const rarityColor = getColorFromRarity(params.row.original_rarity);

    return (
      <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              marginRight: '4px',
              backgroundColor: rarityColor,
            }}
          />
          <img
            style={{ maxHeight: '32px', maxWidth: '32px', marginRight: '4px' }}
            src={skinpath}
            alt={''}
          />
          {params.row.name}
        </div>
      </Tooltip>
    );
  }

  renderCellFunc.displayName = 'Cell Name';

  function renderCellFuncNew(params) {
    let path = config.backend_base_url + 'image/' + params.row.image_path;
    if (showImagesInsteadOfStats === 1) {
      return (
        <img
          src={path}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          alt={params.row.stats_description}
        />
      );
    }
    return (
      <Tooltip
        title={
          <img
            src={path}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            alt={params.row.stats_description}
          />
        }
        arrow
      >
        <div>{params.row.stats_description}</div>
      </Tooltip>
    );
  }

  renderCellFuncNew.displayName = 'Cell Description';

  function renderCellFuncCart(params) {
    let path = config.backend_base_url + 'image/' + params.row.image_path;
    if (showImagesInsteadOfStatsCart === 1) {
      return (
        <img
          src={path}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          alt={params.row.stats_description}
        />
      );
    }
    return (
      <Tooltip
        title={
          <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={path} alt={params.row.name} />
        }
        arrow
      >
        <div>{params.row.stats_description}</div>
      </Tooltip>
    );
  }

  renderCellFuncCart.displayName = 'Cart Cell Description';

  async function getSavedSearches() {
    let url = config.backend_base_url + 'ml/search/mine';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setSavedSearches(response.data);
        } else {
          setSavedSearches([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getItemsSearchByEnter(event) {
    if (event.key === 'Enter') {
      getItemsSearch();
    }
  }

  function getCartItemSearchByEnter(event) {
    if (event.key === 'Enter') {
      getCartItems();
    }
  }

  function clearSearch() {
    setSearchTerm('');
    setAddRarityFilterID(0);
    setAddTypeFilterID(0);
    setAddNewItemsFilterID(0);
    setAddEthItemsFilterID(0);
    setUnidFilterID(0);
    setLimitFilter(1000);
    setSearchStatFilters([]);
    setItemData([]);
    resetTermFromUrlParams();
  }

  function resetTermFromUrlParams() {
    const params = new URLSearchParams(window.location.search);
    params.delete('term');
    const newUrl = `${location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);
  }
  function getItemsSearch() {
    const fetchingNotification = toast.loading('Fetching data, please wait...');

    let url = config.backend_base_url + 'ml/items/search';
    if (parseInt(limitFilter) > 1000 && !trashModeEnabled) {
      toast.error('Maximum Search Limit is 1000');
      toast.dismiss(fetchingNotification); // Dismiss the loading notification
      return;
    }
    const params = new URLSearchParams(window.location.search);
    if (searchTerm !== '') {
      params.set('term', searchTerm);
    } else {
      params.delete('term');
    }

    const newUrl = `${location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);

    let useTerm = searchTerm;

    // Split the keywords state by \n and iterate over it
    const keywordsArray = keywords.split('\n');
    keywordsArray.forEach((keywordPair) => {
      const [var1, var2] = keywordPair.split(':');
      if (var1 && var2) {
        useTerm = useTerm.replace(new RegExp(var1, 'g'), var2);
      }
    });
    if (useTerm !== searchTerm) {
      setSearchTerm(useTerm);
    }
    axios
      .post(
        url,
        {
          search_term: useTerm,
          rarity_filter_id: parseInt(addRarityFilterID),
          type_filter_id: parseInt(addTypeFilterID),
          new_items_filter: parseInt(addNewItemsFilterID),
          eth_items_filter: parseInt(addEthItemsFilterID),
          unid_filter: parseInt(unidFilterID),
          limit: parseInt(limitFilter),
          stat_filters: searchStatFilters,
          only_own_stock: parseInt(onlyOwnStock),
          only_listed_by_me: parseInt(onlyListedByMe),
          include_unpriced_items: parseInt(includeUnpricedItems),
          include_uncartable_items: parseInt(includeUncartableItems),
          include_listed_items: parseInt(toggleListedItems),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        toast.dismiss(fetchingNotification); // Dismiss the loading notification
        if (response.data != null && response.data.length > 0) {
          toast.success('Search successful [' + response.data.length + ']');
          setItemData(response.data);
        } else {
          toast.error('Search found no matches');
          setItemData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(fetchingNotification); // Dismiss the loading notification
        toast.error('Error occurred. Please refresh the page');
      });
  }

  function getPackItemsByID(packID) {
    const fetchingNotification = toast.loading('Fetching data, please wait...');

    let url = config.backend_base_url + 'ml/pack/search';
    if (parseInt(limitFilter) > 1000) {
      toast.error('Maximum Search Limit is 1000');
      toast.dismiss(fetchingNotification); // Dismiss the loading notification
      return;
    }
    axios
      .post(
        url,
        {
          id: packID,
          limit: parseInt(packsLimit),
          include_incomplete_packs: parseInt(includeIncompletePacks),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        toast.dismiss(fetchingNotification); // Dismiss the loading notification
        if (response.data != null && response.data.length > 0) {
          toast.success('Pack Search successful [' + response.data.length + ']');
          setPackItemData(response.data);
        } else {
          toast.error('Search found no matches');
          setPackItemData([]);
        }
      })
      .catch((error) => {
        toast.dismiss(fetchingNotification); // Dismiss the loading notification
        toast.error('Search found no matches');
        console.log(error);
        setPackItemData([]);
      });
  }

  function saveNewItemSearch() {
    let url = config.backend_base_url + 'ml/search/add';
    axios
      .post(
        url,
        {
          search_term: searchTerm,
          rarity_filter_id: parseInt(addRarityFilterID),
          type_filter_id: parseInt(addTypeFilterID),
          new_items_filter: parseInt(addNewItemsFilterID),
          eth_items_filter: parseInt(addEthItemsFilterID),
          unid_filter: parseInt(unidFilterID),
          stat_filters: searchStatFilters,
          search_name: newSearchName,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success('Adding Search successfull.');
          getSavedSearches();
        } else {
          toast.error('Adding Search failed');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function deleteSelectedSavedSearches() {
    let url = config.backend_base_url + 'ml/search/delete';
    axios
      .post(
        url,
        {
          ids: selectedSavedSearchesIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success('Removing Saved searches successfull.');
          getSavedSearches();
        } else {
          toast.error('Deleting Searches failed');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function activateSelectedAsSearch(searchTerm) {
    setSearchTerm(searchTerm);
    setSavedSearchSent(!savedSearchSent);
  }

  async function getItemStats() {
    let url = config.backend_base_url + 'stats/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemStats(response.data);
        } else {
          setItemStats([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemRarities() {
    let url = config.backend_base_url + 'rarities/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemRarities(response.data);
        } else {
          setItemRarities([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getMyCustomers() {
    //this redirects the user somewhere else if we are not an admin
    let url = config.backend_base_url + 'ml/customers/get';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setMyCustomersData(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getMyPacks() {
    //this redirects the user somewhere else if we are not an admin
    let url = config.backend_base_url + 'ml/pack/get';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setMyPacksData(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemTypes() {
    let url = config.backend_base_url + 'categories/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemTypes(response.data);
        } else {
          setItemTypes([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  let getTradeValue = function (ids) {
    axios
      .post(
        config.backend_base_url + 'cart/selection/total',
        {
          item_ids: ids,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null) {
          if (currency === 'fg') {
            setTotalPrice(response.data['total_fg_price']);
          } else {
            setTotalPrice(response.data['total_fiat_price']);
          }
        } else {
          setTotalPrice('0');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  let handleTrashModeChange = function (event) {
    if (event.target.checked === true) {
      if (
        !window.confirm(
          'You are about to enter trash mode and selected items will be marked as trash. Continue?'
        )
      ) {
        return;
      }
      setTrashModeEnabled(true);
    } else {
      setTrashModeEnabled(false);
    }
  };

  let handleOwnStockChange = function (event) {
    if (event.target.checked === true) {
      setOnlyOwnStock(1);
    } else {
      setOnlyOwnStock(0);
    }
  };

  let handleListedByMeChange = function (event) {
    if (event.target.checked === true) {
      setOnlyListedByMe(1);
    } else {
      setOnlyListedByMe(0);
    }
  };

  let handleIncomplePacksChange = function (event) {
    if (event.target.checked === true) {
      setIncludeIncompletePacks(1);
    } else {
      setIncludeIncompletePacks(0);
    }
  };

  function deleteListing(itID) {
    let url = config.backend_base_url + 'd2rs/listing/delete';
    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully deleted item');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  const [toggleListedItems, setToggleListedItems] = useState(settings?.toggleListedItems ?? 0);
  const [oldListedItemsToggle, setOldListedItemsToggle] = useState(toggleListedItems);
  React.useEffect(() => {
    if (oldListedItemsToggle !== toggleListedItems) {
      setOldListedItemsToggle(toggleListedItems);
      getItemsSearch();
    }
  });

  const [showImagesInsteadOfStats] = useState(settings?.showImagesInsteadOfStats ?? 0);
  const [showImagesInsteadOfStatsCart] = useState(settings?.showImagesInsteadOfStatsCart ?? 0);
  const [showImagesInsteadOfStatsPriceVerification] = useState(
    settings?.showImagesInsteadOfStatsPriceVerification ?? 0
  );
  const [showPricesTextList, setShowPricesTextList] = useState(settings?.showPricesTextList ?? 1);
  const [abbreviateStats, setAbbreviateStats] = useState(settings?.abbreviateStats ?? 1);
  const [ftListDelimiter, setFtListDelimiter] = useState(settings?.ftListDelimiter ?? '|');
  const [rarityHeaderFontSize, setRarityHeaderFontSize] = useState(
    settings?.rarityHeaderFontSize ?? 5
  );
  const [skipRarityHeaders, setSkipRarityHeaders] = useState(settings?.skipRarityHeaders ?? 0);
  const [typeHeaderFontSize, setTypeHeaderFontSize] = useState(settings?.typeHeaderFontSize ?? 3);
  const [skipTypeHeaders, setSkipTypeHeaders] = useState(settings?.skipTypeHeaders ?? 0);
  const [togglePricesInFTImageList, setTogglePricesInFTImageList] = useState(
    settings?.togglePricesInFTImageList ?? 1
  );
  const [toggleIteratorInFTImageList, setToggleIteratorInFTImageList] = useState(
    settings?.toggleIteratorInFTImageList ?? 1
  );
  const [toggleTransparentBackgroundFTImageList, setToggleTransparentBackgroundFTImageList] =
    useState(settings?.toggleTransparentBackgroundFTImageList ?? 1);
  const [overlayFontSize, setOverlayFontSize] = useState(settings?.overlayFontSize ?? 25);
  const [quickCart, setQuickCart] = useState(settings?.quickCart ?? 1);
  // const [overlayColor, setOverlayColor] = useState(settings?.overlayColor ?? [1, 0, 0]);
  const [oldTogglePricesInFTList, setOldTogglePricesInFTList] = useState(togglePricesInFTImageList);
  React.useEffect(() => {
    if (oldTogglePricesInFTList !== togglePricesInFTImageList) {
      setOldTogglePricesInFTList(togglePricesInFTImageList);
    }
  });

  const [newIssueTradeID, setNewIssueTradeID] = useState(0); //trade_id
  const [canList, setCanList] = useState(false);

  // Add checkUserPermissions function
  async function checkUserPermissions() {
    const userService = UserService.getInstance();
    const userType = await userService.getUserType();
    setCanList(userType?.can_list === 1);
  }

  // Add useEffect for initial permission check
  useEffect(() => {
    checkUserPermissions();
  }, []);

  // Helper function to get columns based on current state and permissions
  const getColumnsWithPermissions = (baseColumnsToUse) => {
    let columnsToReturn = [...baseColumnsToUse];

    if (toggleListedItems === 1) {
      columnsToReturn = columnsToReturn.concat({
        field: 'd2rshopproductid',
        headerName: t('search.columns.listingId'),
        flex: 2,
      });
    }

    columnsToReturn = columnsToReturn.concat({
      field: toggleListedItems === 1 ? 'fiat_price' : 'fg_price',
      headerName:
        toggleListedItems === 1 ? t('search.columns.fiatPrice') : t('search.columns.fgPrice'),
      flex: 2,
    });

    columnsToReturn = columnsToReturn.concat({
      field: toggleListedItems === 1 ? 'action_btn' : 'list_btn',
      headerName: t('search.columns.actions'),
      flex: 1,
      displayName: t('search.columns.actions'),
      renderCell: renderCellFuncActionList,
    });

    // Only add shop actions if user has permission
    if (canList) {
      columnsToReturn = columnsToReturn.concat({
        field: 'shop_action_btn',
        headerName: t('search.columns.shopActions'),
        flex: 1,
        displayName: t('search.columns.shopActions'),
        renderCell: renderCellFuncShopActionList,
      });
    }

    return columnsToReturn;
  };

  // Modify handleD2RShopModeChange to use the helper function
  let handleD2RShopModeChange = function (event) {
    event.preventDefault();
    if (!canList) {
      toast.error(t('errors.noPermission'));
      return;
    }
    setToggleListedItems(event.target.checked ? 1 : 0);
    setTrashModeEnabled(0);
    setColumns(getColumnsWithPermissions(baseColumns));
  };

  // Update any other places where columns are set to use the helper function
  useEffect(() => {
    setColumns(getColumnsWithPermissions(baseColumns));
  }, [canList]); // Re-run when canList changes

  let handleShowPricesInFtImageListChange = function (event) {
    if (event.target.checked === true) {
      setTogglePricesInFTImageList(1);
    } else {
      setTogglePricesInFTImageList(0);
    }
  };
  let handleShowIteratorInFtListChange = function (event) {
    if (event.target.checked === true) {
      setToggleIteratorInFTImageList(1);
    } else {
      setToggleIteratorInFTImageList(0);
    }
  };
  let handleToggleTransparentBackgroundFTImageListChange = function (event) {
    if (event.target.checked === true) {
      setToggleTransparentBackgroundFTImageList(1);
    } else {
      setToggleTransparentBackgroundFTImageList(0);
    }
  };

  let handleAbbreviateStatsChange = function (event) {
    if (event.target.checked === true) {
      setAbbreviateStats(1);
    } else {
      setAbbreviateStats(0);
    }
  };
  let handleShowPricesTextListChange = function (event) {
    if (event.target.checked === true) {
      setShowPricesTextList(1);
    } else {
      setShowPricesTextList(0);
    }
  };
  let handleRarityHeadersChange = function (event) {
    if (event.target.checked === true) {
      setSkipRarityHeaders(1);
    } else {
      setSkipRarityHeaders(0);
    }
  };
  let handleTypeHeadersChange = function (event) {
    if (event.target.checked === true) {
      setSkipTypeHeaders(1);
    } else {
      setSkipTypeHeaders(0);
    }
  };
  let handleImagesInsteadOfStatsChange = function (event) {
    event.preventDefault();
    if (event.target.checked === true) {
      localStorage.setItem('show_items_instead_stats', 1);
      settings.showImagesInsteadOfStats = 1;
    } else {
      localStorage.setItem('show_items_instead_stats', 0);
      settings.showImagesInsteadOfStats = 0;
    }
    localStorage.setItem('appSettings_items', JSON.stringify(settings));
    window.location.reload(true);
  };

  let handleImagesCartChange = function (event) {
    event.preventDefault();
    if (event.target.checked === true) {
      localStorage.setItem('show_items_instead_stats_cart', 1);
      settings.showImagesInsteadOfStatsCart = 1;
    } else {
      localStorage.setItem('show_items_instead_stats_cart', 0);
      settings.showImagesInsteadOfStatsCart = 0;
    }
    localStorage.setItem('appSettings_items', JSON.stringify(settings));
    window.location.reload(true);
  };
  let handleImagesPriceVerificationChange = function (event) {
    event.preventDefault();
    if (event.target.checked === true) {
      localStorage.setItem('show_items_instead_stats_price_verification', 1);
      settings.showImagesInsteadOfStatsPriceVerification = 1;
    } else {
      localStorage.setItem('show_items_instead_stats_price_verification', 0);
      settings.showImagesInsteadOfStatsPriceVerification = 0;
    }
    localStorage.setItem('appSettings_items', JSON.stringify(settings));
    window.location.reload(true);
  };

  let handleIncludeUnpricedItemsChange = function (event) {
    if (event.target.checked === true) {
      setIncludeUnpricedItems(1);
    } else {
      setIncludeUnpricedItems(0);
    }
  };
  let handleQuickCartChange = function (event) {
    if (event.target.checked === true) {
      setQuickCart(1);
    } else {
      setQuickCart(0);
    }
  };

  let handleIncludeUncartableItemsChange = function (event) {
    if (event.target.checked === true) {
      setIncludeUncartableItems(1);
    } else {
      setIncludeUncartableItems(0);
    }
  };

  async function getCartItems() {
    const fetchingNotification = toast.loading('Fetching cart data, please wait...');

    let url = config.backend_base_url + 'cart/items/all';

    try {
      const response = await axios.post(
        url,
        {
          term: cartSearchTerm,
          quick_cart: parseInt(quickCart),
        },
        { headers: { 'session-token': sessionToken } }
      );

      toast.dismiss(fetchingNotification); // Dismiss the loading notification

      if (response.data != null && response.data.constructor === Array) {
        setCartItemData(response.data);
        getTradeValue(selectedCartItemIDs);
      } else {
        setCartItemData([]);
      }
    } catch (error) {
      toast.dismiss(fetchingNotification); // Dismiss the loading notification
      console.log('Error ========>', error);
      toast.error('Error occurred. Please refresh the page');
    }
  }

  let tradeActionCreateBugFunction = function (itemID) {
    const comment = window.prompt('Please enter a comment and fully describe the issue:');

    if (!comment) {
      return;
    }

    if (comment.trim() === '') {
      toast.error('Please enter a comment and fully describe the issue');
      return;
    }

    axios
      .post(
        config.backend_base_url + 'ml/issue/create',
        {
          item_id: itemID,
          comment: comment,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Issue created!');
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  let tradeActionRerouteTradeFunction = function () {
    setTradeActionRerouteTrade(true);
  };

  let rerouteTradeAndKillRemainingDrops = function () {
    axios
      .post(
        config.backend_base_url + 'ml/trade/reroute',
        {
          trade_id: newIssueTradeID,
          new_game_name: rerouteNewGameName,
          new_game_password: rerouteNewGamePassword,
          new_game_realm: rerouteNewGameRealm,
          comment: '',
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success(
          'Trade has been rerouted, droppers will be killed automatically, please be ready in the new game!'
        );
        getTrades();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  const [selectedItemPrices, setSelectedItemPrices] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const savedSearchesColumns = [
    {
      field: 'search_name',
      headerName: t('search.columns.name'),
      flex: 5,
    },
    {
      field: 'search_term',
      headerName: t('search.columns.description'),
      flex: 5,
      hide: true,
    },
    {
      field: 'search_count',
      headerName: t('search.columns.quantity'),
      flex: 2,
    },
  ];
  const [stockOverview, setStockOverview] = useState([]);
  const stockOverviewColumns = [
    {
      field: 'item_name',
      headerName: t('search.columns.name'),
      flex: 5,
    },
    {
      field: 'amount',
      headerName: t('search.columns.quantity'),
      flex: 1,
    },
  ];

  const [activeCollage, setActiveCollage] = useState({
    path: '',
  });

  let previousGameName = localStorage.getItem('pg');
  let previousGamePassword = localStorage.getItem('pp');
  let previousGameRealm = localStorage.getItem('pr');

  if (!previousGameName) {
    previousGameName = '';
  }
  if (!previousGamePassword) {
    previousGamePassword = '';
  }
  if (!previousGameRealm) {
    previousGameRealm = 'europe';
  }

  const [gameRealm, setGameRealm] = useState(settings?.gamerealm ?? previousGameRealm);
  const [gameName, setGameName] = useState(settings?.gameName ?? previousGameName);
  const [gamePassword, setGamePassword] = useState(settings?.gamePassword ?? previousGamePassword);

  // Effect to ensure game details are properly initialized and updated
  React.useEffect(() => {
    if (settings?.gamerealm) setGameRealm(settings.gamerealm);
    if (settings?.gameName) setGameName(settings.gameName);
    if (settings?.gamePassword) setGamePassword(settings.gamePassword);
  }, [settings?.gamerealm, settings?.gameName, settings?.gamePassword]);

  // Effect to persist game details to localStorage
  React.useEffect(() => {
    if (gameName) localStorage.setItem('pg', gameName);
    if (gamePassword) localStorage.setItem('pp', gamePassword);
    if (gameRealm) localStorage.setItem('pr', gameRealm);
  }, [gameName, gamePassword, gameRealm]);

  const [cartSuffix, setCartSuffix] = useState('');

  const [keywords, setKeywords] = useState(
    settings?.keywords ??
      'fcr:faster cast rate\nfhr:faster hit recovery\n@:all resistances\nerep:ethereal,repair'
  );
  const [includeUnpricedItems, setIncludeUnpricedItems] = useState(
    settings?.includeUnpricedItems ?? 1
  );
  const [includeUncartableItems, setIncludeUncartableItems] = useState(
    settings?.includeUncartableItems ?? 0
  );
  const [trashModeEnabled, setTrashModeEnabled] = useState(false);
  const [onlyOwnStock, setOnlyOwnStock] = useState(settings?.onlyOwnStock ?? 0);
  const [onlyListedByMe, setOnlyListedByMe] = useState(settings?.onlyListedByMe ?? 0);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [allowedCurrencies, setAllowedCurrencies] = useState([]);
  const [currency, setCurrency] = useState(settings?.currency ?? 'fg');

  const [itemStats, setItemStats] = useState([]);
  const [itemRarities, setItemRarities] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);

  const baseColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0,
    },
    {
      field: 'min_price',
      headerName: t('search.columns.price'),
      flex: 0,
    },
    {
      field: 'ocr_string',
      headerName: t('search.columns.description'),
      flex: 0,
    },
    {
      field: 'skin_image_path',
      headerName: t('search.columns.description'),
    },
    {
      field: 'name',
      headerName: t('search.columns.name'),
      flex: 4,
      renderCell: renderCellFunc,
    },
    {
      field: 'stats_description',
      headerName: t('search.columns.stats'),
      flex: 16,
      renderCell: renderCellFuncNew,
    },
  ];
  const [columns, setColumns] = useState(baseColumns);

  const baseCartColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0,
    },
    {
      field: 'min_price',
      headerName: t('search.columns.price'),
      flex: 0,
    },
    {
      field: 'ocr_string',
      headerName: t('search.columns.description'),
      flex: 0,
    },
    {
      field: 'skin_image_path',
      headerName: t('search.columns.description'),
    },
    {
      field: 'name',
      headerName: t('search.columns.name'),
      flex: 4,
      renderCell: renderCellFunc,
    },
    {
      field: 'stats_description',
      headerName: t('search.columns.stats'),
      flex: 16,
      renderCell: renderCellFuncCart,
    },
  ];
  const [cartColumns, setCartColumns] = useState(baseCartColumns);

  const [lastPackID, setLastPackID] = useState(0);
  const [packsLimit, setPacksLimit] = useState(1);
  const [oldLimitCheck, setOldLimitCheck] = useState(packsLimit);
  React.useEffect(() => {
    if (oldLimitCheck !== packsLimit) {
      setOldLimitCheck(packsLimit);
      if (lastPackID !== 0) {
        getPackItemsByID(lastPackID);
      }
    }
  });
  const [includeIncompletePacks, setIncludeIncompletePacks] = useState(
    settings?.includeIncompletePacks ?? 1
  );
  const [oldIncludeCheck, setOldIncludeCheck] = useState(includeIncompletePacks);
  React.useEffect(() => {
    if (oldIncludeCheck !== includeIncompletePacks) {
      setOldIncludeCheck(includeIncompletePacks);
      if (lastPackID !== 0) {
        getPackItemsByID(lastPackID);
      }
    }
  });

  const [myPacksData, setMyPacksData] = useState([]);
  const [myCustomersData, setMyCustomersData] = useState([]);

  function onCustomerChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setGameRealm(values.game_realm.toLowerCase());
    setGameName(values.game_name.replaceAll(' ', ''));
    setGamePassword(values.game_password.replaceAll(' ', ''));
  }

  function onPacksChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setLastPackID(values.id);
    getPackItemsByID(values.id);
  }

  function onStatFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddStatFilterID(values.id);
  }

  function onTypeFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddTypeFilterID(values.id);
  }

  function onRarityFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddRarityFilterID(values.id);
  }

  let addStatRequirementForSearch = function () {
    let statName = '';

    itemStats.forEach(function (it) {
      if (it.id === addStatFilterID) {
        statName = it.name;
      }
    });

    setSearchStatFilters(
      searchStatFilters.concat({
        id: addStatFilterID,
        name: statName,
        min: parseInt(addStatFilterMin),
        max: parseInt(addStatFilterMax),
      })
    );
  };

  const checkGameDetails = function () {
    if (
      gameRealm.toLowerCase() !== 'europe' &&
      gameRealm.toLowerCase() !== 'americas' &&
      gameRealm.toLowerCase() !== 'asia'
    ) {
      toast.error('You can only drop to Europe or Americas or Asia.');
      return false;
    }
    if (gameName.includes(' ') || gamePassword.includes(' ')) {
      toast.error('You cannot use empty spaces in your game details');
      return false;
    }
    if (gameName.length < 1) {
      toast.error('You need to choose a game name');
      return false;
    }
    if (gamePassword.length < 1) {
      toast.error('You need to choose a game password');
      return false;
    }
    return true;
  };

  let dropSelectedCartFunction = function (event) {
    event.preventDefault();
    if (!checkGameDetails()) {
      return;
    }
    sendDropTrade(selectedCartItemIDs);
  };

  // Add state for halted flag
  const [createTradeHalted, setCreateTradeHalted] = useState(false);

  let sendDropTrade = async function (selectedItemIDs, gameDetails = null) {
    setSendBtnDisabled(true);

    // Use provided game details or fall back to state
    const currentGameName = gameDetails?.gameName ?? gameName;
    const currentGamePassword = gameDetails?.gamePassword ?? gamePassword;
    const currentGameRealm = gameDetails?.gameRealm ?? gameRealm;

    if (
      currentGameRealm.toLowerCase() !== 'europe' &&
      currentGameRealm.toLowerCase() !== 'americas' &&
      currentGameRealm.toLowerCase() !== 'asia'
    ) {
      toast.error('You can only drop to Europe or Americas.');
      return;
    }
    if (currentGameName.includes(' ') || currentGamePassword.includes(' ')) {
      toast.error('You cannot use empty spaces in your game details');
      return;
    }

    try {
      const response = await axios.post(
        config.backend_base_url + 'ml/drop/orders/create',
        {
          game_name: currentGameName,
          game_password: currentGamePassword,
          realm: currentGameRealm,
          currency: currency,
          item_ids: selectedItemIDs,
          is_halted: createTradeHalted ? 1 : 0,
        },
        { headers: { 'session-token': sessionToken } }
      );

      console.log('Success ========>', response.data);
      toast.success('Trade has been initiated!');
      getCartItems();
      getTrades();
      getBotStatus();
      setSendBtnDisabled(false);

      // Update localStorage with the successful game details
      localStorage.setItem('pg', currentGameName);
      localStorage.setItem('pp', currentGamePassword);
      localStorage.setItem('pr', currentGameRealm);

      // Update state to match localStorage
      setGameName(currentGameName);
      setGamePassword(currentGamePassword);
      setGameRealm(currentGameRealm);
    } catch (error) {
      console.log('Error ========>', error);
      setSendBtnDisabled(false);
      if (error.response?.status === 667) {
        toast.error('You cannot make this purchase, please purchase credit or pay your due first.');
      } else {
        toast.error('Error occurred.');
      }
    }
  };

  let sendSelectedItems = async function (itemIDs) {
    if (cartSuffix.includes('dispension')) {
      toast.error('Cart Suffix not allowed');
      return;
    }

    const topicCount = cartItemData.filter((item) => item.suffix === 'topic').length;

    // Check if any selected items are runes (rarity = 1)
    const selectedItems = itemData.filter((item) => itemIDs.includes(item.id));
    const hasRunes = selectedItems.some((item) => item.rarity_id === 5);

    if (hasRunes && cartSuffix === 'topic') {
      toast.error('Runes cannot be added with the topic suffix.');
      return;
    }

    if (topicCount + itemIDs.length > 100 && cartSuffix === 'topic') {
      toast.error(
        'Too many items in topics. You can have 100 items with the topic suffix at most.'
      );
      return;
    }

    axios
      .post(
        config.backend_base_url + 'cart/add',
        {
          item_ids: itemIDs,
          suffix: cartSuffix,
        },
        {
          headers: { 'session-token': sessionToken },
        }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        getCartItems();
      })
      .catch((error) => {
        console.log('Error ========>', error);
      });
  };

  const [activeItem, setActiveItem] = useState({});

  let cellClick = function (params, event) {
    event.defaultMuiPrevented = true;
    event.preventDefault();
    event.stopPropagation();
    setActiveItem({
      id: params.row.id,
      name: params.row.name,
      stat_description: params.row.stat_description,
      image_path: config.backend_base_url + 'image/' + params.row.image_path,
    });
  };

  let sendSelectedTrashItems = function (itemIDs) {
    if (!window.confirm('You are about to mark ' + itemIDs.length + ' Items as trash. Continue?')) {
      return;
    }
    axios
      .post(
        config.backend_base_url + 'trash/add',
        {
          item_ids: itemIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Marked items as trash');
      })
      .catch((error) => {
        console.log('Error ========>', error);
      });
  };

  let sendRemoveSelectedItems = function () {
    sendRemoveItemsFunc(selectedCartItemIDs);
  };

  let sendRemoveItemsFunc = function (ids) {
    axios
      .post(
        config.backend_base_url + 'cart/remove',
        {
          item_ids: ids,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.status);
        getCartItems();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  function updateListingPrice(itID, newPrice, action) {
    let url = config.backend_base_url + 'd2rs/listing/update';
    if (isNaN(newPrice)) {
      toast.error('Price Input is not a number');
      return;
    }
    if (newPrice === '' || newPrice === null) {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    const loadingT = toast.loading(
      action + ' product on SHOP, this can take a moment. Please wait for the next notification...'
    );
    axios
      .post(
        url,
        {
          item_id: itID,
          new_price: newPrice,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.dismiss(loadingT);
          toast.success(
            'Successfully ' + action + ' item on SHOP! It can take a few minutes to be visible.'
          );
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loadingT);
        toast.error(error.message);
      });
  }

  function retakePicFunc(itID) {
    let url = config.backend_base_url + 'pic/generate';
    const loadingT = toast.loading('generating picture..');
    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.dismiss(loadingT);
          toast.success('picture successfully generated. rehover to see the result');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loadingT);
        toast.error(error.message);
      });
  }

  async function directDropFunc(itID, alreadyInCart = false) {
    // Get latest game details from localStorage
    const currentGameDetails = {
      gameName: localStorage.getItem('pg') || '',
      gamePassword: localStorage.getItem('pp') || '',
      gameRealm: localStorage.getItem('pr') || 'europe',
    };

    // Check game details with current values
    if (
      currentGameDetails.gameRealm.toLowerCase() !== 'europe' &&
      currentGameDetails.gameRealm.toLowerCase() !== 'americas' &&
      currentGameDetails.gameRealm.toLowerCase() !== 'asia'
    ) {
      toast.error('You can only drop to Europe or Americas.');
      return;
    }
    if (
      currentGameDetails.gameName.includes(' ') ||
      currentGameDetails.gamePassword.includes(' ')
    ) {
      toast.error('You cannot use empty spaces in your game details');
      return;
    }
    if (currentGameDetails.gameName.length < 1) {
      toast.error('You need to choose a game name');
      return;
    }
    if (currentGameDetails.gamePassword.length < 1) {
      toast.error('You need to choose a game password');
      return;
    }

    try {
      if (alreadyInCart) {
        await sendDropTrade([itID], currentGameDetails);
      } else {
        // First add to cart
        await axios.post(
          `${config.backend_base_url}cart/add`,
          { item_ids: [itID] },
          { headers: { 'session-token': sessionToken } }
        );

        // Update local state
        setItemData((currentItemData) => currentItemData.filter((it) => it.id !== itID));

        // Then immediately drop using the current game details
        await sendDropTrade([itID], currentGameDetails);

        // Refresh cart and trades
        await Promise.all([getCartItems(), getTrades(), getBotStatus()]);
      }
    } catch (error) {
      console.error('Error in directDropFunc:', error);
      toast.error(
        error.response?.status === 667
          ? 'You cannot make this purchase, please purchase credit or pay your due first.'
          : 'Error occurred during direct drop.'
      );
    }
  }

  async function getPastSales(itemID) {
    try {
      const response = await axios.get(`${config.backend_base_url}info/${itemID}`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching past sales:', error);
      return null;
    }
  }

  function renderCellFuncActionList(params) {
    const [salesData, setSalesData] = useState(null);
    const [isLoadingSales, setIsLoadingSales] = useState(false);

    const getDefaultInfo = () => {
      let infoText = 'ID: ' + params.row.id;
      infoText += '<br/>Node: ' + params.row.node_id;
      infoText += '<br/>Owner: ' + params.row.shareholder_id;
      infoText += '<br/>Account: ' + params.row.account_id;
      infoText += '<br/>Char: ' + params.row.char_name;
      infoText += '<br/>Minimum Price: ' + params.row.min_price;
      infoText += '<br/>Note: ' + params.row.note;
      infoText += '<br/>Full Item: ' + params.row.ocr_string;
      return infoText;
    };

    const handleTooltipOpen = async () => {
      if (!salesData && !isLoadingSales) {
        setIsLoadingSales(true);
        const data = await getPastSales(params.row.id);
        if (data) {
          setSalesData(data.toString().replace(/\n/g, '<br/>'));
        }
        setIsLoadingSales(false);
      }
    };

    const getTooltipContent = () => {
      let content = getDefaultInfo();
      content += '<br/><br/>Past Sales:';
      if (isLoadingSales) {
        content += '<br/>Loading past sales...';
      } else if (salesData) {
        content += '<br/>' + salesData;
      } else {
        content += '<br/>No sales data available';
      }
      return content;
    };

    const retakePic = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      retakePicFunc(params.row.id);
    };

    const trashItems = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      sendSelectedTrashItems([params.row.id]);
    };

    let path = config.backend_base_url + 'image/' + params.row.image_path;

    let trashD = (
      <Tooltip
        title={
          <div>
            Mark this item as TRASH
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={trashItems}>🗑️</div>
      </Tooltip>
    );

    let infoD = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: getTooltipContent() }} />
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
        onOpen={handleTooltipOpen}
      >
        <div>🇮</div>
      </Tooltip>
    );

    let picD = (
      <Tooltip
        title={
          <div>
            Generate picture
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={retakePic}>🖼️</div>
      </Tooltip>
    );

    return (
      <span>
        {infoD}
        <DirectDropTooltip
          path={path}
          itemName={params.row.name}
          onDirectDrop={(e) => {
            e.stopPropagation();
            directDropFunc(params.row.id);
          }}
        />
        {picD}
        {params.row.d2rshopproductlink === '' && trashD}
      </span>
    );
  }

  function renderCellFuncShopActionList(params) {
    const list = (e) => {
      e.stopPropagation();
      const resp = window.prompt(
        'Enter the price of this item in $. !CAREFUL! After pressing OK, the item will be listed on SHOP for the price you entered.'
      );
      if (!resp) {
        return;
      }
      updateListingPrice(params.row.id, resp, 'listing');
    };
    const update = (e) => {
      e.stopPropagation();
      const resp = window.prompt(
        'Enter the updated price of this item. !CAREFUL! After pressing OK, the price change will be published on SHOP'
      );
      updateListingPrice(params.row.id, resp, 'updating');
    };
    const delist = (e) => {
      e.stopPropagation();
      if (
        !window.confirm('!CAREFUL! After pressing OK, the item will be deleted on SHOP. Continue?')
      ) {
        return;
      }
      deleteListing(params.row.id);
    };

    let path = config.backend_base_url + 'image/' + params.row.image_path;

    let deleteD = (
      <Tooltip
        title={
          <div>
            DELETE this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={delist}>🇩</div>
      </Tooltip>
    );
    let updateD = (
      <Tooltip
        title={
          <div>
            UPDATE this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={update}>🇺</div>
      </Tooltip>
    );
    let listD = (
      <Tooltip
        title={
          <div>
            LIST this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={list}>💱</div>
      </Tooltip>
    );

    const viewD = params.row.d2rshopproductlink ? (
      <Tooltip title={'VIEW this item on this nodes shop'} arrow>
        <a
          href={params.row.d2rshopproductlink}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none', display: 'block' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={yellowIcon} alt="View in Shop" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    ) : null;

    const viewD2rsD = (
      <Tooltip title={'VIEW this item on D2RS'} arrow>
        <a
          href={`/i/${params.row.id}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={blueIcon} alt="View on D2RS" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    return (
      <span>
        {params.row.d2rshopproductlink === '' && listD}
        {params.row.d2rshopproductlink !== '' && updateD}
        {params.row.d2rshopproductlink !== '' && deleteD}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          {viewD}
          {viewD2rsD}
        </div>
      </span>
    );
  }

  function renderPriceHistoryFG(params) {
    const historyText = params.row.history_text.replace(/\n/g, '<br />');
    return (
      <Tooltip title={<span dangerouslySetInnerHTML={{ __html: historyText }} />} arrow>
        <div>🏷️ {params.row.fg_price}</div>
      </Tooltip>
    );
  }
  function renderPriceHistoryFiat(params) {
    const historyText = params.row.history_text.replace(/\n/g, '<br />');
    return (
      <Tooltip title={<span dangerouslySetInnerHTML={{ __html: historyText }} />} arrow>
        <div>🏷️ {params.row.fiat_price}</div>
      </Tooltip>
    );
  }

  function renderAdditionalCartInfo(params) {
    const [salesData, setSalesData] = useState(null);
    const [isLoadingSales, setIsLoadingSales] = useState(false);

    const getDefaultInfo = () => {
      let infoText = 'Node: ' + params.row.node_name + ' ' + params.row.node_status;
      infoText += '<br/>Owner: ' + params.row.shareholder_name;
      infoText += '<br/>Account: ' + params.row.account_id;
      infoText += '<br/>Char: ' + params.row.char_name;
      infoText += '<br/>Cart Suffix: ' + params.row.suffix;
      infoText += '<br/>Full Item: ' + params.row.ocr_string;

      const cartedAt = new Date(params.row.carted_at);
      infoText +=
        '<br/>Carted at: ' +
        cartedAt.toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      return infoText;
    };

    const handleTooltipOpen = async () => {
      if (!salesData && !isLoadingSales) {
        setIsLoadingSales(true);
        const data = await getPastSales(params.row.id);
        if (data) {
          setSalesData(data.toString().replace(/\n/g, '<br/>'));
        }
        setIsLoadingSales(false);
      }
    };

    const getTooltipContent = () => {
      let content = getDefaultInfo();
      content += '<br/><br/>Past Sales:';
      if (isLoadingSales) {
        content += '<br/>Loading past sales...';
      } else if (salesData) {
        content += '<br/>' + salesData;
      } else {
        content += '<br/>No sales data available';
      }
      return content;
    };

    const retakePic = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      retakePicFunc(params.row.id);
    };

    const removeFromCart = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      sendRemoveItemsFunc([params.row.id]);
    };

    let path = config.backend_base_url + 'image/' + params.row.image_path;

    const infoD = (
      <Tooltip
        title={<span dangerouslySetInnerHTML={{ __html: getTooltipContent() }} />}
        arrow
        onOpen={handleTooltipOpen}
      >
        <div>🇮</div>
      </Tooltip>
    );

    const retakeD = (
      <Tooltip
        title={
          <div>
            RETAKE picture of this item
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={retakePic}>🖼️</div>
      </Tooltip>
    );

    const removeD = (
      <Tooltip
        title={
          <div>
            REMOVE this item from cart
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={removeFromCart}>❌</div>
      </Tooltip>
    );

    const viewD = params.row.d2rshopproductlink ? (
      <Tooltip title={'VIEW this item on this nodes shop'} arrow>
        <a
          href={params.row.d2rshopproductlink}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none', display: 'block' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={yellowIcon} alt="View in Shop" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    ) : null;

    const viewD2rsD = (
      <Tooltip title={'VIEW this item on D2RS'} arrow>
        <a
          href={`/i/${params.row.id}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={blueIcon} alt="View on D2RS" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    return (
      <span>
        {infoD}
        <DirectDropTooltip
          path={path}
          itemName={params.row.name}
          onDirectDrop={(e) => {
            e.stopPropagation();
            directDropFunc(params.row.id, true);
          }}
        />
        {retakeD}
        {removeD}
        {viewD}
        {viewD2rsD}
      </span>
    );
  }

  function determineAllowedCurrencies() {
    let url = config.backend_base_url + 'user/type';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          //columns checks first
          let addColumns = [];
          let addCartColumns = [];
          if (
            response.data['allowed_currencies'] &&
            response.data['allowed_currencies'].length > 0
          ) {
            setAllowedCurrencies(response.data['allowed_currencies']);
            setCurrency(response.data['allowed_currencies'][0]);

            response.data['allowed_currencies'].forEach(function (val) {
              if (val === 'fg') {
                addColumns = addColumns.concat({
                  field: 'fg_price',
                  headerName: 'FG Price',
                  flex: 2,
                });
                addCartColumns = addCartColumns.concat({
                  field: 'fg_price',
                  headerName: 'FG Price',
                  flex: 2,
                  renderCell: renderPriceHistoryFG,
                });
              } else if (val === 'fiat') {
                addColumns = addColumns.concat({
                  field: 'fiat_price',
                  headerName: '$ Price',
                  flex: 2,
                });
                addCartColumns = addCartColumns.concat({
                  field: 'fiat_price',
                  headerName: '$ Price',
                  flex: 2,
                  renderCell: renderPriceHistoryFiat,
                });
              }
            });
            addColumns = addColumns.concat({
              field: 'list_btn',
              headerName: 'Actions',
              flex: 1,
              displayName: 'Actions',
              renderCell: renderCellFuncActionList,
            });
            addCartColumns = addCartColumns.concat({
              field: 'info_btn',
              headerName: 'Actions',
              flex: 1,
              displayName: 'Info',
              renderCell: renderAdditionalCartInfo,
            });
            if (canList) {
              addColumns = addColumns.concat({
                field: 'shop_action_btn',
                headerName: 'Shop Actions',
                flex: 1,
                displayName: 'Shop Actions',
                renderCell: renderCellFuncShopActionList,
              });
            }
          }
          setColumns(baseColumns.concat(addColumns));
          setCartColumns(baseCartColumns.concat(addCartColumns));
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  const [oldCurrency, setOldCurrency] = useState('');
  React.useEffect(() => {
    if (oldCurrency !== currency) {
      setOldCurrency(currency);
      getTradeValue(selectedCartItemIDs);
    }
  });
  const [savedSearchSent, setSavedSearchSent] = useState(false);
  const [oldSavedSearchSent, setOldSavedSearchSent] = useState(false);
  React.useEffect(() => {
    if (oldSavedSearchSent !== savedSearchSent) {
      setOldSavedSearchSent(savedSearchSent);
      getItemsSearch();
    }
  });

  const [tradeItemColumns] = useState([
    {
      field: 'reseller_name',
      headerName: 'Seller',
      flex: 4,
    },
    {
      field: 'shareholder_name',
      headerName: 'Owner',
      flex: 4,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 4,
    },
    {
      field: 'name',
      headerName: 'Item',
      flex: 8,
      renderCell: renderCellFunc,
    },
    {
      field: 'fg_price',
      headerName: 'FG Price',
      flex: 2,
    },
    {
      field: 'fiat_price',
      headerName: 'Fiat Price',
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: renderDropOrderActions, // Pass the function reference, don't call it
    },
  ]);
  const [tradesData, setTradesData] = useState([]);

  async function getTrades() {
    let url = config.backend_base_url + 'rs/trade/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (
          response.data != null &&
          response.data['trades'] &&
          response.data['trades'].length > 0
        ) {
          setTradesData(response.data['trades']);
        } else {
          setTradesData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  const [tradeItemData, setTradeItemData] = useState({});
  const tradeColumns = [
    {
      field: 'reseller_name',
      headerName: 'Seller',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'game_name',
      headerName: 'Game Name',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'game_password',
      headerName: 'Game Password',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'context',
      headerName: 'Context',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      },
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.formattedValue}</div>
      ),
    },
    {
      field: 'remaining',
      headerName: 'Remaining',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'completed',
      headerName: 'Completed',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderTradeActions,
    },
  ];

  const defaultSelectedTrade = {
    trade: {
      id: 0,
      value: 0,
      game_name: 'Game Name',
      game_password: 'Game Password',
      realm: 'realm',
      completed: 0,
    },
    items: [],
  };
  const [selectedTrade, setSelectedTrade] = useState(defaultSelectedTrade);

  let itemCellClick = function (params) {
    let iD = selectedTrade.items.filter(function (selIte) {
      return selIte.id === params.row.id;
    })[0];
    setTradeItemData(iD);
  };

  const [tradeActionRerouteTrade, setTradeActionRerouteTrade] = useState(false);

  const [rerouteNewGameName, setRerouteNewGameName] = useState('');
  const [rerouteNewGamePassword, setRerouteNewGamePassword] = useState('');
  const [rerouteNewGameRealm, setRerouteNewGameRealm] = useState('europe');
  let tradeCellClick = function (params, event) {
    event.defaultMuiPrevented = true;
    setNewIssueTradeID(params.row.id);
    setRerouteNewGameName(params.row.game_name);
    setRerouteNewGamePassword(params.row.game_password);
    setRerouteNewGameRealm(params.row.realm);
    let url = config.backend_base_url + 'rs/trade/detail/' + params.row.id;
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setSelectedTrade(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  };

  async function getBotStatus() {
    await axios
      .get(config.backend_base_url + 'bots/all', { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setBotStatusData(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Droppers down or error occured.');
      });
  }

  async function getStockOverview() {
    await axios
      .get(config.backend_base_url + 'stock/overview', {
        headers: { 'session-token': sessionToken },
      })
      .then((response) => {
        if (response.data != null) {
          setStockOverview(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
      });
  }

  const [botStatusColumns] = useState([
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'subnode',
      headerName: 'Subnode',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'last_ping_seconds_ago',
      headerName: t('search.columns.lastPing'),
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'status',
      headerName: t('search.columns.status'),
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>{params.value}</div>
      ),
    },
    {
      field: 'actions',
      headerName: t('search.columns.actions'),
      flex: 1,
      renderCell: (params) => {
        let infoText = 'ID: ' + params.row.id;
        infoText += '<br/>Node: ' + params.row.node_name;
        infoText += '<br/>Subnode: ' + params.row.subnode;
        infoText += '<br/>Last Ping: ' + params.row.last_ping_seconds_ago;
        infoText += '<br/>Status: ' + params.row.status;

        return (
          <span>
            <Tooltip
              title={
                <div>
                  <span dangerouslySetInnerHTML={{ __html: infoText }} />
                </div>
              }
              arrow
            >
              <div>🇮</div>
            </Tooltip>
            <Tooltip
              title="Kill this bot. It will unlock the accounts that its logged in with, so that another bot can retry the task."
              arrow
            >
              <div
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure you want to kill this bot? Admins and Nodehosts will be notified.'
                    )
                  ) {
                    axios
                      .post(
                        config.backend_base_url + 'ml/droppers/kill',
                        {
                          bot_ids: [params.row.id],
                        },
                        { headers: { 'session-token': sessionToken } }
                      )
                      .then((response) => {
                        console.log(response);
                        getBotStatus();
                        toast.success('Bot killed successfully');
                      })
                      .catch((error) => {
                        console.log('Error ========>', error);
                        toast.error('Error occurred while killing bot');
                      });
                  }
                }}
              >
                ❌
              </div>
            </Tooltip>
          </span>
        );
      },
    },
  ]);

  const [botStatusData, setBotStatusData] = useState([]);

  async function haltTrade(tradeId) {
    const url = `${config.backend_base_url}v1/trade/reroute/${tradeId}`;
    try {
      await axios.post(url, {}, { headers: { 'session-token': sessionToken } });
      toast.success('Trade halted successfully');
      getTrades(); // Refresh trades after halting
    } catch (error) {
      console.log('Error ========>', error);
      toast.error('Error occurred while halting trade');
    }
  }

  async function getAdminSettings() {
    let url = config.backend_base_url + 'admin/settings';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        console.log('response ========>', response);
        if (response != null) {
          if (response.data.mule_game_name && gameName === '') {
            setGameName(response.data.mule_game_name);
          }
          if (response.data.mule_game_password && gamePassword === '') {
            setGamePassword(response.data.mule_game_password);
          }
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  if (loadingItems) {
    getAdminSettings();
    getSavedSearches();
    determineAllowedCurrencies();
    getItemsSearch();
    getCartItems();
    getItemStats();
    getItemRarities();
    getItemTypes();
    getMyCustomers();
    getMyPacks();
    getTrades();
    getBotStatus();
    getStockOverview();
    setLoadingItems(false);
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            color="primary"
            onChange={handleChange}
            tabs={[
              {
                tabName: 'cart',
                tabButton: t('search.cart.title') + ' (' + cartItemData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={2} xl={2}>
                      <Autocomplete
                        id="customersAutocomplete"
                        options={myCustomersData}
                        getOptionLabel={(option) =>
                          option.label +
                          ' (' +
                          option.game_realm +
                          '/' +
                          option.game_name +
                          '/' +
                          option.game_password +
                          ')'
                        }
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.presetGames.title')} />
                        )}
                        onChange={onCustomerChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <CustomInput
                        labelText={t('search.game.name')}
                        id="gameName"
                        value={gameName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setGameName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <CustomInput
                        labelText={t('search.game.password')}
                        id="gamePassword"
                        value={gamePassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setGamePassword(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <Select
                        id="gameRealm"
                        value={gameRealm}
                        fullWidth={true}
                        onChange={(params) => {
                          setGameRealm(params.target.value.toLowerCase());
                        }}
                      >
                        <MenuItem value="europe" selected>
                          {t('search.game.realm.europe')}
                        </MenuItem>
                        <MenuItem value="americas">{t('search.game.realm.americas')}</MenuItem>
                        <MenuItem value="asia">{t('search.game.realm.asia')}</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} xl={1}>
                      <Autocomplete
                        id="customersAutocomplete"
                        options={[
                          { label: 'Dont Halt Trade', is_halted: false },
                          { label: '🛑Halt Trade', is_halted: true },
                        ]}
                        getOptionLabel={(option) => option.label}
                        defaultValue={{ label: 'Dont Halt Trade', is_halted: false }}
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.haltedTrade.title')} />
                        )}
                        onChange={(event, value) => {
                          setCreateTradeHalted(value?.is_halted || false);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button
                        disabled={
                          sendBtnDisabled ||
                          selectedCartItemIDs.length === 0 ||
                          gameName === '' ||
                          gamePassword === '' ||
                          gameRealm === ''
                        }
                        fullWidth={true}
                        color="primary"
                        onClick={dropSelectedCartFunction}
                      >
                        💧{t('search.cart.drop')} {selectedCartItemIDs.length}{' '}
                        {t('search.cart.selectedItems')} {t('search.cart.to')} &nbsp;
                        <b>
                          ({gameName}/{gamePassword}/{gameRealm} - {currentContext}) {totalPrice}
                          {currency.replaceAll('fiat', '$')}
                        </b>
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9} xl={9}>
                      <CustomInput
                        labelText={t('search.cart.searchTerm')}
                        id="cartSearchTerm"
                        value={cartSearchTerm}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onKeyDown={getCartItemSearchByEnter}
                        onChange={(params) => {
                          setCartSearchTerm(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Search className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                      <Button
                        color="primary"
                        fullWidth={true}
                        type="submit"
                        form="searchParamsForm"
                        onClick={getCartItems}
                      >
                        {t('search.cart.getResults')} ({cartItemData.length})
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          columns={cartColumns}
                          rows={cartItemData}
                          checkboxSelection
                          disableRowSelectionOnClick
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                              },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          onRowSelectionModelChange={(params) => {
                            setSelectedCartItemIDs(params);
                            getItemCartPrices(params);
                          }}
                          onCellClick={(params, event) => {
                            if (params.field !== '__check__') {
                              cellClick(params, event);
                            }
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button
                        disabled={selectedCartItemIDs.length === 0}
                        fullWidth={true}
                        color="danger"
                        onClick={sendRemoveSelectedItems}
                      >
                        {t('search.cart.remove')} {selectedCartItemIDs.length}{' '}
                        {t('search.cart.selectedItems')}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <CustomInput
                        labelText={t('search.cart.bulkUpdatePrice', {
                          price: updatePrice,
                          currency: currency.replaceAll('fiat', '$'),
                        })} // Adding currency to labelText
                        value={updatePrice}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setUpdatePrice(params.target.value);
                        }}
                        inputProps={{
                          type: 'string',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <Button
                        fullWidth={true}
                        disabled={selectedCartItemIDs.length === 0}
                        color="primary"
                        onClick={bulkUpdatePrices}
                      >
                        {t('search.cart.bulkUpdate')} ({updatePrice + ' ' + currency + ' each'}){' '}
                        {t('search.cart.for')} {selectedCartItemIDs.length}{' '}
                        {t('search.cart.selectedItems')}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <Button
                        fullWidth={true}
                        disabled={selectedCartItemIDs.length === 0}
                        color="primary"
                        onClick={copyFTList}
                      >
                        {t('search.cart.copyFTList')} {selectedCartItemIDs.length}{' '}
                        {t('search.cart.selectedItems')}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <Button
                        fullWidth={true}
                        disabled={selectedCartItemIDs.length === 0}
                        color="primary"
                        onClick={copyFTImageList}
                      >
                        {t('search.cart.copyFTImageList')} {selectedCartItemIDs.length}{' '}
                        {t('search.cart.selectedItems')}
                      </Button>
                    </GridItem>
                    {activeItem && Object.keys(activeItem).length > 0 && (
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <div>
                          <b>{activeItem.name}</b>
                        </div>
                        <div>
                          <b>{activeItem.stat_description}</b>
                        </div>
                        {activeItem.image_path && (
                          <img
                            src={activeItem.image_path}
                            style={{ border: '1px solid black' }}
                            alt={activeItem.name}
                          />
                        )}
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <img
                        src={activeCollage.path}
                        style={{ border: '1px solid black' }}
                        alt={activeCollage.path}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>
                        selected cart items Overview <b>{totalPrice}</b>{' '}
                        {currency.replaceAll('fiat', '$')}
                      </h3>
                      {selectedItemPrices.map((it, key) => (
                        <FormControl id={'itemPriceForm' + parseInt(key)} key={key}>
                          <CustomInput
                            labelText="Name"
                            value={it.name}
                            id={'itemName' + parseInt(key)}
                            inputProps={{
                              type: 'text',
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabledProp={true}
                          />
                          {currency === 'fiat' ? (
                            <CustomInput
                              labelText="Fiat Price"
                              id="itemPrice$"
                              disabledProp={true}
                              defaultValue={it.fiat_price}
                              inputProps={{
                                type: 'text',
                              }}
                            />
                          ) : (
                            <div></div>
                          )}
                          {currency === 'fg' ? (
                            <CustomInput
                              labelText="FG Price"
                              id="itemPriceFG"
                              defaultValue={it.fg_price}
                              inputProps={{
                                type: 'text',
                              }}
                              onChange={(params) => {
                                if (params.target.value.includes(',')) {
                                  toast.error('use . instead of , to enter cents');
                                  return;
                                }
                                selectedItemPrices.forEach((x, i) => {
                                  if (x.id === it.id) {
                                    let newEntry = selectedItemPrices[i];
                                    newEntry.fg_price = params.target.value;

                                    selectedItemPrices[i] = newEntry;
                                    updatedItemPrices[it.id] = newEntry;

                                    setSelectedItemPrices(selectedItemPrices);
                                    setUpdatedItemPrices(updatedItemPrices);
                                  }
                                });
                              }}
                            />
                          ) : (
                            <div></div>
                          )}
                          {showImagesInsteadOfStatsPriceVerification === 1 ? (
                            <img
                              src={config.backend_base_url + 'image/' + it.image_path}
                              alt={it.stats_description}
                            />
                          ) : (
                            <CustomInput
                              labelText="Description"
                              id={'itemDescription' + parseInt(key)}
                              value={it.stats_description}
                              inputProps={{
                                type: 'text',
                                multiline: true,
                                rows: 5,
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              disabledProp={true}
                            />
                          )}
                        </FormControl>
                      ))}
                      <Button
                        fullWidth={true}
                        disabled={currency != 'fg'}
                        color="primary"
                        onClick={updateItemPrices}
                      >
                        Update Prices
                      </Button>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'search',
                tabButton: t('search.title') + ' (' + itemData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <div className={classes.searchWrapper}>
                      <GridItem className={classes.grid}>
                        <Autocomplete
                          id="savedSearchesAutocomplete"
                          options={savedSearches}
                          getOptionLabel={(option) =>
                            '' + option.search_count + 'x ' + option.search_name
                          }
                          renderInput={(params) => (
                            <Autotextfield
                              {...params}
                              label="Search For Saved Searches and choose one to activate it.."
                            />
                          )}
                          onChange={(event, selectedOption) =>
                            activateSelectedAsSearch(selectedOption.search_term)
                          }
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <CustomInput
                          labelText="Stat Min Value"
                          id="minValue"
                          value={addStatFilterMin}
                          onChange={(params) => {
                            setAddStatFilterMin(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <CustomInput
                          labelText="Stat Max Value"
                          id="maxValue"
                          value={addStatFilterMax}
                          onChange={(params) => {
                            setAddStatFilterMax(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemStats}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Stat Filter Search" />
                          )}
                          onChange={onStatFilterChange}
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemRarities}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Rarity Filter" />
                          )}
                          onChange={onRarityFilterChange}
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemTypes}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Type Filter" />
                          )}
                          onChange={onTypeFilterChange}
                        />
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Select
                          id="newItemsFilter"
                          fullWidth={true}
                          value={addNewItemsFilterID}
                          onChange={(params) => {
                            setAddNewItemsFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>New Filter</MenuItem>
                          {searchNewItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Select
                          id="unidItemsFilter"
                          fullWidth={true}
                          value={unidFilterID}
                          onChange={(params) => {
                            setUnidFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>Unid Filter</MenuItem>
                          {searchUnidItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <Select
                          id="ethItemsFilter"
                          fullWidth={true}
                          value={addEthItemsFilterID}
                          onChange={(params) => {
                            setAddEthItemsFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>Eth Filter</MenuItem>
                          {searchEthItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem className={classes.grid}>
                        <CustomInput
                          labelText="Search Limit..."
                          id="limitFilter"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={limitFilter}
                          onChange={(params) => {
                            setLimitFilter(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                            autoComplete: 'off',
                          }}
                        />
                      </GridItem>{' '}
                    </div>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      {searchStatFilters.map((filter, key) => (
                        <Button
                          onClick={() =>
                            setSearchStatFilters(
                              searchStatFilters.filter(function (it) {
                                return it.id !== filter.id;
                              })
                            )
                          }
                          key={key}
                          color="primary"
                          size="sm"
                          style={{ marginLeft: '12px' }}
                        >
                          <div
                            style={{ display: 'flex', whiteSpace: 'normal', alignItems: 'center' }}
                          >
                            <Close></Close>
                            {filter.name}: {filter.min} - {filter.max}
                          </div>
                        </Button>
                      ))}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <CustomInput
                        labelText="Search Term..."
                        id="searchTerm"
                        value={searchTerm}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onKeyDown={getItemsSearchByEnter}
                        onChange={(params) => {
                          setSearchTerm(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Search className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                      <Button
                        color="primary"
                        fullWidth={true}
                        type="submit"
                        form="searchParamsForm"
                        onClick={getItemsSearch}
                      >
                        Get Search Results ({itemData.length})
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={canList ? 2 : 3}
                      xl={canList ? 2 : 3}
                      style={{ paddingTop: 10 }}
                    >
                      <Button
                        color="danger"
                        fullWidth={true}
                        type="submit"
                        form="searchParamsForm"
                        onClick={clearSearch}
                      >
                        ❌Clear Search
                      </Button>
                    </GridItem>
                    {canList && (
                      <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={toggleListedItems === 1}
                              id={'d2rshoptoggle'}
                              onChange={handleD2RShopModeChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Shop"
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          getEstimatedRowHeight={() => 100}
                          columns={columns}
                          rows={itemData}
                          checkboxSelection
                          disableRowSelectionOnClick
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                              },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          onRowSelectionModelChange={(params) => {
                            if (
                              params.length > 1 &&
                              params.length === itemData.length &&
                              !trashModeEnabled
                            ) {
                              if (
                                !window.confirm(
                                  'You are about to add ' +
                                    itemData.length +
                                    ' items to your Cart. Continue?'
                                )
                              ) {
                                setMLSelection([]);
                                return;
                              }
                            }
                            setMLSelection(params);
                            if (trashModeEnabled) {
                              if (params.length > 0) {
                                if (
                                  !window.confirm(
                                    'You are about to mark ' +
                                      params.length +
                                      ' Items as trash. Continue?'
                                  )
                                ) {
                                  return;
                                }
                                sendSelectedTrashItems(params);
                                setItemData(
                                  itemData.filter(function (it) {
                                    return !params.includes(it.id);
                                  })
                                );
                              }
                            } else {
                              if (params.length > 0) {
                                sendSelectedItems(params);
                                setItemData(
                                  itemData.filter(function (it) {
                                    return !params.includes(it.id);
                                  })
                                );
                              }
                            }
                          }}
                          onCellClick={(params, event) => {
                            if (params.field !== '__check__') {
                              cellClick(params, event);
                            }
                          }}
                          sx={{
                            '& .MuiDataGrid-cell': {
                              whiteSpace: 'normal !important',
                              wordWrap: 'break-word !important',
                              lineHeight: 'normal !important',
                              padding: '8px',
                              maxWidth: '100% !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important',
                              '&:focus': {
                                outline: 'none',
                              },
                            },
                            '& .MuiDataGrid-row': {
                              alignItems: 'stretch !important',
                              '& > *': {
                                display: 'flex !important',
                                alignItems: 'center !important',
                              },
                            },
                            '& .MuiDataGrid-renderCell': {
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important',
                              display: 'block !important',
                              maxWidth: '100% !important',
                              wordBreak: 'break-word !important',
                              whiteSpace: 'normal !important',
                              height: 'auto !important',
                              lineHeight: '1.2 !important',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              whiteSpace: 'normal !important',
                              lineHeight: '1.2 !important',
                            },
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button
                        fullWidth={true}
                        color="primary"
                        onClick={copyFTListWithSearchParamsToClipboard}
                      >
                        📜Copy FT&nbsp;<b>Text</b>&nbsp;List from Item Search Result to Clipboard
                      </Button>
                    </GridItem>
                    {activeItem && Object.keys(activeItem).length > 0 && (
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <div>
                          <b>{activeItem.name}</b>
                        </div>
                        <div>
                          <b>{activeItem.stat_description}</b>
                        </div>
                        {activeItem.image_path && (
                          <img
                            src={activeItem.image_path}
                            style={{ border: '1px solid black' }}
                            alt={activeItem.name}
                          />
                        )}
                      </GridItem>
                    )}
                  </GridContainer>
                ),
              },
              {
                tabName: 'psearch',
                tabButton: t('search.packSearch') + ' (' + packItemData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={8} xl={8} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="searchForPacksAutocomplete"
                        options={myPacksData}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <Autotextfield {...params} label="Item Packs Search.." />
                        )}
                        onChange={onPacksChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4}>
                      <CustomInput
                        labelText="Pack Amount Limit..."
                        id="limitFilterP"
                        value={packsLimit}
                        onChange={(params) => {
                          setPacksLimit(params.target.value);
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'number',
                          autoComplete: 'off',
                          width: '100%',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          columns={columns.filter(
                            (col) => col.field !== 'list_btn' && col.field !== 'shop_action_btn'
                          )}
                          rows={packItemData}
                          checkboxSelection
                          disableRowSelectionOnClick
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                              },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          onRowSelectionModelChange={(params) => {
                            setMLSelection(params);
                            if (trashModeEnabled) {
                              return;
                            }
                            if (params.length > 0) {
                              sendSelectedItems(params);
                              setPackItemData(
                                packItemData.filter(function (it) {
                                  return !params.includes(it.id);
                                })
                              );
                            }
                          }}
                          onCellClick={(params, event) => {
                            if (params.field !== '__check__') {
                              cellClick(params, event);
                            }
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button fullWidth={true} color="primary" onClick={copyFTPackList}>
                        📜Copy FT&nbsp;<b>Text</b>&nbsp;List from Pack Search Result to Clipboard
                      </Button>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'sSearch',
                tabButton: t('search.savedSearchesTab') + ' (' + savedSearches.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Autocomplete
                        id="savedSearchesAutocomplete"
                        options={savedSearches}
                        getOptionLabel={(option) =>
                          option.search_name + ' (' + option.search_count + ')'
                        }
                        renderInput={(params) => (
                          <Autotextfield
                            {...params}
                            label="Search For Saved Searches and choose on to activate it.."
                          />
                        )}
                        onChange={(event, selectedOption) =>
                          activateSelectedAsSearch(selectedOption.search_term)
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={savedSearchesColumns}
                          rows={savedSearches}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                              },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            let newSelection = [];
                            params.forEach(function (item) {
                              newSelection.push(item);
                            });
                            setSelectedSavedSearchesIDs(newSelection);
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="danger"
                        fullWidth={true}
                        disabled={selectedSavedSearchesIDs.length === 0}
                        onClick={deleteSelectedSavedSearches}
                      >
                        ❌Delete selected Searches
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h2>Current Search Parameters</h2>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <b>Term</b>: {searchTerm}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="New Saved Search Name"
                        id="newSearchName"
                        value={newSearchName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setNewSearchName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="primary" fullWidth={true} onClick={saveNewItemSearch}>
                        💾Save as New Saved Search
                      </Button>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'stockOverview',
                tabButton: t('search.stockOverview') + ' (' + stockOverview.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Autocomplete
                        id="stockOverviewAutocomplete"
                        options={stockOverview}
                        getOptionLabel={(option) => option.item_name + ' (' + option.amount + ')'}
                        renderInput={(params) => (
                          <Autotextfield {...params} label="Search For Item Name" />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          getRowHeight={() => 'auto'}
                          columns={stockOverviewColumns}
                          rows={stockOverview}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 100 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'trades',
                tabButton:
                  t('search.trades') +
                  ' (' +
                  tradesData.filter((trade) => trade.completed === 0).length +
                  ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    {tradeActionRerouteTrade === true && (
                      <GridContainer style={{ width: '100%' }}>
                        <GridItem xs={12} sm={12} md={4} xl={4}>
                          <CustomInput
                            labelText="New Game Name"
                            id="gameName"
                            value={rerouteNewGameName}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            onChange={(params) => {
                              setRerouteNewGameName(params.target.value);
                            }}
                            inputProps={{
                              type: 'text',
                            }}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} xl={4}>
                          <CustomInput
                            labelText="New Game Password"
                            id="gamePassword"
                            value={rerouteNewGamePassword}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            onChange={(params) => {
                              setRerouteNewGamePassword(params.target.value);
                            }}
                            inputProps={{
                              type: 'text',
                            }}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} xl={4}>
                          <Select
                            id="rerouteTradeNewRealm"
                            fullWidth={true}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={rerouteNewGameRealm}
                            onChange={(params) => {
                              setRerouteNewGameRealm(params.target.value);
                            }}
                          >
                            <MenuItem value={'europe'} selected>
                              Europe
                            </MenuItem>
                            <MenuItem value={'americas'}>Americas</MenuItem>
                            <MenuItem value={'asia'}>Asia</MenuItem>
                          </Select>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} xl={6}>
                          <Button
                            disabled={
                              rerouteNewGameName === '' ||
                              rerouteNewGamePassword === '' ||
                              rerouteNewGameRealm === ''
                            }
                            color="danger"
                            fullWidth={true}
                            form="rerouteTradeFinalize"
                            onClick={() => {
                              setTradeActionRerouteTrade(false);
                            }}
                          >
                            ❌Cancel Reroute
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} xl={6}>
                          <Button
                            disabled={
                              rerouteNewGameName === '' ||
                              rerouteNewGamePassword === '' ||
                              rerouteNewGameRealm === ''
                            }
                            color="primary"
                            fullWidth={true}
                            form="rerouteTradeFinalize"
                            onClick={rerouteTradeAndKillRemainingDrops}
                          >
                            🔄Reroute this trade to the updated game details
                          </Button>
                        </GridItem>
                      </GridContainer>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          getEstimatedRowHeight={() => 100}
                          columns={tradeColumns}
                          rows={tradesData}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          onCellClick={tradeCellClick}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          getEstimatedRowHeight={() => 100}
                          columns={tradeItemColumns}
                          rows={selectedTrade.items}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                          onCellClick={itemCellClick}
                        />
                      </div>
                    </GridItem>
                    {tradeItemData && Object.keys(tradeItemData).length > 0 && (
                      <GridItem xs={12} sm={12} md={12} xl={12} style={{ textAlign: 'center' }}>
                        <b>{tradeItemData.name}</b>
                      </GridItem>
                    )}
                  </GridContainer>
                ),
              },
              {
                tabName: 'droppers',
                tabButton:
                  t('search.droppers') +
                  ' (' +
                  botStatusData.filter((bot) => bot.status.includes('no jobs')).length +
                  '/' +
                  botStatusData.length +
                  ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={botStatusColumns}
                          rows={botStatusData}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 100 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'settings',
                tabButton: t('navigation.settings'),
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12}>
                      <form>
                        <Button
                          color={'primary'}
                          size={'xl'}
                          fullWidth={true}
                          onClick={handleSaveSettings}
                        >
                          {t('search.settings.saveToDevice')}
                        </Button>
                      </form>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>Trade Information</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Select
                        value={currency}
                        fullWidth={true}
                        onChange={(params) => {
                          setCurrency(params.target.value);
                          getTradeValue(selectedCartItemIDs);
                        }}
                      >
                        {allowedCurrencies.map((cur, key) => (
                          <MenuItem value={cur} key={key} selected={key === 0}>
                            {cur}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <Autocomplete
                        id="customersAutocomplete"
                        options={myCustomersData}
                        getOptionLabel={(option) =>
                          option.label +
                          ' (' +
                          option.game_realm +
                          '/' +
                          option.game_name +
                          '/' +
                          option.game_password +
                          ')'
                        }
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.presetGames.title')} />
                        )}
                        onChange={onCustomerChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <CustomInput
                        labelText={t('search.game.name')}
                        id="gameName"
                        value={gameName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setGameName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <CustomInput
                        labelText={t('search.game.password')}
                        id="gamePassword"
                        value={gamePassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setGamePassword(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3}>
                      <Select
                        id="gameRealm"
                        value={gameRealm}
                        fullWidth={true}
                        onChange={(params) => {
                          setGameRealm(params.target.value.toLowerCase());
                        }}
                      >
                        <MenuItem value="europe" selected>
                          {t('search.game.realm.europe')}
                        </MenuItem>
                        <MenuItem value="americas">{t('search.game.realm.americas')}</MenuItem>
                        <MenuItem value="asia">{t('search.game.realm.asia')}</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>Item Search Settings</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h4>{t('search.settings.keywords')}</h4>
                      <textarea
                        value={keywords}
                        onChange={handleKeywordChange}
                        placeholder={t('search.settings.keywordsPlaceholder')}
                        rows={4}
                        style={{ width: '100%' }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeUnpricedItems === 1}
                              id={'unpriceditemstoggle'}
                              onChange={handleIncludeUnpricedItemsChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Include Unpriced Items"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeUncartableItems === 1}
                              id={'unpriceditemstoggle'}
                              onChange={handleIncludeUncartableItemsChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Include Uncartable Items"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={trashModeEnabled}
                              id={'trashmodetoggle'}
                              onChange={handleTrashModeChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Enable Trash Mode"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={onlyOwnStock === 1}
                              id={'ownstocktoggle'}
                              onChange={handleOwnStockChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Only Show Your Own Stock"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={onlyListedByMe === 1}
                              id={'listedbyme'}
                              onChange={handleListedByMeChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Only Show Items Listed By You"
                        />
                      </FormGroup>
                    </GridItem>
                    {canList && (
                      <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={toggleListedItems === 1}
                              id={'d2rshoptoggle'}
                              onChange={handleD2RShopModeChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Show Listed SHOP Products"
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showImagesInsteadOfStats === 1}
                              id={'imagesinsteadtoggle'}
                              onChange={handleImagesInsteadOfStatsChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Show Images Instead of Stat String [a]"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>Pack Search Settings</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeIncompletePacks === 1}
                              id={'incompletesetstoggle'}
                              onChange={handleIncomplePacksChange}
                            />
                          }
                          label="Include Incomplete Packs"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showImagesInsteadOfStats === 1}
                              id={'imagesinsteadtoggle'}
                              onChange={handleImagesInsteadOfStatsChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Show Images Instead of Stat String [a]"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>Cart Settings</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <CustomInput
                        labelText="Cart Suffix (adjust this and use for filtering your cart)"
                        id="cartSuffix"
                        value={cartSuffix}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setCartSuffix(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={quickCart === 1}
                              id={'quickCart'}
                              onChange={handleQuickCartChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Quick Cart (loads faster)"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showImagesInsteadOfStatsCart === 1}
                              id={'imagesCart'}
                              onChange={handleImagesCartChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Show Images instead of Stat String [b]"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showImagesInsteadOfStatsPriceVerification === 1}
                              id={'priceVerification'}
                              onChange={handleImagesPriceVerificationChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Show Images instead of Stat String (price verification) [c]"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>FT List Settings</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h4>Text</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <CustomInput
                        labelText="Delimiter"
                        value={ftListDelimiter}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setFtListDelimiter(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={skipRarityHeaders === 1}
                              onChange={handleRarityHeadersChange}
                            />
                          }
                          label="Skip Rarity Headers"
                        />
                      </FormGroup>
                    </GridItem>
                    {skipRarityHeaders === 0 && (
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <CustomInput
                          labelText="Rarity Header Font Size"
                          value={rarityHeaderFontSize}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(params) => {
                            setRarityHeaderFontSize(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                            autoComplete: 'off',
                          }}
                          required
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={skipTypeHeaders === 1}
                              onChange={handleTypeHeadersChange}
                            />
                          }
                          label="Skip Type Headers"
                        />
                      </FormGroup>
                    </GridItem>
                    {skipTypeHeaders === 0 && (
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <CustomInput
                          labelText="Type Header Font Size"
                          value={typeHeaderFontSize}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(params) => {
                            setTypeHeaderFontSize(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                            autoComplete: 'off',
                          }}
                          required
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showPricesTextList === 1}
                              onChange={handleShowPricesTextListChange}
                            />
                          }
                          label="Show Prices"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={abbreviateStats === 1}
                              onChange={handleAbbreviateStatsChange}
                            />
                          }
                          label="Abbreviate Stats"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h4>Image</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={togglePricesInFTImageList === 1}
                              onChange={handleShowPricesInFtImageListChange}
                            />
                          }
                          label="Show Prices"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={toggleIteratorInFTImageList === 1}
                              onChange={handleShowIteratorInFtListChange}
                            />
                          }
                          label="Show Iterator (1, 2, 3..)"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={toggleTransparentBackgroundFTImageList === 1}
                              onChange={handleToggleTransparentBackgroundFTImageListChange}
                            />
                          }
                          label="Transparent Background"
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <CustomInput
                        labelText="Overlay Font Size"
                        value={overlayFontSize}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setOverlayFontSize(params.target.value);
                        }}
                        inputProps={{
                          type: 'number',
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>{t('search.itemPacks.title')}</h3>
                      <p>{t('search.itemPacks.uploadInfo')}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <form>
                        <input type="file" onChange={handleFileChange} />
                        <Button
                          color={'primary'}
                          size={'sm'}
                          fullWidth={true}
                          onClick={handleItemPacksSubmit}
                        >
                          {t('search.itemPacks.upload')}
                        </Button>
                      </form>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <h3>{t('search.presetGames.title')}</h3>
                      <p>{t('search.presetGames.uploadInfo')}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <form>
                        <input type="file" onChange={handleFileChange} />
                        <Button
                          color={'primary'}
                          size={'sm'}
                          fullWidth={true}
                          onClick={handleGameDataSubmit}
                        >
                          {t('search.presetGames.upload')}
                        </Button>
                      </form>
                    </GridItem>
                  </GridContainer>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer pageKey="itemSearchPage" helpText={t('help.itemSearchPage')} />
    </div>
  );
}
