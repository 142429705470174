import React, { useState } from 'react';
// @material-ui/core components
// core components
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import config from '../../config/config.json';

import { DataGrid } from '@mui/x-data-grid';
import NavPills from '../../components/NavPills/NavPills';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles(styles);

export default function UsersNodePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [loadRole, setLoadRole] = useState(true);
  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  const { t } = useTranslation();
  useDocumentTitle(t('navigation.nodeUsers'));

  function getUserPermissions() {
    //this redirects the user somewhere else if we are not an admin
    let url = config.backend_base_url + 'users/permissions';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setUserPermissions(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  if (loadRole) {
    getUserPermissions();
    setLoadRole(false);
  }

  const [selectedIssueIDs, setSelectedIssueIDs] = useState([]);
  const [issuesData, setIssuesData] = useState([]);

  const [selectedAdminIDs, setSelectedAdminIDs] = useState([]);
  const [loadingAdminData, setLoadingAdminData] = useState(true);
  const [bookDueAdminID, setBookDueAdminID] = useState(0);
  const [gameShareholderID, setGameShareholderID] = useState(0);
  const [bookDueAmount, setBookDueAmount] = useState('');
  const [currency, setCurrency] = useState('fg');
  const [bookDueComment, setBookDueComment] = useState('');
  const [dueAction, setDueAction] = useState('increase');

  const [userPermissions, setUserPermissions] = useState([]);
  const userPermissionColumns = [
    {
      field: 'id',
      headerName: 'Permission ID',
      flex: 1,
    },
    {
      field: 'user_id',
      headerName: 'User ID',
      flex: 1,
    },
    {
      field: 'user_name',
      headerName: 'User Name',
      flex: 2,
    },
    {
      field: 'is_sourcing',
      headerName: 'Is Sourcing',
      flex: 1,
    },
    {
      field: 'can_drop',
      headerName: 'Can Drop',
      flex: 1,
      editable: true,
    },
    {
      field: 'can_mule',
      headerName: 'Can Supply',
      flex: 1,
      editable: true,
    },
    {
      field: 'disabled',
      headerName: 'Account Disabled',
      flex: 1,
    },
    {
      field: 'last_activity_string',
      headerName: 'Last Ping',
      flex: 1,
    },
    {
      field: 'update',
      headerName: 'Update User Action',
      flex: 1,
      renderCell: renderCellFuncUpdate,
    },
    {
      field: 'uncart',
      headerName: 'Uncart User',
      flex: 1,
      renderCell: renderUncartUserOnNode,
    },
    {
      field: 'remove',
      headerName: 'Remove User',
      flex: 1,
      renderCell: renderCellFuncRemoveFromNote,
    },
  ];

  function renderCellFuncUpdate(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const api = params.api;
      const thisRow = {};

      api
        .getAllColumns()
        .filter((c) => c.field !== '__check__' && !!c)
        .forEach((c) => (thisRow[c.field] = params.row[c.field]));
      updateUserPermissions(thisRow);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Update
      </Button>
    );
  }
  renderCellFuncUpdate.displayName = 'Update';

  function updateUserPermissions(perms) {
    let input = {};
    for (const [key, value] of Object.entries(perms)) {
      let res = parseInt(value);
      if (key === 'last_activity' || key === 'last_activity_string') {
        //dont send
      } else if (isNaN(res)) {
        input[key] = value;
      } else {
        input[key] = res;
      }
    }
    let url = config.backend_base_url + 'staff/permissions/update';
    axios
      .post(url, input, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.success('Successfully updated permissions');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }
  function renderUncartUserOnNode(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const api = params.api;
      const thisRow = {};

      api
        .getAllColumns()
        .filter((c) => c.field !== '__check__' && !!c)
        .forEach((c) => (thisRow[c.field] = params.row[c.field]));
      uncartUserOnNode(thisRow.user_id);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Uncart User
      </Button>
    );
  }

  function renderCellFuncRemoveFromNote(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const api = params.api;
      const thisRow = {};

      api
        .getAllColumns()
        .filter((c) => c.field !== '__check__' && !!c)
        .forEach((c) => (thisRow[c.field] = params.row[c.field]));
      removeUserFromNode(thisRow.user_id);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Remove from Node
      </Button>
    );
  }
  renderCellFuncRemoveFromNote.displayName = 'Remove';

  function disableUser(userID) {
    if (!window.confirm('You are about to disable ' + userID + '. Confirm?')) {
      return;
    }
    let url = config.backend_base_url + 'user/disable/' + parseInt(userID);
    axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.success('Successfully disabled user');
          getUserPermissions();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }
  function removeUserFromNode(userID) {
    if (!window.confirm('You are about to remove ' + userID + ' from your node. Confirm?')) {
      return;
    }
    let url = config.backend_base_url + 'user/remove/' + parseInt(userID);
    axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.success('Successfully removed user from node');
          getUserPermissions();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function uncartUserOnNode(userID) {
    if (!window.confirm('You are about to uncart ' + userID + ' on your node. Confirm?')) {
      return;
    }
    let loading = toast.loading('Uncarting user..');
    let url = config.backend_base_url + 'user/uncart/' + parseInt(userID);
    axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.success('Successfully uncarted user');
          toast.dismiss(loading);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
        toast.dismiss(loading);
      });
  }

  let sendSelectedUsersToDisable = function () {
    let uniqueIDs = selectedAdminIDs.filter((c, index) => {
      return selectedAdminIDs.indexOf(c) === index;
    });

    uniqueIDs.forEach((id) => {
      disableUser(id);
    });
  };
  let sendSelectedUsersToBeNodable = function () {
    axios
      .post(
        config.backend_base_url + 'users/canbeaddedtoothernode/update',
        {
          user_ids: selectedAdminIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Updated Users.');
        getAllUsersData();
        getNodeUsersData();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  let sendSelectedUsersToNOTBeNodable = function () {
    axios
      .post(
        config.backend_base_url + 'users/cannotbeaddedtoothernode/update',
        {
          user_ids: selectedAdminIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Updated Users.');
        getAllUsersData();
        getNodeUsersData();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  let closeSelectedIssues = function () {
    axios
      .post(
        config.backend_base_url + 'staff/issues/close',
        {
          issue_ids: selectedIssueIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Closed Issues.');
        getIssuesData();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  async function bookDueForAdmin() {
    let url = config.backend_base_url + 'node/book/due';
    const finalAmount = dueAction === 'increase' ? bookDueAmount : `-${bookDueAmount}`;

    await axios
      .post(
        url,
        {
          user_id: bookDueAdminID,
          amount: finalAmount,
          currency: currency,
          comment: bookDueComment,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        toast.success('Successfully booked due.');
        console.log(response);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }

  async function getIssuesData() {
    let url = config.backend_base_url + 'staff/issues';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setIssuesData(response.data);
        } else {
          setIssuesData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured getting issues. Please refresh the page');
      });
  }

  if (loadingAdminData) {
    getIssuesData();
    getNodeUsersData();
    getAllUsersData();
    getPendingBookings();
    getD2RGames();
    setLoadingAdminData(false);
  }

  const adminsColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
    },
    {
      field: 'is_sourcing',
      headerName: 'Is Sourcing',
      flex: 1,
    },
    {
      field: 'can_be_added_to_nodes',
      headerName: 'Can be added to nodes',
      flex: 2,
    },
    {
      field: 'paypal_email',
      headerName: 'Paypal Email',
      flex: 3,
    },
    {
      field: 'credit',
      headerName: 'Credit',
      flex: 2,
    },
    {
      field: 'due_amount',
      headerName: 'Due',
      flex: 2,
      valueGetter: (params) => parseFloat(params.value, 10),
      valueParser: (value) => parseFloat(value, 10),
    },
    {
      field: 'fg_due_amount',
      headerName: 'FG Due',
      flex: 3,
      valueGetter: (params) => parseFloat(params.value, 10),
      valueParser: (value) => parseFloat(value, 10),
    },
    {
      field: 'last_activity_string',
      headerName: 'Last Ping',
      flex: 1,
    },
    {
      field: 'forum_link',
      headerName: 'Forum Profile',
      flex: 1,
      renderCell: (params) => {
        const link = params.value.replaceAll('#', '');
        const showWarning = !link.toLowerCase().includes('d2jsp');
        return (
          <>
            <a href={link} target="_blank" rel="noopener noreferrer">
              Link
            </a>
            {showWarning && <span style={{ marginLeft: '4px' }}>⚠️</span>}
          </>
        );
      },
    },
  ];

  const issuesColumns = [
    {
      field: 'creator_user_id',
      headerName: 'Creator ID',
      flex: 1,
    },
    {
      field: 'creator_name',
      headerName: 'Creator Name',
      flex: 1,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 6,
    },
    {
      field: 'trade_id',
      headerName: 'Trade',
      flex: 1,
    },
    {
      field: 'item_id',
      headerName: 'Item',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 3,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 3,
    },
  ];

  const [selectedDueBookingIDs, setSelectedDueBookingIDs] = useState([]);
  const [pendingDueBookings, setPendingDueBookings] = useState([]);
  const dueBookingsColumns = [
    {
      field: 'creator_name',
      headerName: 'Creator',
      flex: 4,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 4,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 1,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 6,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 3,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
    },
  ];
  const [canBeAddedToMyNodeUsers, setCanBeAddedToMyNodeUsers] = useState([]);
  const [myNodeUsersData, setMyNodeUsersData] = useState([]);
  const [myfgPayoutUsersData, setMyfgPayoutUsersData] = useState([]);
  const [myfgPayoutTotal, setMyfgPayoutTotal] = useState(0);
  const [myfgPayinUsersData, setMyfgPayinUsersData] = useState([]);
  const [myfgPayinTotal, setMyfgPayinTotal] = useState(0);
  const [myfiatPayoutUsersData, setMyfiatPayoutUsersData] = useState([]);
  const [myfiatPayoutTotal, setMyfiatPayoutTotal] = useState(0);
  const [myfiatPayinUsersData, setMyfiatPayinUsersData] = useState([]);
  const [myfiatPayinTotal, setMyfiatPayinTotal] = useState(0);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [addUsernameField, setAddUsernameField] = useState('');
  const [addUserPasswordField, setAddUserPasswordField] = useState('');

  async function getPendingBookings() {
    let url = config.backend_base_url + 'node/duebookings/pending';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setPendingDueBookings(response.data);
        } else {
          setPendingDueBookings([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured getting duebookings. Please refresh the page');
      });
  }

  async function acceptSelectedDueBookings() {
    axios
      .post(
        config.backend_base_url + 'node/duebookings/accept',
        {
          due_booking_ids: selectedDueBookingIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        setSelectedDueBookingIDs([]);
        toast.success('Accepted due bookings.');
        getPendingBookings();
        getNodeUsersData();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }

  function onNodeUsersDataChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setBookDueAdminID(values.id);
  }
  function onNodeUsersGameOpeningDataChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setGameShareholderID(values.user_id);
  }

  function onAllUsersDataChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setSelectedUserIDs([values.id]);
  }

  async function denySelectedDueBookings() {
    axios
      .post(
        config.backend_base_url + 'node/duebookings/deny',
        {
          due_booking_ids: selectedDueBookingIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        setSelectedDueBookingIDs([]);
        toast.success('Refused due bookings.');
        getPendingBookings();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }
  async function addUserToNode() {
    let url = config.backend_base_url + 'node/add/user';
    if (addUsernameField === '' || addUserPasswordField === '') {
      toast.error('details cant be empty');
      return;
    }
    await axios
      .post(
        url,
        {
          rs_name: addUsernameField,
          rs_password: addUserPasswordField,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response);
        toast.success(`Added ${addUsernameField}`);
        getNodeUsersData();
        getUserPermissions();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }

  async function addUserAccessToNode() {
    let url = config.backend_base_url + 'node/add/useraccess';
    if (selectedUserIDs.length === 0) {
      toast.error('You need to choose a user');
      return;
    }
    await axios
      .post(
        url,
        {
          rs_ids: selectedUserIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Added User to your Node. You need to set permissions.');
        getNodeUsersData();
        getUserPermissions();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }

  async function getNodeUsersData() {
    let url = config.backend_base_url + 'node/myusers/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setMyNodeUsersData(response.data);

          const fgpayoutusers = response.data.filter((i) => {
            const fgDueAmount = parseInt(i.fg_due_amount.replaceAll('-', ''), 10);
            return i.fg_due_amount.includes('-') && fgDueAmount >= 5000;
          });

          const fgpayoutTotal = fgpayoutusers.reduce((total, user) => {
            return total + parseInt(user.fg_due_amount.replaceAll('-', ''), 10);
          }, 0);

          setMyfgPayoutUsersData(fgpayoutusers);
          setMyfgPayoutTotal(fgpayoutTotal);

          const fgpayinUsers = response.data.filter((i) => {
            const fgdueAmount = parseInt(i.fg_due_amount.replaceAll('-', ''), 10);
            return (
              !i.fg_due_amount.includes('-') &&
              fgdueAmount >= 0.1 &&
              i.name.toLowerCase() !== 'mehl'
            );
          });

          const fgpayinTotal = fgpayinUsers.reduce((total, user) => {
            return total + parseInt(user.fg_due_amount, 10);
          }, 0);

          setMyfgPayinUsersData(fgpayinUsers);
          setMyfgPayinTotal(fgpayinTotal);

          const fiatpayoutusers = response.data.filter((i) => {
            const fiatDueAmount = parseInt(i.due_amount.replaceAll('-', ''), 10);
            return i.due_amount.includes('-') && fiatDueAmount >= 50;
          });

          const fiatpayoutTotal = fiatpayoutusers.reduce((total, user) => {
            return total + parseInt(user.due_amount.replaceAll('-', ''), 10);
          }, 0);

          setMyfiatPayoutUsersData(fiatpayoutusers);
          setMyfiatPayoutTotal(fiatpayoutTotal);

          const fiatpayinUsers = response.data.filter((i) => {
            const fiatdueAmount = parseInt(i.due_amount.replaceAll('-', ''), 10);
            return (
              !i.due_amount.includes('-') && fiatdueAmount >= 0.1 && i.name.toLowerCase() !== 'mehl'
            );
          });

          const fiatpayinTotal = fiatpayinUsers.reduce((total, user) => {
            return total + parseInt(user.due_amount, 10);
          }, 0);

          setMyfiatPayinUsersData(fiatpayinUsers);
          setMyfiatPayinTotal(fiatpayinTotal);
        } else {
          setMyNodeUsersData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured get all. Please refresh the page');
      });
  }
  async function getAllUsersData() {
    let url = config.backend_base_url + 'addtonode/users/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setCanBeAddedToMyNodeUsers(response.data);
        } else {
          setCanBeAddedToMyNodeUsers([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured get all. Please refresh the page');
      });
  }

  const handleChange = () => {};

  const gameColumns = [
    {
      field: 'shareholder_name',
      headerName: 'Supplier',
      flex: 4,
    },
    {
      field: 'name',
      headerName: 'Game Name',
      flex: 4,
    },
    {
      field: 'password',
      headerName: 'Password',
      flex: 4,
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 3,
    },
    {
      field: 'fiat_only',
      headerName: 'Fiat Only',
      flex: 1,
    },
    {
      field: 'permanent',
      headerName: 'Permanent',
      flex: 1,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 1,
    },
  ];

  async function openMuleGameForAdmin() {
    if (gameShareholderID === 0) {
      toast.error('You need to choose a user');
      return;
    }
    let url = config.backend_base_url + 'node/game/open';
    await axios
      .post(
        url,
        {
          fiat_only: fiatOnlyMuling,
          realm: realmMuling,
          shareholder_id: gameShareholderID,
          permanent: permanent,
          game_name: muleGameName,
          game_password: muleGamePassword,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Successfully opened Game.');
        getD2RGames();
      })
      .catch((error) => {
        toast.error(error.response);
      });
  }

  async function disableDroppingOnNode() {
    let url = config.backend_base_url + 'node/dropping/disable';
    await axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Successfully disabled all dropping.');
        getUserPermissions();
      })
      .catch((error) => {
        toast.error(error.response);
      });
  }

  async function enableDroppingOnNode() {
    let url = config.backend_base_url + 'node/dropping/enable';
    await axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Successfully enabled dropping.');
        getUserPermissions();
      })
      .catch((error) => {
        toast.error(error.response);
      });
  }

  let defaultMuleRealm = localStorage.getItem('default_realm');
  if (!defaultMuleRealm) {
    defaultMuleRealm = 'europe';
  }
  const [muleGameName, setMuleGameName] = useState('');
  const [muleGamePassword, setMuleGamePassword] = useState('');
  const [permanent, setPermanent] = useState(0);
  const [realmMuling, setRealmMuling] = useState(defaultMuleRealm);
  const [fiatOnlyMuling, setFiatOnlyMuling] = useState(0);
  const [selectedMuleGameIDs, setSelectedMuleGameIDs] = useState([]);
  let sendCloseSelectedMuleGamesToRemove = function () {
    let uniqueIDs = selectedMuleGameIDs.filter((c, index) => {
      return selectedMuleGameIDs.indexOf(c) === index;
    });

    axios
      .post(
        config.backend_base_url + 'node/game/close',
        {
          game_ids: uniqueIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Successfully closed Games.');
        setSelectedMuleGameIDs([]);
        getD2RGames();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  };

  const [d2rGamesData, setD2rGamesData] = useState([]);

  async function getD2RGames() {
    let url = config.backend_base_url + 'node/games';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setD2rGamesData(response.data);
          //depending on the tab we load specific orders and set it as farmAccountsData
        } else {
          setD2rGamesData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            onChange={handleChange}
            color="primary"
            tabs={[
              {
                tabName: 'bookings',
                tabButton: 'User Bookings (' + pendingDueBookings.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>Please verify the booking before accepting it</h3>
                      <FormControl id="BookDueForm" fullWidth={true}>
                        <div style={{ height: '100%', width: '100%' }}>
                          <DataGrid
                            autoHeight
                            getRowWidth={() => 'auto'}
                            columns={dueBookingsColumns}
                            rows={pendingDueBookings}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(params) => {
                              let newSelection = [];
                              params.forEach(function (item) {
                                newSelection.push(item);
                              });
                              setSelectedDueBookingIDs(newSelection);
                            }}
                          />
                        </div>
                        <Button
                          color="primary"
                          type="submit"
                          form="BookDueFormAccept"
                          disabled={selectedDueBookingIDs.length === 0}
                          onClick={acceptSelectedDueBookings}
                        >
                          Accept Selected Bookings
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={selectedDueBookingIDs.length === 0}
                          form="BookDueFormDeny"
                          onClick={denySelectedDueBookings}
                        >
                          Deny Selected Bookings
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>
                        You can make additional bookings for your own node users below. They will
                        book immediately
                      </h3>
                      <FormControl id="adminBookDueForm" fullWidth={true}>
                        <Autocomplete
                          id="Stat Filters"
                          options={myNodeUsersData}
                          getOptionLabel={(option) =>
                            option.name +
                              ' [' +
                              option.fg_due_amount +
                              'fg, ' +
                              option.due_amount +
                              '$]' +
                              ' (' +
                              option.id +
                              ')' || ''
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Search for one of your Node Users" />
                          )}
                          onChange={onNodeUsersDataChange}
                        />
                        <Select
                          id="currency"
                          value={currency}
                          onChange={(params) => {
                            setCurrency(params.target.value);
                          }}
                        >
                          <MenuItem value="fiat" selected>
                            $
                          </MenuItem>
                          <MenuItem value="fg">fg</MenuItem>
                        </Select>
                        <Select
                          id="dueAction"
                          value={dueAction}
                          onChange={(params) => {
                            setDueAction(params.target.value);
                          }}
                          style={{ marginTop: '10px' }}
                        >
                          <MenuItem value="increase">Increase Due (+)</MenuItem>
                          <MenuItem value="decrease">Decrease Due (-)</MenuItem>
                        </Select>
                        <CustomInput
                          labelText={currency === 'fg' ? 'FG Amount' : 'Amount'}
                          id="bookDueAmount"
                          value={bookDueAmount}
                          onChange={(params) => {
                            setBookDueAmount(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                          required
                        />
                        <CustomInput
                          labelText="Comment"
                          id="bookDueComment"
                          value={bookDueComment}
                          onChange={(params) => {
                            setBookDueComment(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <Button
                          color="primary"
                          type="submit"
                          disabled={bookDueAdminID === 0}
                          form="adminBookDueForm"
                          onClick={() => {
                            const selectedUser = myNodeUsersData.find(
                              (user) => user.id === bookDueAdminID
                            );
                            if (!selectedUser) return;

                            const amount =
                              dueAction === 'increase' ? bookDueAmount : `-${bookDueAmount}`;
                            const currencySymbol = currency === 'fiat' ? '$' : 'fg';
                            const message = `This will ${dueAction} ${selectedUser.name}'s due by ${amount.replace('-', '')}${currencySymbol}. Confirm?`;

                            if (window.confirm(message)) {
                              bookDueForAdmin();
                            }
                          }}
                        >
                          Book due
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'games',
                tabButton: 'Node Games (' + d2rGamesData.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth={true}>
                          <Button color="primary" type="submit" onClick={disableDroppingOnNode}>
                            Disable all dropping on your node (use before doing a mule session to
                            avoid empty mule games)
                          </Button>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth={true}>
                          <Button color="primary" type="submit" onClick={enableDroppingOnNode}>
                            Enable dropping again on your node (use after mule sessions)
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={gameColumns}
                          rows={d2rGamesData}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            let newSelection = [];
                            params.forEach(function (item) {
                              let selItem = d2rGamesData.filter(function (selIte) {
                                return selIte.id === item;
                              })[0];
                              newSelection.push(selItem.id);
                            });
                            setSelectedMuleGameIDs(newSelection);
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="Stat Filters"
                        options={userPermissions}
                        getOptionLabel={(option) =>
                          option.user_name + ' (' + option.user_id + ')' || ''
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search for one of your Users as shareholder for this game"
                          />
                        )}
                        onChange={onNodeUsersGameOpeningDataChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12} style={{ paddingTop: 10 }}>
                      <Select
                        value={fiatOnlyMuling}
                        fullWidth={true}
                        onChange={(params) => {
                          setFiatOnlyMuling(params.target.value);
                        }}
                      >
                        <MenuItem value={0}>Fiat + FG</MenuItem>
                        <MenuItem value={1}>Fiat Only</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12} style={{ paddingTop: 10 }}>
                      <Select
                        value={realmMuling}
                        fullWidth={true}
                        onChange={(params) => {
                          setRealmMuling(params.target.value);
                          localStorage.setItem('default_realm', params.target.value);
                        }}
                      >
                        <MenuItem value={'americas'}>Americas</MenuItem>
                        <MenuItem value={'europe'}>Europe</MenuItem>
                        <MenuItem value={'asia'}>Asia</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <CustomInput
                        labelText="Game Name"
                        id="gameName"
                        value={muleGameName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setMuleGameName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: <InputAdornment position="end"></InputAdornment>,
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <CustomInput
                        labelText="Game Password"
                        id="gamePassword"
                        value={muleGamePassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setMuleGamePassword(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: <InputAdornment position="end"></InputAdornment>,
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12} style={{ paddingTop: 10 }}>
                      <Select
                        value={permanent}
                        fullWidth={true}
                        onChange={(params) => {
                          setPermanent(params.target.value);
                        }}
                      >
                        <MenuItem value={0}>non Permanent</MenuItem>
                        <MenuItem value={1}>Permanent</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="openGameForm" fullWidth={true}>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={gameShareholderID === 0}
                          onClick={openMuleGameForAdmin}
                        >
                          Open Mule Game
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="closeMuleGamessForm" fullWidth={true}>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={selectedMuleGameIDs.length === 0}
                          onClick={sendCloseSelectedMuleGamesToRemove}
                        >
                          Close Selected Mule Games
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'issues',
                tabButton: 'Node Issues (' + issuesData.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div>
                        <h4>Submitted Issues on your Node</h4>
                      </div>
                      <div style={{ height: '100%', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                          <div style={{ flexGrow: 1 }}>
                            <DataGrid
                              autoHeight
                              getRowWidth={() => 'auto'}
                              columns={issuesColumns}
                              rows={issuesData}
                              pageSize={10}
                              rowsPerPageOptions={[10]}
                              checkboxSelection
                              onRowSelectionModelChange={(params) => {
                                console.log(params);
                                let newSelection = [];
                                params.forEach(function (item) {
                                  let selItem = issuesData.filter(function (selIte) {
                                    return selIte.id === item;
                                  })[0];
                                  newSelection.push(selItem.id);
                                });
                                setSelectedIssueIDs(newSelection);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        fullWidth={true}
                        disabled={selectedIssueIDs.length === 0}
                        onClick={closeSelectedIssues}
                      >
                        Delete Selected Issues
                      </Button>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'users',
                tabButton: 'Node Users (' + myNodeUsersData.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        disabled={selectedAdminIDs.length === 0}
                        onClick={function () {
                          sendSelectedUsersToDisable();
                        }}
                      >
                        Disable Selected Users
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        disabled={selectedAdminIDs.length === 0}
                        onClick={function () {
                          sendSelectedUsersToBeNodable();
                        }}
                      >
                        Allow Selected Users on other nodes
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        disabled={selectedAdminIDs.length === 0}
                        onClick={function () {
                          sendSelectedUsersToNOTBeNodable();
                        }}
                      >
                        Disallow Selected Users on other nodes
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={adminsColumns}
                          rows={myNodeUsersData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            setSelectedAdminIDs(params);
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'fgpayoutusers',
                tabButton: `FG Payout Users (${myfgPayoutUsersData.length}) [${myfgPayoutTotal}fg]`,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={adminsColumns}
                          rows={myfgPayoutUsersData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          sortModel={[
                            {
                              field: 'fg_due_amount',
                              sort: 'asc',
                            },
                          ]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'fgpayinusers',
                tabButton: `FG Payin Users (${myfgPayinUsersData.length}) [${myfgPayinTotal}fg]`,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={adminsColumns}
                          rows={myfgPayinUsersData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          sortModel={[
                            {
                              field: 'fg_due_amount',
                              sort: 'desc',
                            },
                          ]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'fiatpayoutusers',
                tabButton: `$ Payout Users (${myfiatPayoutUsersData.length}) [${myfiatPayoutTotal}$]`,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={adminsColumns}
                          rows={myfiatPayoutUsersData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          sortModel={[
                            {
                              field: 'due_amount',
                              sort: 'asc',
                            },
                          ]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'fiatpayinusers',
                tabButton: `$ Payin Users (${myfiatPayinUsersData.length}) [${myfiatPayinTotal}$]`,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={adminsColumns}
                          rows={myfiatPayinUsersData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          sortModel={[
                            {
                              field: 'due_amount',
                              sort: 'desc',
                            },
                          ]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'addusers',
                tabButton: 'No-Access Users (' + canBeAddedToMyNodeUsers.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Autocomplete
                        id="Stat Filters"
                        options={canBeAddedToMyNodeUsers}
                        getOptionLabel={(option) => option.name + ' (' + option.id + ')' || ''}
                        renderInput={(params) => (
                          <TextField {...params} label="Search for all Users to add to your node" />
                        )}
                        onChange={onAllUsersDataChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        disabled={selectedUserIDs.length === 0}
                        onClick={function () {
                          addUserAccessToNode();
                        }}
                      >
                        Give User access to your Node
                      </Button>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'permissions',
                tabButton: 'User access permissions (' + userPermissions.length + ')',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                          <div style={{ flexGrow: 1 }}>
                            <DataGrid
                              autoHeight
                              getRowWidth={() => 'auto'}
                              columns={userPermissionColumns}
                              rows={userPermissions}
                              pageSize={100}
                              rowsPerPageOptions={[10]}
                            />
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'createusers',
                tabButton: 'Create Users',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="addForm" fullWidth={true}>
                        <CustomInput
                          labelText="User Name"
                          id="addNameField"
                          value={addUsernameField}
                          onChange={(params) => {
                            setAddUsernameField(
                              params.target.value
                                .replaceAll(' ', '')
                                .replaceAll('   ', '')
                                .replaceAll('/', '')
                            );
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <CustomInput
                          labelText="User Password"
                          id="addPasswordField"
                          value={addUserPasswordField}
                          onChange={(params) => {
                            setAddUserPasswordField(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <Button
                          color="primary"
                          type="submit"
                          disabled={addUsernameField === '' || addUserPasswordField === ''}
                          form="addForm"
                          onClick={addUserToNode}
                        >
                          Add User
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer pageKey="usersNodePage" helpText={t('help.usersNodePage')} />
    </div>
  );
}
