import React from 'react';
import { useParams } from 'react-router-dom';
import { ItemDetail } from '../../components/ItemDetail/ItemDetail';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';

type ItemDetailPageParams = Record<'id', string>;

export function ItemDetailPage() {
  const { id } = useParams<ItemDetailPageParams>();
  const discordId = localStorage.getItem('discord_id') || '';
  const currentUserId = localStorage.getItem('discord_id') || '';

  useDocumentTitle('Item Details');

  if (!id) {
    return <div>Invalid item ID</div>;
  }

  return (
    <div style={{ padding: '2rem' }}>
      <ItemDetail itemId={id} type="item" discordId={discordId} currentUserId={currentUserId} />
      <BubbleContainer />
    </div>
  );
}
