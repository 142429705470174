import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const styles = {
  grid: {
    marginRight: '-15px !important',
    marginLeft: '-15px !important',
    width: 'auto !important',
  },
};

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: '',
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
