import { useCallback } from 'react';

export const useScrollToIcon = () => {
  const scrollToIcon = useCallback((iconId: string) => {
    const scrollOptions = { behavior: 'smooth' as const };

    if (iconId === 'top') {
      try {
        window.scrollTo({
          top: 0,
          ...scrollOptions,
        });
      } catch (e) {
        // Fallback for older browsers
        window.scrollTo(0, 0);
      }
      return;
    }

    if (iconId === 'bottom') {
      try {
        const scrollHeight = Math.max(
          document.documentElement.scrollHeight,
          document.body.scrollHeight,
          document.documentElement.offsetHeight,
          document.body.offsetHeight
        );
        window.scrollTo({
          top: scrollHeight,
          ...scrollOptions,
        });
      } catch (e) {
        // Fallback for older browsers
        window.scrollTo(0, document.documentElement.scrollHeight);
      }
      return;
    }

    const element = document.getElementById(iconId);
    if (element) {
      try {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (e) {
        // Fallback for older browsers
        element.scrollIntoView();
      }
    }
  }, []);

  return { scrollToIcon };
};
