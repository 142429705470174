import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
} from 'assets/jss/material-kit-react.js';

const cardHeaderStyle = {
  cardHeader: {
    borderRadius: '3px',
    padding: '1rem 15px',
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '-30px',
    border: '0',
    marginBottom: '0',
    '&&&&': {
      padding: '1.2rem 20px', // Example: Slightly adjusted padding for extra specificity
      marginTop: '-25px', // Example: Override marginTop
    },
  },
  cardHeaderPlain: {
    marginLeft: '0px',
    marginRight: '0px',
    '&&&&': {
      padding: '0.8rem 12px', // Example: Adjusted padding for specificity
    },
  },
  warningCardHeader: {
    ...warningCardHeader,
    '&&&&': {
      backgroundColor: warningCardHeader.backgroundColor, // Ensuring specificity
    },
  },
  successCardHeader: {
    ...successCardHeader,
    '&&&&': {
      backgroundColor: successCardHeader.backgroundColor,
    },
  },
  dangerCardHeader: {
    ...dangerCardHeader,
    '&&&&': {
      backgroundColor: dangerCardHeader.backgroundColor,
    },
  },
  infoCardHeader: {
    ...infoCardHeader,
    '&&&&': {
      backgroundColor: infoCardHeader.backgroundColor,
    },
  },
  primaryCardHeader: {
    ...primaryCardHeader,
    '&&&&': {
      backgroundColor: primaryCardHeader.backgroundColor,
    },
  },
};

export default cardHeaderStyle;
