import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
  Paper,
  Container,
} from '@mui/material';
import { useCookieConsent } from '../../hooks/useCookieConsent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.mode === 'dark' ? '#1A1A1A' : '#FFFFFF',
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
    borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#e0e0e0'}`,
  },
  content: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: 600,
  },
  message: {
    marginBottom: theme.spacing(2),
    color: theme.palette.mode === 'dark' ? '#ccc' : '#666',
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > button': {
        width: '100%',
      },
    },
  },
  preferences: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark' ? '#242424' : '#f5f5f5',
    borderRadius: theme.shape.borderRadius,
  },
  preferenceItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  description: {
    color: theme.palette.mode === 'dark' ? '#999' : '#666',
    paddingLeft: theme.spacing(4),
    fontSize: '0.875rem',
  },
  switch: {
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'dark' ? '#666' : '#bdbdbd',
    },
  },
}));

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showPreferences, setShowPreferences] = useState(false);
  const { preferences, hasConsented, updatePreferences, savePreferences, acceptAll, declineAll } =
    useCookieConsent();

  if (hasConsented) return null;

  const handlePreferenceChange = (name: keyof typeof preferences) => {
    if (name === 'essential') return; // Essential cookies cannot be toggled
    updatePreferences({
      [name]: !preferences[name],
    });
  };

  const handleSavePreferences = () => {
    savePreferences();
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            {t('cookies.title')}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t('cookies.message')}
          </Typography>

          <Collapse in={showPreferences}>
            <Box className={classes.preferences}>
              <Box className={classes.preferenceItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences.essential}
                      disabled
                      color="primary"
                      className={classes.switch}
                    />
                  }
                  label={t('cookies.essential')}
                />
                <Typography className={classes.description}>
                  {t('cookies.essentialDescription')}
                </Typography>
              </Box>

              <Box className={classes.preferenceItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences.analytics}
                      onChange={() => handlePreferenceChange('analytics')}
                      color="primary"
                      className={classes.switch}
                    />
                  }
                  label={t('cookies.analytics')}
                />
                <Typography className={classes.description}>
                  {t('cookies.analyticsDescription')}
                </Typography>
              </Box>

              <Box className={classes.preferenceItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences.marketing}
                      onChange={() => handlePreferenceChange('marketing')}
                      color="primary"
                      className={classes.switch}
                    />
                  }
                  label={t('cookies.marketing')}
                />
                <Typography className={classes.description}>
                  {t('cookies.marketingDescription')}
                </Typography>
              </Box>
            </Box>
          </Collapse>

          <Box className={classes.buttons}>
            <Button
              variant="text"
              color="info"
              onClick={() => setShowPreferences(!showPreferences)}
            >
              {showPreferences ? t('cookies.title') : t('cookies.title')}
            </Button>
            <Button variant="outlined" color="info" onClick={declineAll}>
              {t('cookies.decline')}
            </Button>
            <Button variant="contained" color="primary" onClick={acceptAll}>
              {t('cookies.accept')}
            </Button>
            {showPreferences && (
              <Button variant="contained" color="primary" onClick={handleSavePreferences}>
                {t('cookies.save')}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};
