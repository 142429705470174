import React from 'react';
import { useParams } from 'react-router-dom';
import { ItemDetail } from '../../components/ItemDetail/ItemDetail';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';

type PickitDetailPageParams = Record<'id', string>;

export function PickitDetailPage() {
  const { id } = useParams<PickitDetailPageParams>();
  const discordId = localStorage.getItem('discord_id') || '';

  if (!id) {
    return <div>Invalid pickit ID</div>;
  }

  return (
    <div style={{ padding: '2rem' }}>
      <ItemDetail itemId={id} type="pickit" discordId={discordId} />
      <BubbleContainer />
    </div>
  );
}
