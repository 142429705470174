import React, { useState } from 'react';
// @material-ui/core components
// core components
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { DataGrid } from '@mui/x-data-grid';
import config from '../../config/config.json';
import Tooltip from '@mui/material/Tooltip';
import NavPills from '../../components/NavPills/NavPills';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';

const useStyles = makeStyles(styles);
export default function MehlbotSalesHistoryPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.salesHistory'));

  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  //hover
  function renderCellFunc(params) {
    let path = 'https://d2r-member.com/image/' + params.row.image_path;
    return (
      <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
        <div>{params.row.name}</div>
      </Tooltip>
    );
  }
  renderCellFunc.displayName = 'Cell Name';

  function renderCellFunc2(params) {
    let path = 'https://d2r-member.com/image/' + params.row.image_path;
    console.log(path);
    return (
      <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
        <div>{params.row.stats_description}</div>
      </Tooltip>
    );
  }
  renderCellFunc2.displayName = 'Cell Description';

  const [permSalesHistoryDataFG, setPermSalesHistoryDataFG] = useState([]);
  const [permSalesHistoryDataFGSoldByMe, setPermSalesHistoryDataFGSoldByMe] = useState([]);
  const [permSalesHistoryDataFGOwnedByMe, setPermSalesHistoryDataFGOwnedByMe] = useState([]);
  const [permSalesHistoryDataFGNodeByMe, setPermSalesHistoryDataFGNodeByMe] = useState([]);
  const [permSalesHistoryDataFiat, setPermSalesHistoryDataFiat] = useState([]);
  const [permSalesHistoryDataFiatSoldByMe, setPermSalesHistoryDataFiatSoldByMe] = useState([]);
  const [permSalesHistoryDataFiatOwnedByMe, setPermSalesHistoryDataFiatOwnedByMe] = useState([]);
  const [permSalesHistoryDataFiatNodeByMe, setPermSalesHistoryDataFiatNodeByMe] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  async function getPermSalesHistory() {
    const fetchingNotification = toast.loading('Fetching data, please wait...');
    setPermSalesHistoryDataFG([]);
    setPermSalesHistoryDataFGSoldByMe([]);
    setPermSalesHistoryDataFGOwnedByMe([]);
    setPermSalesHistoryDataFGNodeByMe([]);
    setPermSalesHistoryDataFiat([]);
    setPermSalesHistoryDataFiatSoldByMe([]);
    setPermSalesHistoryDataFiatOwnedByMe([]);
    setPermSalesHistoryDataFiatNodeByMe([]);

    let url = config.backend_base_url + 'perm/sales/' + season;
    try {
      const response = await axios.get(url, { headers: { 'session-token': sessionToken } });

      if (response.data != null && response.data.items.constructor === Array) {
        const requestBy = response.data.request_by.toLowerCase();
        const fgData = response.data.items.filter((item) => item.currency_sold === 'fg');
        setPermSalesHistoryDataFG(fgData);
        setPermSalesHistoryDataFGSoldByMe(
          fgData.filter((item) => requestBy === item.reseller_name.toLowerCase())
        );
        setPermSalesHistoryDataFGOwnedByMe(
          fgData.filter((item) => requestBy === item.shareholder_name.toLowerCase())
        );
        setPermSalesHistoryDataFGNodeByMe(
          fgData.filter((item) => requestBy === item.node_host_name.toLowerCase())
        );

        const fiatData = response.data.items.filter((item) => item.currency_sold !== 'fg');
        setPermSalesHistoryDataFiat(fiatData);
        setPermSalesHistoryDataFiatSoldByMe(
          fiatData.filter((item) => requestBy === item.reseller_name.toLowerCase())
        );
        setPermSalesHistoryDataFiatOwnedByMe(
          fiatData.filter((item) => requestBy === item.shareholder_name.toLowerCase())
        );
        setPermSalesHistoryDataFiatNodeByMe(
          fiatData.filter((item) => requestBy === item.node_host_name.toLowerCase())
        );
      } else {
        setPermSalesHistoryDataFG([]);
        setPermSalesHistoryDataFiat([]);
      }
    } catch (error) {
      console.log('Error ========>', error);
      toast.error('No Data found');
    }
    toast.dismiss(fetchingNotification);
  }

  async function getAvailableSeasons() {
    const loading = toast.loading('Fetching available seasons, please wait...');
    let url = config.backend_base_url + 'seasons';
    try {
      const response = await axios.get(url, { headers: { 'session-token': sessionToken } });
      toast.dismiss(loading);
      if (response.data != null && response.data.constructor === Array) {
        setAvailableSeasons(response.data);
        setSeason(response.data[response.data.length - 1]);
      } else {
        setAvailableSeasons([]);
      }
    } catch (error) {
      toast.dismiss(loading);
      console.log('Error ========>', error);
      toast.error('No available Seasons found');
    }
  }

  const permHistoryColumnsFiat = [
    {
      field: 'name',
      headerName: t('salesHistory.columns.itemName'),
      flex: 5,
      renderCell: renderCellFunc,
    },
    {
      field: 'ocr_string',
      headerName: t('salesHistory.columns.fullItem'),
      flex: 20,
    },
    {
      field: 'involved',
      headerName: t('salesHistory.columns.involved'),
      renderCell: (params) => {
        const { node_host_name, reseller_name, shareholder_name } = params.row;
        const host = node_host_name || t('salesHistory.unknown.host');
        const seller = reseller_name || t('salesHistory.unknown.seller');
        const owner = shareholder_name || t('salesHistory.unknown.owner');
        return `${seller}, ${owner}, ${host}`;
      },
    },
    {
      field: 'fiat_price',
      headerName: t('salesHistory.columns.price'),
      flex: 1,
      type: 'number',
      valueGetter: (params) => parseFloat(params.value, 10),
      valueParser: (value) => parseFloat(value, 10),
    },
    {
      field: 'sold_at',
      headerName: t('salesHistory.columns.soldAt'),
      width: 150,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      },
    },
  ];

  const permHistoryColumnsFG = [
    {
      field: 'name',
      headerName: t('salesHistory.columns.itemName'),
      flex: 5,
      renderCell: renderCellFunc,
    },
    {
      field: 'ocr_string',
      headerName: t('salesHistory.columns.fullItem'),
      flex: 20,
    },
    {
      field: 'involved',
      headerName: t('salesHistory.columns.involved'),
      renderCell: (params) => {
        const { node_host_name, reseller_name, shareholder_name } = params.row;
        const host = node_host_name || t('salesHistory.unknown.host');
        const seller = reseller_name || t('salesHistory.unknown.seller');
        const owner = shareholder_name || t('salesHistory.unknown.owner');
        return `${seller}, ${owner}, ${host}`;
      },
    },
    {
      field: 'fg_price',
      headerName: t('salesHistory.columns.price'),
      flex: 1,
      type: 'number',
      valueGetter: (params) => parseFloat(params.value, 10),
      valueParser: (value) => parseFloat(value, 10),
    },
    {
      field: 'sold_at',
      headerName: t('salesHistory.columns.soldAt'),
      width: 150,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      },
    },
  ];

  async function getProfile() {
    await axios
      .get(config.backend_base_url + 'profile', { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          //what to do
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  const [availableSeasons, setAvailableSeasons] = useState([0]);
  const [season, setSeason] = useState(0);
  const [oldSeason, setOldSeason] = useState(season);
  React.useEffect(() => {
    if (oldSeason !== season) {
      setOldSeason(season);
      getPermSalesHistory();
    }
  });

  if (loadingData) {
    const loading = toast.loading('loading available seasons');
    getAvailableSeasons();
    getProfile();
    setLoadingData(false);
    toast.dismiss(loading);
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            fullWidth
            onChange={function () {}}
            color="primary"
            tabs={[
              {
                tabName: t('salesHistory.tabs.salesHistoryFG'),
                tabButton: t('salesHistory.tabs.salesHistoryFGCount', {
                  count: permSalesHistoryDataFG.length,
                }),
                tabContent: (
                  <div>
                    <Select
                      id="rerouteTradeNewRealm"
                      fullWidth={true}
                      value={season}
                      onChange={(params) => {
                        setSeason(params.target.value);
                      }}
                    >
                      {availableSeasons.map((season, key) => (
                        <MenuItem
                          key={key}
                          selected={key + 1 === availableSeasons.length}
                          value={season}
                        >
                          {t('salesHistory.season', { number: season })}
                        </MenuItem>
                      ))}
                    </Select>
                    <NavPills
                      queryKey={'f'}
                      alignCenter
                      fullWidth
                      onChange={function () {}}
                      color="primary"
                      tabs={[
                        {
                          tabName: t('salesHistory.tabs.all'),
                          tabButton: t('salesHistory.tabs.allCount', {
                            count: permSalesHistoryDataFG.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFG}
                                    rows={permSalesHistoryDataFG}
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.soldByMe'),
                          tabButton: t('salesHistory.tabs.soldByMeCount', {
                            count: permSalesHistoryDataFGSoldByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFG}
                                    rows={permSalesHistoryDataFGSoldByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.ownedByMe'),
                          tabButton: t('salesHistory.tabs.ownedByMeCount', {
                            count: permSalesHistoryDataFGOwnedByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFG}
                                    rows={permSalesHistoryDataFGOwnedByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.nodeByMe'),
                          tabButton: t('salesHistory.tabs.nodeByMeCount', {
                            count: permSalesHistoryDataFGNodeByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFG}
                                    rows={permSalesHistoryDataFGNodeByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                      ]}
                    />
                  </div>
                ),
              },
              {
                tabName: t('salesHistory.tabs.salesHistoryFiat'),
                tabButton: t('salesHistory.tabs.salesHistoryFiatCount', {
                  count: permSalesHistoryDataFiat.length,
                }),
                tabContent: (
                  <div>
                    <Select
                      id="rerouteTradeNewRealm"
                      fullWidth={true}
                      value={season}
                      onChange={(params) => {
                        setSeason(params.target.value);
                      }}
                    >
                      {availableSeasons.map((season, key) => (
                        <MenuItem
                          key={key}
                          selected={key + 1 === availableSeasons.length}
                          value={season}
                        >
                          {t('salesHistory.season', { number: season })}
                        </MenuItem>
                      ))}
                    </Select>
                    <NavPills
                      queryKey={'f'}
                      alignCenter
                      fullWidth
                      onChange={function () {}}
                      color="primary"
                      tabs={[
                        {
                          tabName: t('salesHistory.tabs.all'),
                          tabButton: t('salesHistory.tabs.allCount', {
                            count: permSalesHistoryDataFiat.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFiat}
                                    rows={permSalesHistoryDataFiat}
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.soldByMe'),
                          tabButton: t('salesHistory.tabs.soldByMeCount', {
                            count: permSalesHistoryDataFiatSoldByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFiat}
                                    rows={permSalesHistoryDataFiatSoldByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.ownedByMe'),
                          tabButton: t('salesHistory.tabs.ownedByMeCount', {
                            count: permSalesHistoryDataFiatOwnedByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFiat}
                                    rows={permSalesHistoryDataFiatOwnedByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                        {
                          tabName: t('salesHistory.tabs.nodeByMe'),
                          tabButton: t('salesHistory.tabs.nodeByMeCount', {
                            count: permSalesHistoryDataFiatNodeByMe.length,
                          }),
                          tabContent: (
                            <GridContainer spacing={1}>
                              <GridItem xs={12} sm={12} md={12} xl={12}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <DataGrid
                                    autoHeight
                                    getRowWidth={() => 'auto'}
                                    getRowHeight={() => 'auto'}
                                    columns={permHistoryColumnsFiat}
                                    rows={permSalesHistoryDataFiatNodeByMe}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer hideHelpBubble={true} />
    </div>
  );
}
