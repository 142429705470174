import React from 'react';
import { useScrollToIcon } from '../../hooks/useScrollToIcon';

interface ScrollableIconProps {
  id: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  position: 'left' | 'right';
}

export const ScrollableIcon: React.FC<ScrollableIconProps> = ({
  id,
  children,
  className,
  style,
  position,
}) => {
  const { scrollToIcon } = useScrollToIcon();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    scrollToIcon(id);
  };

  // Check visibility settings from localStorage
  const showLeft = parseInt(localStorage.getItem('show_scrollable_icon_left') || '1', 10) === 1;
  const showRight = parseInt(localStorage.getItem('show_scrollable_icon_right') || '1', 10) === 1;

  // Return null if the icon should be hidden based on position
  if ((position === 'left' && !showLeft) || (position === 'right' && !showRight)) {
    return null;
  }

  return (
    <button
      onClick={handleClick}
      className={className}
      style={{
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        padding: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 9999,
        pointerEvents: 'auto',
        ...style,
      }}
      aria-label={`Scroll to ${position}`}
    >
      {children}
    </button>
  );
};
