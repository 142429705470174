import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/CustomInput/CustomInput.js';
import AddIcon from '@mui/icons-material/Add';

interface TradeListFiltersProps {
  searchTerm: string;
  title: string;
  group: string;
  onSearchTermChange: (value: string) => void;
  onTitleChange: (value: string) => void;
  onGroupChange: (value: string) => void;
  onCreateList: () => void;
  isCreating?: boolean;
}

export function TradeListFilters({
  searchTerm,
  title,
  group,
  onSearchTermChange,
  onTitleChange,
  onGroupChange,
  onCreateList,
  isCreating = false,
}: TradeListFiltersProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <CustomInput
            labelText={t('tradeLists.savedSearches.searchTerm')}
            id="searchTerm"
            value={searchTerm}
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => onSearchTermChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomInput
            labelText={t('tradeLists.savedSearches.name')}
            id="title"
            value={title}
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => onTitleChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomInput
            labelText={t('tradeLists.savedSearches.group')}
            id="group"
            value={group}
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => onGroupChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onCreateList}
            disabled={isCreating}
            startIcon={<AddIcon />}
          >
            {isCreating
              ? t('tradeLists.savedSearches.creating')
              : t('tradeLists.savedSearches.createButton')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TradeListFilters;
