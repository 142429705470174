import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
// @material-ui/core components
// core components
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import config from '../../config/config.json';

import { DataGrid } from '@mui/x-data-grid';
import NavPills from '../../components/NavPills/NavPills';
import FormControl from '@mui/material/FormControl';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Switch from '@mui/material/Switch';

import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';

const useStyles = makeStyles(styles);
export default function NodePage(props) {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.nodeSettingsActions'));
  const classes = useStyles();
  const { ...rest } = props;
  const [loadRole, setLoadRole] = useState(true);
  const [muleGameAndCharAmount, setMuleGamesAndCharAmount] = useState({
    free_chars: 0,
    max_games: 0,
  });
  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  if (loadRole) {
    setLoadRole(false);
  }

  const [markAsTrashAfterDaysSCL, setMarkAsTrashAfterDaysSCL] = useState(0);
  const [markAsTrashAfterDaysSCNL, setMarkAsTrashAfterDaysSCNL] = useState(0);
  const [markAsTrashAfterDaysHCL, setMarkAsTrashAfterDaysHCL] = useState(0);
  const [markAsTrashAfterDaysHCNL, setMarkAsTrashAfterDaysHCNL] = useState(0);
  const [disableDroppingWhileMuling, setDisableDroppingWhileMuling] = useState(0);
  const [prescreenRares, setPrescreenRares] = useState(0);
  const [nodeValues, setNodeValues] = useState({});

  const [muleAccountsData, setMuleAccountsData] = useState([]);

  const [disableEndLadderButton, setDisabledEndLadderButton] = useState(false);
  const [selectedMuleAccountIDs, setSelectedMuleAccountIDs] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [addMuleAccountNameField, setAddMuleAccountNameField] = useState('');
  const [addMuleAccountPasswordField, setAddMuleAccountPasswordField] = useState('');
  const [addMuleAccountTokenField, setAddMuleAccountTokenField] = useState('');

  let sendSelectedMuleAccountsToRemove = function () {
    if (!window.confirm('Completing this action will delete all the items etc. Continue?')) {
      return;
    }
    const loading = toast.loading('deleting accounts, please wait');
    axios
      .post(
        config.backend_base_url + 'admin/mule/accs/delete',
        {
          mule_account_ids: selectedMuleAccountIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        setSelectedMuleAccountIDs([]);
        toast.dismiss(loading);
        toast.success('Deleted Mule Accounts.');
        getMuleAccounts();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('Error occured.');
      });
  };

  async function getMuleAccounts() {
    let url = config.backend_base_url + 'admin/mule/acc/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setMuleAccountsData(response.data);
          //depending on the tab we load specific orders and set it as farmAccountsData
        } else {
          setMuleAccountsData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured getting mule accounts. Please refresh the page');
      });
  }

  async function getFreeMuleCharAmount() {
    let url = config.backend_base_url + 'mls/mule/char/amount';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setMuleGamesAndCharAmount(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured getting mule char amount. Please refresh the page');
      });
  }

  async function addMuleAccount() {
    let url = config.backend_base_url + 'admin/mule/acc/add';
    await axios
      .post(
        url,
        {
          account_name: addMuleAccountNameField,
          account_password: addMuleAccountPasswordField,
          account_token: addMuleAccountTokenField,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Added Mule Account.');
        getMuleAccounts();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  }

  async function getNodeDetails() {
    let url = config.backend_base_url + 'node/details';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setNodeValues(response.data);
          if (response.data['ladder_ended'] === 1) {
            setDisabledEndLadderButton(true);
          }
          if (response.data['mark_as_trash_after_days_scl']) {
            setMarkAsTrashAfterDaysSCL(response.data['mark_as_trash_after_days_scl']);
          }
          if (response.data['mark_as_trash_after_days_scnl']) {
            setMarkAsTrashAfterDaysSCNL(response.data['mark_as_trash_after_days_scnl']);
          }
          if (response.data['mark_as_trash_after_days_hcl']) {
            setMarkAsTrashAfterDaysHCL(response.data['mark_as_trash_after_days_hcl']);
          }
          if (response.data['mark_as_trash_after_days_hcnl']) {
            setMarkAsTrashAfterDaysHCNL(response.data['mark_as_trash_after_days_hcnl']);
          }
          if (response.data['disable_dropping_while_muling']) {
            setDisableDroppingWhileMuling(response.data['disable_dropping_while_muling']);
          }
          if (response.data['prescreen_rares']) {
            setPrescreenRares(response.data['prescreen_rares']);
          }
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('You need to choose one specific node to use this page');
      });
  }

  async function useUnclogButton() {
    let url = config.backend_base_url + 'admin/unclog';

    if (
      !window.confirm(
        'Completing this action is irrevertible and can cause issues if you still have active Dropper Instances. Continue?'
      )
    ) {
      return;
    }

    await axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          toast.success('Success!');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured resetting data. Please refresh the page');
      });
  }

  async function endLadderBtnAction() {
    setDisabledEndLadderButton(true);
    let url = config.backend_base_url + 'admin/d2rreset';

    if (
      !window.confirm(
        'Completing this action is irrevertible and will cause issues that might need a complete system relog if you use it randomly.  Continue?'
      )
    ) {
      return;
    }
    if (!window.confirm('Confirm one more time that you want to proceed.')) {
      return;
    }
    const fetchingNotification = toast.loading(
      'Ending ladder.. this can take a while. Dont refresh the page'
    );
    await axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          getNodeDetails();
          toast.success('Success! Please refresh the page');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured executing reset. Please refresh the page');
      });
    toast.dismiss(fetchingNotification);
  }

  if (loadingData) {
    getNodeDetails();
    getMuleAccounts();
    getFreeMuleCharAmount();
    setLoadingData(false);
  }

  const muleColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hidden: true,
    },
    {
      field: 'account_name',
      headerName: 'Account Name',
      flex: 7,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 7,
    },
    {
      field: 'free_slots_amount',
      headerName: 'Free Slots',
      flex: 3,
    },
    {
      field: 'auth_token',
      headerName: 'Auth Token',
      flex: 7,
      editable: true,
    },
    {
      field: 'subnode',
      headerName: 'Subnode',
      flex: 7,
      editable: true,
    },
    {
      field: 'update_btn',
      headerName: 'Update',
      flex: 2,
      renderCell: renderCellFuncUpdate,
    },
    {
      field: 'uncart_acc',
      headerName: 'Uncart Account',
      flex: 2,
      renderCell: renderCellFuncUncartAccount,
    },
    {
      field: 'trash_acc',
      headerName: 'Trash Account',
      flex: 2,
      renderCell: renderCellFuncTrashAccount,
    },
    {
      field: 'resync_acc',
      headerName: 'Resync Account',
      flex: 2,
      renderCell: renderCellFuncResyncAccount,
    },
  ];

  function renderCellFuncUpdate(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const api = params.api;
      const thisRow = {};

      api
        .getAllColumns()
        .filter((c) => c.field !== '__check__' && !!c)
        .forEach((c) => (thisRow[c.field] = params.row[c.field]));
      updateAccToken(thisRow.id, thisRow.auth_token, thisRow.subnode);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Update
      </Button>
    );
  }

  function renderCellFuncResyncAccount(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      ResyncAccount(params.row.id);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Resync
      </Button>
    );
  }

  function renderCellFuncUncartAccount(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      UncartAccount(params.row.id);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Uncart
      </Button>
    );
  }

  function renderCellFuncTrashAccount(params) {
    const onClick = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      TrashAccount(params.row.id);
    };

    return (
      <Button fullWidth={true} onClick={onClick}>
        Trash
      </Button>
    );
  }

  function updateAccToken(id, token, subnode) {
    let url = config.backend_base_url + 'muleacc/token/update';
    axios
      .post(
        url,
        { id: id, auth_token: token, subnode: subnode },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Success');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function ResyncAccount(id) {
    if (window.confirm(t('game.resync.warning'))) {
      const loading = toast.loading(t('game.resync.clientVersion'));
      let url = config.backend_base_url + 'muleacc/resync';
      axios
        .post(url, { id: id }, { headers: { 'session-token': sessionToken } })
        .then((response) => {
          if (response.status) {
            toast.dismiss(loading);
            toast.success(t('game.resync.success'));
          }
        })
        .catch((error) => {
          console.log('Error ========>', error);
          toast.dismiss(loading);
          toast.error(t('game.resync.error'));
        });
    }
  }

  function UncartAccount(id) {
    const loading = toast.loading('uncarting items on account');
    let url = config.backend_base_url + 'account/uncart/' + parseInt(id);
    axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.dismiss(loading);
          toast.success('Successfully uncarted account');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('Error occured. Please refresh the page');
      });
  }
  function TrashAccount(id) {
    const loading = toast.loading('marking items on account as trash');
    let url = config.backend_base_url + 'account/trashmark/' + parseInt(id);
    axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.status) {
          toast.dismiss(loading);
          toast.success('Successfully marked items on account as trash.');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('Error occured. Please refresh the page');
      });
  }

  const [file, setFile] = useState();
  function handleMuleAccCSVFileChange(event) {
    setFile(event.target.files[0]);
  }

  function saveChanges() {
    const loading = toast.loading('Saving node settings');

    let url = config.backend_base_url + 'node/settings/update';
    axios
      .post(
        url,
        {
          id: nodeValues['id'],
          mark_as_trash_after_days_scl: markAsTrashAfterDaysSCL,
          mark_as_trash_after_days_scnl: markAsTrashAfterDaysSCNL,
          mark_as_trash_after_days_hcl: markAsTrashAfterDaysHCL,
          mark_as_trash_after_days_hcnl: markAsTrashAfterDaysHCNL,
          disable_dropping_while_muling: disableDroppingWhileMuling,
          prescreen_rares: prescreenRares,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null) {
          getNodeDetails();
          toast.dismiss(loading);
          toast.success('Successfully updated Settings!');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('error');
      });
  }
  function handleMuleAccCSVSubmit(event) {
    event.preventDefault();
    const url = config.backend_base_url + 'admin/mule/acc/bulk';
    const formData = new FormData();
    formData.append('create_packs', file);
    formData.append('create_packs_name', file.name);
    if (!file.name.includes('.csv')) {
      toast.error('You need to select a valid csv file');
      return;
    }
    const rconfig = {
      headers: {
        'content-type': 'multipart/form-data',
        'session-token': sessionToken,
      },
    };
    axios.post(url, formData, rconfig).then((response) => {
      toast.success('Successfully uploaded item packs');
      console.log(response);
      getMuleAccounts();
    });
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            color="primary"
            onChange={function () {}}
            tabs={[
              {
                tabName: 'accounts',
                tabButton: 'Mule Accounts  (' + muleAccountsData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12}>
                      <b>
                        {t('game.mule.serviceCharsAvailable', {
                          count: muleGameAndCharAmount.free_chars,
                          maxGames: muleGameAndCharAmount.max_games,
                        })}
                      </b>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={muleColumns}
                          rows={muleAccountsData}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            let newSelection = [];
                            params.forEach(function (item) {
                              newSelection.push(item);
                            });
                            setSelectedMuleAccountIDs(newSelection);
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        onClick={function () {
                          sendSelectedMuleAccountsToRemove();
                        }}
                      >
                        Remove Selected Mule Accounts
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="addMuleAccountForm" fullWidth={true}>
                        <CustomInput
                          labelText="Account E-mail"
                          id="addMuleAccountNameField"
                          value={addMuleAccountNameField}
                          onChange={(params) => {
                            setAddMuleAccountNameField(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <CustomInput
                          labelText="Account Password"
                          id="addMuleAccountPasswordField"
                          value={addMuleAccountPasswordField}
                          onChange={(params) => {
                            setAddMuleAccountPasswordField(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <CustomInput
                          labelText="Account Token"
                          id="addMuleAccountTokenField"
                          value={addMuleAccountTokenField}
                          onChange={(params) => {
                            setAddMuleAccountTokenField(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          required
                        />
                        <Button
                          color="primary"
                          type="submit"
                          form="addMuleAccountForm"
                          onClick={addMuleAccount}
                        >
                          Add Mule Account
                        </Button>
                      </FormControl>
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <h3>Upload Bulk Mule accounts</h3>
                        <p>
                          You need to upload a valid csv file (accname, accpw, acctoken). It will
                          always skip the first row, the column names dont matter, but the order
                          that you put the data does. If you need help please ask on Discord.
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <form>
                          <input type="file" onChange={handleMuleAccCSVFileChange} />
                          <Button
                            color={'primary'}
                            size={'sm'}
                            fullWidth={true}
                            onClick={handleMuleAccCSVSubmit}
                          >
                            Upload Mule Acc (.csv)
                          </Button>
                        </form>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'settings',
                tabButton: 'Settings',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <NavPills
                        queryKey={'t2'}
                        alignCenter
                        color="primary"
                        onChange={function () {}}
                        tabs={[
                          {
                            tabName: 'general',
                            tabButton: 'general',
                            tabContent: (
                              <div>
                                <GridItem xs={12} sm={12} md={12}>
                                  <h2>
                                    {nodeValues['node_name']} ({nodeValues['status']}) in{' '}
                                    {nodeValues['realm']} since {nodeValues['created_at']}
                                  </h2>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['is_d2rs_node']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Is D2RS Node"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['show_in_new_listings']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Show your items in new listings (discord)"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['show_in_sales_history']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Show your items in sales history (discord)"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['make_all_items_available_to_d2rshop']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Make all Items available to d2rshop and other fiat transactions"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['make_rares_available_to_d2rshop']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Make RARE Items available to d2rshop and other fiat transactions"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={nodeValues['make_rares_available_to_jsp']}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          checked: classes.switchChecked,
                                          thumb: classes.switchIcon,
                                          track: classes.switchBar,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label="Make RARE Items available to fg transactions"
                                  />
                                </GridItem>
                              </div>
                            ),
                          },
                          {
                            tabName: 'settings',
                            tabButton: 'settings',
                            tabContent: (
                              <GridContainer spacing={1}>
                                <GridItem xs={12} sm={12} md={12}>
                                  <Button
                                    color={'primary'}
                                    size={'xl'}
                                    fullWidth={true}
                                    onClick={saveChanges}
                                  >
                                    💾Save Node Settings
                                  </Button>
                                </GridItem>
                                <GridItem xs={12}>
                                  <CustomInput
                                    type="number"
                                    labelText="Days passed after muling before marking Softcore Ladder (SCL) items as trash"
                                    value={markAsTrashAfterDaysSCL}
                                    onChange={(e) =>
                                      setMarkAsTrashAfterDaysSCL(parseInt(e.target.value))
                                    }
                                    InputProps={{ inputProps: { min: 0, max: 6 } }}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12}>
                                  <CustomInput
                                    type="number"
                                    labelText="Days passed after muling before marking Softcore Non-Ladder (SCNL) items as trash"
                                    value={markAsTrashAfterDaysSCNL}
                                    onChange={(e) =>
                                      setMarkAsTrashAfterDaysSCNL(parseInt(e.target.value))
                                    }
                                    InputProps={{ inputProps: { min: 0, max: 6 } }}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12}>
                                  <CustomInput
                                    type="number"
                                    labelText="Days passed after muling before marking Hardcore Ladder (HCL) items as trash"
                                    value={markAsTrashAfterDaysHCL}
                                    onChange={(e) =>
                                      setMarkAsTrashAfterDaysHCL(parseInt(e.target.value))
                                    }
                                    InputProps={{ inputProps: { min: 0, max: 6 } }}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12}>
                                  <CustomInput
                                    type="number"
                                    labelText="Days passed after muling before marking Hardcore Non-Ladder (HCNL) items as trash"
                                    value={markAsTrashAfterDaysHCNL}
                                    onChange={(e) =>
                                      setMarkAsTrashAfterDaysHCNL(parseInt(e.target.value))
                                    }
                                    InputProps={{ inputProps: { min: 0, max: 6 } }}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12}>
                                  <Select
                                    value={disableDroppingWhileMuling}
                                    fullWidth={true}
                                    onChange={(params) => {
                                      setDisableDroppingWhileMuling(params.target.value);
                                    }}
                                  >
                                    <MenuItem value={1}>
                                      Disable all dropping while muling (node hosts muling only)
                                    </MenuItem>
                                    <MenuItem value={0}>
                                      No drop access changes while muling
                                    </MenuItem>
                                  </Select>
                                </GridItem>
                                <GridItem xs={12}>
                                  <Select
                                    value={prescreenRares}
                                    fullWidth={true}
                                    onChange={(params) => {
                                      setPrescreenRares(params.target.value);
                                    }}
                                  >
                                    <MenuItem value={1}>Prescreen Rare items</MenuItem>
                                    <MenuItem value={0}>Do NOT Prescreen rare items</MenuItem>
                                  </Select>
                                </GridItem>
                              </GridContainer>
                            ),
                          },
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'actions',
                tabButton: 'Actions',
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <NavPills
                        queryKey={'t2'}
                        alignCenter
                        color="primary"
                        onChange={function () {}}
                        tabs={[
                          {
                            tabName: 'unclog',
                            tabButton: 'Unclog',
                            tabContent: (
                              <div>
                                <GridContainer justify="center">
                                  <GridItem xs={12} sm={12} md={12}>
                                    <h2>
                                      Using the Unclog Button will complete the following actions:
                                    </h2>
                                    <br />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <h4>Delete all remaining Dropper Instances on your node</h4>
                                    <br />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <h4>
                                      Unlock all of your accounts so that new Dropper Instances can
                                      enter the accounts
                                    </h4>
                                    <br />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <br />
                                    <h3>
                                      Before using this Button, make sure that all active Dropper
                                      Instances are turned off. They will not be able to reconnect
                                      on their own.
                                    </h3>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <br />
                                    <br />
                                    <Button
                                      fullWidth={true}
                                      color={'warning'}
                                      size={'lg'}
                                      onClick={useUnclogButton}
                                    >
                                      Unclog Button
                                    </Button>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            ),
                          },
                          {
                            tabName: 'eol',
                            tabButton: 'END LADDER',
                            tabContent: (
                              <div>
                                <GridContainer justify="center">
                                  {nodeValues['ladder_ended'] === 1 && (
                                    <GridItem xs={12} sm={12} md={12}>
                                      <h2 style={{ color: 'red' }}>
                                        You have already ended ladder
                                      </h2>
                                      <br />
                                    </GridItem>
                                  )}
                                  {nodeValues['ladder_ended'] === 0 && (
                                    <GridItem xs={12} sm={12} md={12}>
                                      <h1 style={{ color: 'green' }}>You have not ended ladder</h1>
                                    </GridItem>
                                  )}
                                  {nodeValues['end_of_ladder_function'] === 1 && (
                                    <GridItem xs={12} sm={12} md={12}>
                                      <h2>Functionality 1 (Migrate to Non-Ladder)</h2>
                                      <h3>
                                        Create trades on each applicable context to drop all unique
                                        charms (shared stashes must be empty)
                                      </h3>
                                      <h3>
                                        Set all your items, mule accounts and other applicable data
                                        to be on Non Ladder.
                                      </h3>
                                      <h3>Disable your node until reset.</h3>
                                    </GridItem>
                                  )}
                                  {nodeValues['end_of_ladder_function'] === 2 && (
                                    <GridItem xs={12} sm={12} md={12}>
                                      <h2>Functionality 2 (Reuse Ladder accounts)</h2>
                                      <h3>Mark all your ladder items as sold</h3>
                                      <h3>
                                        Create a job for each ladder account to delete all chars
                                      </h3>
                                      <h3>Disable your node until reset.</h3>
                                    </GridItem>
                                  )}
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Button
                                      fullWidth={true}
                                      disabled={disableEndLadderButton}
                                      color={'warning'}
                                      size={'lg'}
                                      onClick={endLadderBtnAction}
                                    >
                                      END LADDER NOW!
                                    </Button>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer pageKey="nodePage" helpText={t('help.nodePage')} />
    </div>
  );
}
