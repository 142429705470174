import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import CustomInput from '../../components/CustomInput/CustomInput';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import blueIcon from '../../assets/img/blue_icon.webp';
import yellowIcon from '../../assets/img/yellow_icon.png';

import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Close from '@mui/icons-material/Close';
import config from '../../config/config.json';
import NavPills from '../../components/NavPills/NavPills';
import TextField from '@mui/icons-material/TextFields';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { default as Autotextfield } from '@mui/material/TextField/TextField';
import { useTranslation } from 'react-i18next';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles(styles);
export default function SupplierItemPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.suppliedTrashItems'));
  const classes = useStyles();

  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  const [btnPressed, setBtnPressed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [addRarityFilterID, setAddRarityFilterID] = useState('0');
  const [addTypeFilterID, setAddTypeFilterID] = useState('0');
  const [addNewItemsFilterID, setAddNewItemsFilterID] = useState('0');
  const [addEthItemsFilterID, setAddEthItemsFilterID] = useState('0');
  const [unidFilterID, setUnidFilterID] = useState('0');
  const [limitFilter, setLimitFilter] = useState(10000);
  const [addStatFilterID, setAddStatFilterID] = useState('0');
  const [addStatFilterMin, setAddStatFilterMin] = useState(1);
  const [addStatFilterMax, setAddStatFilterMax] = useState(10000);

  const [oldCheck, setOldCheck] = useState(addStatFilterID);
  React.useEffect(() => {
    if (oldCheck !== addStatFilterID) {
      setOldCheck(addStatFilterID);
      addStatRequirementForSearch();
    }
  });
  const [searchStatFilters, setSearchStatFilters] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [searchNewItemsFilters] = useState([
    {
      id: 1,
      name: t('search.filters.newItemsOnly'),
      selected: false,
    },
    {
      id: 2,
      name: t('search.filters.excludeNewItems'),
      selected: false,
    },
  ]);
  const [searchEthItemsFilters] = useState([
    {
      id: 1,
      name: t('search.filters.ethItemsOnly'),
      selected: false,
    },
    {
      id: 2,
      name: t('search.filters.excludeEthItems'),
      selected: false,
    },
  ]);
  const [searchUnidItemsFilters] = useState([
    {
      id: 1,
      name: t('search.filters.unidItemsOnly'),
      selected: false,
    },
    {
      id: 2,
      name: t('search.filters.excludeUnidItems'),
      selected: false,
    },
  ]);

  const [itemData, setItemData] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  function clearSearch() {
    setSearchTerm('');
    setAddRarityFilterID(0);
    setAddTypeFilterID(0);
    setAddNewItemsFilterID(0);
    setAddEthItemsFilterID(0);
    setUnidFilterID(0);
    setLimitFilter(1000);
    setSearchStatFilters([]);
    setItemData([]);
  }
  const [savedSearchSent, setSavedSearchSent] = useState(false);
  const [oldSavedSearchSent, setOldSavedSearchSent] = useState(false);
  React.useEffect(() => {
    if (oldSavedSearchSent !== savedSearchSent) {
      setOldSavedSearchSent(savedSearchSent);
      getItemsSearch();
    }
  });

  function onStatFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddStatFilterID(values.id);
  }

  function onTypeFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddTypeFilterID(values.id);
  }

  function onRarityFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddRarityFilterID(values.id);
  }

  function activateSelectedAsSearch(searchTerm) {
    setSearchTerm(searchTerm);
    setSavedSearchSent(!savedSearchSent);
  }
  async function getSavedSearches() {
    let url = config.backend_base_url + 'ml/search/mine';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setSavedSearches(response.data);
        } else {
          setSavedSearches([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }
  async function downloadSupplierCSV() {
    setBtnPressed(true);
    const loading = toast.loading(
      'Generating CSV, this may take a while. DO NOT refresh the page! Download starts automatically after the file is ready.'
    );
    let url = config.backend_base_url + 'supplier/csv';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        toast.dismiss(loading);
        toast.success('Done generating csv!');
        window.location.assign(config.backend_base_url + 'csv/' + response.data);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getItemsSearch() {
    let url = config.backend_base_url + 'items/search';
    const loading = toast.loading(
      'Loading data.. this can take a minute, please wait for this notification to disappear'
    );
    axios
      .post(
        url,
        {
          search_term: searchTerm,
          rarity_filter_id: parseInt(addRarityFilterID),
          type_filter_id: parseInt(addTypeFilterID),
          new_items_filter: parseInt(addNewItemsFilterID),
          eth_items_filter: parseInt(addEthItemsFilterID),
          unid_filter: parseInt(unidFilterID),
          stat_filters: searchStatFilters,
          limit: parseInt(limitFilter),
          only_own_stock: 1,
          include_unpriced_items: 1,
          include_uncartable_items: 1,
          include_listed_items: 1,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          toast.dismiss(loading);
          toast.success('Search successful [' + response.data.length + ']');
          setItemData(response.data);
        } else {
          toast.dismiss(loading);
          toast.error('Search found no matches');
          setItemData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loading);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemStats() {
    let url = config.backend_base_url + 'stats/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemStats(response.data);
        } else {
          setItemStats([]);
        }
        setLoadingItems(false);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemRarities() {
    let url = config.backend_base_url + 'rarities/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemRarities(response.data);
        } else {
          setItemRarities([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemTypes() {
    let url = config.backend_base_url + 'categories/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemTypes(response.data);
        } else {
          setItemTypes([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  async function getStockOverview() {
    await axios
      .get(config.backend_base_url + 'stock/overview/mine', {
        headers: { 'session-token': sessionToken },
      })
      .then((response) => {
        if (response.data != null) {
          setStockOverview(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  let defaultMuleRealm = localStorage.getItem('default_realm');
  if (!defaultMuleRealm) {
    defaultMuleRealm = 'europe';
  }
  const [realm, setRealm] = useState(defaultMuleRealm);
  const [trashItemData, setTrashItemData] = useState([]);
  const [trashBtnDisabled, setTrashBtnDisabled] = useState(false);
  const [activeTrashItem, setActiveTrashItem] = useState({});

  const [trashGameName, setTrashGameName] = useState('');
  const [trashGamePassword, setTrashGamePassword] = useState('');
  const [mlSelection, setMLSelection] = useState([]);

  let trashCellClick = function (params, event) {
    event.defaultMuiPrevented = true;
    setActiveTrashItem({
      id: params.row.id,
      name: params.row.name,
      stat_description: params.row.stat_description,
      image_path: 'https://d2r-member.com/image/' + params.row.image_path,
    });
  };

  let relistSelectedItems = function (itemIDs) {
    axios
      .post(
        config.backend_base_url + 'trash/remove',
        {
          item_ids: itemIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        getTrashItems();
      })
      .catch((error) => {
        console.log('Error ========>', error);
      });
  };

  async function getTrashItems() {
    let url = config.backend_base_url + 'admin/trash/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          if (response.data['items']) {
            setTrashItemData(response.data['items']);
          } else {
            setTrashItemData([]);
            setTrashBtnDisabled(true);
          }
          setTrashGameName(response.data['game_name']);
          setTrashGamePassword(response.data['game_password']);
        } else {
          setTrashItemData([]);
          setTrashBtnDisabled(true);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function copyFTList() {
    let url = config.backend_base_url + 'ml/ft/selected/list';
    axios
      .post(
        url,
        {
          item_ids: trashItemData.map((item) => item.id),
          delimiter: '|',
          show_prices: 0,
          type_header_size: 3,
          rarity_header_size: 4,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          navigator.clipboard.writeText(response.data);
          toast.success('FT List copied to clipboard!');
        } else {
          toast.error('No matches');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occurred. Please refresh the page');
      });
  }

  let initiateTrashCleanup = function () {
    if (
      !window.confirm(
        `This will start your trash session (${trashGameName}/${trashGamePassword}/${realm}) and cannot be stopped. Continue?`
      )
    ) {
      return;
    }
    setTrashBtnDisabled(true);
    axios
      .post(
        config.backend_base_url + 'admin/trash/create',
        {
          realm: realm,
          game_name: trashGameName,
          game_password: trashGamePassword,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Success!');
      })
      .catch((error) => {
        toast.error(error.response.data['Message']);
      });
  };

  if (loadingItems) {
    getTrashItems();
    getItemsSearch();
    getItemStats();
    getItemRarities();
    getItemTypes();
    getSavedSearches();
    getStockOverview();
    setLoadingItems(false);
  }

  const [activeItem, setActiveItem] = useState({
    id: 1,
    name: 'Selected Item Name',
    image_path: 'https://d2r-member.com/image/' + 'JAH_RUNE.png',
    skin_image_path: 'https://d2r-member.com/skin/' + 'JAH_RUNE.png',
  });

  const [itemStats, setItemStats] = useState([]);
  const [itemRarities, setItemRarities] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [stockOverview, setStockOverview] = useState([]);
  const stockOverviewColumns = [
    {
      field: 'item_name',
      headerName: t('search.columns.name'),
      flex: 5,
    },
    {
      field: 'amount',
      headerName: t('search.columns.quantity'),
      flex: 1,
    },
  ];

  function getColorFromRarity(rarName) {
    const textColorMap = {
      WHITE: '#FFFFFF',
      SET: '#00FF00',
      MAGIC: '#4169E1',
      UNIQUE: '#A59263',
      CRAFTED: '#FFA800',
      RARE: '#FFFF00',
    };

    return textColorMap[rarName.toUpperCase()] || '#FFFFFF';
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'min_price',
      headerName: 'Minimum Price',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Item Name',
      flex: 6,
      renderCell: (params) => {
        const skinpath = config.backend_base_url + 'skin/' + params.row.skin_image_path;
        const path = config.backend_base_url + 'image/' + params.row.image_path;
        const rarityColor = getColorFromRarity(params.row.original_rarity);
        return (
          <Tooltip
            title={
              <div>
                {params.value}
                <br />
                <img
                  src={path}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.value}
                />
              </div>
            }
            arrow
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  marginRight: '4px',
                  backgroundColor: rarityColor,
                }}
              />
              <img
                style={{ maxHeight: '32px', maxWidth: '32px', marginRight: '4px' }}
                src={skinpath}
                alt={''}
              />
              {params.row.name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'stats_description',
      headerName: 'Stats',
      flex: 14,
      renderCell: (params) => {
        const path = config.backend_base_url + 'image/' + params.row.image_path;
        return (
          <Tooltip
            title={
              <div>
                {params.value}
                <br />
                <img
                  src={path}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.row.name}
                />
              </div>
            }
            arrow
          >
            <div>{params.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'shareholder_name',
      headerName: 'Owner',
      flex: 3,
    },
    {
      field: 'reseller_name',
      headerName: 'Reseller',
      flex: 3,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 3,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 3,
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 4,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      displayName: 'Actions',
      renderCell: renderCellFuncActionList,
    },
  ];

  function addNoteToItem(itID, note) {
    let url = config.backend_base_url + 'note/add';
    if (note === '') {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
          note: note,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Added note:' + note);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  function addMinPriceToItem(itID, minPrice) {
    let url = config.backend_base_url + 'minprice/add';
    if (minPrice === '') {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    if (isNaN(minPrice)) {
      toast.error('Price needs to be a valid whole number');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
          min_price: minPrice,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Added minimum price:' + minPrice);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  let sendSelectedTrashItems = function (itemIDs) {
    axios
      .post(
        config.backend_base_url + 'trash/add',
        {
          item_ids: itemIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Marked items as trash');
        getTrashItems();
      })
      .catch((error) => {
        console.log('Error ========>', error);
      });
  };

  let sendRemoveItemsFunc = function (ids) {
    axios
      .post(
        config.backend_base_url + 'cart/remove',
        {
          item_ids: ids,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.status);
        getItemsSearch();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  function releasePrescreenItem(itID) {
    let url = config.backend_base_url + 'prescreen/release';

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Released item from prescreening');
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  function addPrescreenItem(itID) {
    let url = config.backend_base_url + 'prescreen/add';

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('added item to prescreening');
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  function renderCellFuncActionList(params) {
    const trashItems = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      sendSelectedTrashItems([params.row.id]);
    };
    const addNote = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt('Enter the note you want to add to the item');
      if (!resp) {
        return;
      }
      addNoteToItem(params.row.id, resp);
    };
    const addMinPrice = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt(
        'Enter the minimum price you want to add to the item. Currently that price will not be enforced, but it will be visible to sellers'
      );
      if (!resp) {
        return;
      }
      addMinPriceToItem(params.row.id, resp);
    };

    const removeFromCart = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      if (params.row.status !== 'in_cart') {
        toast.error("Can only remove items with 'in_cart' status");
        return;
      }
      sendRemoveItemsFunc([params.row.id]);
    };

    const releasePrescreen = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      releasePrescreenItem(params.row.id);
    };
    const addPrescreen = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      addPrescreenItem(params.row.id);
    };

    let infoText = 'ID: ' + params.row.id;
    infoText += '<br/>  Note: ' + params.row.note;
    infoText += '<br/>  Minimum Price: ' + params.row.min_price + ' fg';
    infoText += '<br/>  Status: ' + params.row.status;
    const path = config.backend_base_url + 'image/' + params.row.image_path;

    let trashD = (
      <Tooltip
        title={
          <div>
            Mark this item as TRASH
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={trashItems}>🗑️</div>
      </Tooltip>
    );

    let noteD = (
      <Tooltip
        title={
          <div>
            Add a note to this item
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addNote}>✍️</div>
      </Tooltip>
    );

    let prescreenD = (
      <Tooltip
        title={
          <div>
            Release item from prescreening
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={releasePrescreen}>👌</div>
      </Tooltip>
    );

    let addPrescreenD = (
      <Tooltip
        title={
          <div>
            Add item to prescreening
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addPrescreen}>🔍</div>
      </Tooltip>
    );

    let minPriceD = (
      <Tooltip
        title={
          <div>
            Add a minimum price to this item
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addMinPrice}>🏷️</div>
      </Tooltip>
    );

    let infoD = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: infoText }} />
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div>🇮</div>
      </Tooltip>
    );

    let removeFromCartD = (
      <Tooltip
        title={
          <div>
            Remove Item from Cart
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={removeFromCart}>❌</div>
      </Tooltip>
    );

    const viewD = (
      <Tooltip title={'VIEW this item on this nodes shop'} arrow>
        <a
          href={params.row.d2rshopproductlink}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={yellowIcon} alt="View in Shop" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    const viewD2rsD = (
      <Tooltip title={'VIEW this item on D2RS'} arrow>
        <a
          href={`/i/${params.row.id}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={blueIcon} alt="View on D2RS" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    return (
      <span>
        {infoD}
        {noteD}
        {params.row.status !== 'sold' && params.row.status !== 'in_drop_order' && minPriceD}
        {params.row.marked_as_trash === 0 &&
          params.row.status !== 'sold' &&
          params.row.status !== 'in_drop_order' &&
          params.row.d2rshopproductlink === '' &&
          trashD}
        {params.row.status === 'in_cart' && removeFromCartD}
        {params.row.prescreened === 0 && prescreenD}
        {params.row.prescreened === 1 && addPrescreenD}
        {params.row.d2rshopproductlink !== '' && viewD}
        {viewD2rsD}
      </span>
    );
  }

  function getItemsSearchByEnter(event) {
    if (event.key === 'Enter') {
      getItemsSearch();
    }
  }

  let cellClick = function (params, event) {
    event.defaultMuiPrevented = true;
    setActiveItem({
      id: params.row.id,
      name: params.row.name,
      image_path: 'https://d2r-member.com/image/' + params.row.image_path,
      skin_image_path: 'https://d2r-member.com/skin/' + params.row.skin_image_path,
    });
  };

  let addStatRequirementForSearch = function () {
    let statName = '';

    itemStats.forEach(function (it) {
      if (it.id === addStatFilterID) {
        statName = it.name;
      }
    });

    setSearchStatFilters(
      searchStatFilters.concat({
        id: addStatFilterID,
        name: statName,
        min: parseInt(addStatFilterMin),
        max: parseInt(addStatFilterMax),
      })
    );
  };

  const [selectedBulkAction, setSelectedBulkAction] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const handleBulkAction = async () => {
    if (!selectedItems.length) {
      toast.error(t('errors.noItemsSelected'));
      return;
    }

    const uniqueIDs = selectedItems.filter((c, index) => {
      return selectedItems.indexOf(c) === index;
    });

    switch (selectedBulkAction) {
      case 'note': {
        const note = window.prompt(t('prompts.enterNote'));
        if (!note) return;

        try {
          await axios.post(
            config.backend_base_url + 'note/add/bulk',
            {
              item_ids: uniqueIDs,
              note: note,
            },
            { headers: { 'session-token': sessionToken } }
          );
          toast.success(t('success.noteAdded'));
          await getItemsSearch();
          setSelectedItems(uniqueIDs);
        } catch (error) {
          console.log('Error ========>', error);
          toast.error(error.response?.data?.Message || error.message);
        }
        break;
      }
      case 'minPrice': {
        const minPrice = window.prompt(t('prompts.enterMinPrice'));
        if (!minPrice || isNaN(parseFloat(minPrice))) {
          toast.error(t('errors.invalidPrice'));
          return;
        }

        try {
          await axios.post(
            config.backend_base_url + 'minprice/add/bulk',
            {
              item_ids: uniqueIDs,
              min_price: minPrice,
            },
            { headers: { 'session-token': sessionToken } }
          );
          toast.success(t('success.minPriceAdded'));
          await getItemsSearch();
          setSelectedItems(uniqueIDs);
        } catch (error) {
          console.log('Error ========>', error);
          toast.error(error.response?.data?.Message || error.message);
        }
        break;
      }
      case 'trash': {
        if (!window.confirm(t('prompts.confirmTrash', { count: uniqueIDs.length }))) {
          return;
        }

        try {
          await sendSelectedTrashItems(uniqueIDs);
          toast.success(t('success.itemsTrashed'));
          await getItemsSearch();
          setSelectedItems(uniqueIDs);
        } catch (error) {
          console.log('Error ========>', error);
          toast.error(error.response?.data?.Message || error.message);
        }
        break;
      }
      default:
        toast.error(t('errors.selectAction'));
    }
    setSelectedBulkAction('');
  };

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            fullWidth
            onChange={function () {}}
            color="primary"
            tabs={[
              {
                tabName: 'items',
                tabButton: 'Supplied Items (' + itemData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem md={2} xl={2} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="savedSearchesAutocomplete"
                        options={savedSearches}
                        getOptionLabel={(option) =>
                          '' + option.search_count + 'x ' + option.search_name
                        }
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.savedSearches.choose')} />
                        )}
                        onChange={(event, selectedOption) =>
                          activateSelectedAsSearch(selectedOption.search_term)
                        }
                      />
                    </GridItem>
                    <GridItem md={1} xl={1}>
                      <CustomInput
                        labelText={t('form.minValue')}
                        id="minValue"
                        value={addStatFilterMin}
                        onChange={(params) => {
                          setAddStatFilterMin(params.target.value);
                        }}
                        inputProps={{
                          type: 'number',
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem md={1} xl={1}>
                      <CustomInput
                        labelText={t('form.maxValue')}
                        id="maxValue"
                        value={addStatFilterMax}
                        onChange={(params) => {
                          setAddStatFilterMax(params.target.value);
                        }}
                        inputProps={{
                          type: 'number',
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem md={2} xl={2} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="searchForStatAutocomplete"
                        options={itemStats}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.stats.searchLabel')} />
                        )}
                        onChange={onStatFilterChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="searchForStatAutocomplete"
                        options={itemRarities}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.filters.rarity')} />
                        )}
                        onChange={onRarityFilterChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                      <Autocomplete
                        id="searchForStatAutocomplete"
                        options={itemTypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.filters.type')} />
                        )}
                        onChange={onTypeFilterChange}
                      />
                    </GridItem>
                    <GridItem md={1} xl={1} style={{ paddingTop: 10 }}>
                      <Select
                        id="newItemsFilter"
                        fullWidth={true}
                        value={addNewItemsFilterID}
                        onChange={(params) => {
                          setAddNewItemsFilterID(params.target.value);
                        }}
                      >
                        <MenuItem value={'0'}>{t('search.filters.newItemsFilter')}</MenuItem>
                        {searchNewItemsFilters.map((boolFilter, key) => (
                          <MenuItem key={key} value={boolFilter.id}>
                            {boolFilter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <GridItem md={1} xl={1} style={{ paddingTop: 10 }}>
                      <Select
                        id="unidItemsFilter"
                        fullWidth={true}
                        value={unidFilterID}
                        onChange={(params) => {
                          setUnidFilterID(params.target.value);
                        }}
                      >
                        <MenuItem value={'0'}>{t('search.filters.unidItemFilter')}</MenuItem>
                        {searchUnidItemsFilters.map((boolFilter, key) => (
                          <MenuItem key={key} value={boolFilter.id}>
                            {boolFilter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                      <Select
                        id="ethItemsFilter"
                        fullWidth={true}
                        value={addEthItemsFilterID}
                        onChange={(params) => {
                          setAddEthItemsFilterID(params.target.value);
                        }}
                      >
                        <MenuItem value={'0'}>{t('search.filters.ethItemFilter')}</MenuItem>
                        {searchEthItemsFilters.map((boolFilter, key) => (
                          <MenuItem key={key} value={boolFilter.id}>
                            {boolFilter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} xl={1}>
                      <CustomInput
                        labelText={t('search.limit')}
                        id="limitFilter"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={limitFilter}
                        onChange={(params) => {
                          setLimitFilter(params.target.value);
                        }}
                        inputProps={{
                          type: 'number',
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      {searchStatFilters.map((filter, key) => (
                        <Button
                          onClick={() =>
                            setSearchStatFilters(
                              searchStatFilters.filter(function (it) {
                                return it.id !== filter.id;
                              })
                            )
                          }
                          key={key}
                          color="primary"
                          size="sm"
                        >
                          <div style={{ contentAlign: 'center' }}>
                            <Close></Close>
                            {filter.name}: {filter.min} - {filter.max}
                          </div>
                        </Button>
                      ))}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} xl={6}>
                      <CustomInput
                        labelText={t('search.term')}
                        id="searchTerm"
                        value={searchTerm}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onKeyDown={getItemsSearchByEnter}
                        onChange={(params) => {
                          setSearchTerm(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Search className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                      <Button
                        color="primary"
                        fullWidth={true}
                        type="submit"
                        form="searchParamsForm"
                        onClick={getItemsSearch}
                      >
                        {t('search.buttons.search')} ({itemData.length})
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                      <Button
                        color="danger"
                        fullWidth={true}
                        type="submit"
                        form="searchParamsForm"
                        onClick={clearSearch}
                      >
                        {t('search.buttons.clear')}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {selectedItems.length > 0 && (
                        <GridContainer spacing={1} style={{ marginBottom: '10px' }}>
                          <GridItem xs={12} sm={8} md={8}>
                            <Select
                              value={selectedBulkAction}
                              onChange={(e) => setSelectedBulkAction(e.target.value)}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                {t('actions.selectBulkAction')}
                              </MenuItem>
                              <MenuItem value="note">{t('actions.addNote')}</MenuItem>
                              <MenuItem value="minPrice">{t('actions.setMinPrice')}</MenuItem>
                              <MenuItem value="trash">{t('actions.moveToTrash')}</MenuItem>
                            </Select>
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <Button
                              color="primary"
                              onClick={handleBulkAction}
                              disabled={!selectedBulkAction}
                              fullWidth
                            >
                              {t('actions.applyToSelected')} ({selectedItems.length})
                            </Button>
                          </GridItem>
                        </GridContainer>
                      )}
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          getRowHeight={() => 'auto'}
                          columns={columns}
                          rows={itemData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            setSelectedItems(params);
                          }}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                                note: false,
                              },
                            },
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        fullWidth={true}
                        disabled={btnPressed}
                        type="submit"
                        form="searchParamsForm"
                        onClick={downloadSupplierCSV}
                      >
                        Download CSV
                      </Button>
                      {btnPressed ? (
                        <div>
                          Generating CSV, this may take a while. DO NOT refresh the page! Download
                          starts automatically after the file is ready.
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div>
                        <b>{activeItem.name}</b>
                      </div>
                      <img
                        src={activeItem.skin_image_path}
                        style={{ border: '1px solid black' }}
                        alt={activeItem.name}
                      />
                      <img
                        src={activeItem.image_path}
                        style={{ border: '1px solid black' }}
                        alt={activeItem.name}
                      />
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'stockOverview',
                tabButton: t('search.stockOverview') + ' (' + stockOverview.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Autocomplete
                        id="stockOverviewAutocomplete"
                        options={stockOverview}
                        getOptionLabel={(option) => option.item_name + ' (' + option.amount + ')'}
                        renderInput={(params) => (
                          <Autotextfield {...params} label={t('search.term')} />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          getRowHeight={() => 'auto'}
                          columns={stockOverviewColumns}
                          rows={stockOverview}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 100 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50, 100]}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabName: 'trash',
                tabButton: 'Trash Items (' + trashItemData.length + ')',
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={4} xl={4}>
                      <CustomInput
                        labelText="Game Name"
                        id="gameName"
                        value={trashGameName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setTrashGameName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4}>
                      <CustomInput
                        labelText="Game Password"
                        id="gamePassword"
                        value={trashGamePassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setTrashGamePassword(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextField />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4} style={{ paddingTop: 10 }}>
                      <Select
                        value={realm}
                        fullWidth={true}
                        onChange={(params) => {
                          setRealm(params.target.value);
                          localStorage.setItem('default_realm', params.target.value);
                        }}
                      >
                        <MenuItem value={'americas'}>Americas</MenuItem>
                        <MenuItem value={'europe'}>Europe</MenuItem>
                        <MenuItem value={'asia'}>Asia</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button
                        fullWidth={true}
                        color="primary"
                        disabled={trashBtnDisabled}
                        onClick={initiateTrashCleanup}
                      >
                        💧Start Trash Session ({trashItemData.length})
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <Button
                        fullWidth={true}
                        disabled={trashItemData.length === 0}
                        color="danger"
                        onClick={copyFTList}
                      >
                        📜Copy FT&nbsp;<b>Text</b>&nbsp;List from {trashItemData.length} trash items
                        to Clipboard
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowHeight={() => 'auto'}
                          getRowWidth={() => 'auto'}
                          columns={columns}
                          rows={trashItemData}
                          pageSize={100}
                          rowsPerPageOptions={[10]}
                          onCellClick={trashCellClick}
                          checkboxSelection
                          selectionModel={mlSelection}
                          onRowSelectionModelChange={(params) => {
                            relistSelectedItems(params);
                            setMLSelection([]);
                          }}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                min_price: false,
                                ocr_string: false,
                                skin_image_path: false,
                              },
                            },
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12}>
                      <div>
                        <b>{activeTrashItem.name}</b>
                      </div>
                      <img
                        src={activeTrashItem.image_path}
                        style={{ backgroundSize: 'contain', border: '1px solid black' }}
                        alt={activeTrashItem.name}
                      />
                    </GridItem>
                  </GridContainer>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer pageKey="supplierItemPage" helpText={t('help.supplierItemPage')} />
    </div>
  );
}
