import axios from 'axios';
import config from '../config/config.json';
import toast from 'react-hot-toast';

interface AdminSettings {
  forum_link?: string;
  paypal_email?: string;
  mule_game_name?: string;
  mule_game_password?: string;
  show_help_bubble?: number;
  show_mule_bubble?: number;
  show_tracker_bubble?: number;
  show_scrollable_icon_left?: number;
  show_scrollable_icon_right?: number;
}

class SettingsService {
  private static instance: SettingsService;
  private sessionToken: string | null = null;

  private constructor() {
    this.sessionToken = localStorage.getItem('session_token');
  }

  public static getInstance(): SettingsService {
    if (!SettingsService.instance) {
      SettingsService.instance = new SettingsService();
    }
    return SettingsService.instance;
  }

  public async syncSettings(): Promise<void> {
    if (!this.sessionToken) return;

    try {
      const response = await axios.get(`${config.backend_base_url}admin/settings`, {
        headers: { 'session-token': this.sessionToken },
      });

      if (response.data) {
        const settings: AdminSettings = response.data;

        // Update local storage with settings
        if (settings.show_help_bubble !== undefined) {
          localStorage.setItem('show_help_bubble', settings.show_help_bubble.toString());
        }
        if (settings.show_mule_bubble !== undefined) {
          localStorage.setItem('show_mule_bubble', settings.show_mule_bubble.toString());
        }
        if (settings.show_tracker_bubble !== undefined) {
          localStorage.setItem('show_tracker_bubble', settings.show_tracker_bubble.toString());
        }
        if (settings.show_scrollable_icon_left !== undefined) {
          localStorage.setItem(
            'show_scrollable_icon_left',
            settings.show_scrollable_icon_left.toString()
          );
        }
        if (settings.show_scrollable_icon_right !== undefined) {
          localStorage.setItem(
            'show_scrollable_icon_right',
            settings.show_scrollable_icon_right.toString()
          );
        }
      }
    } catch (error) {
      console.error('Error syncing settings:', error);
      toast.error('Error occurred while syncing settings');
    }
  }
}

export default SettingsService;
