const analyticsPageStyle = (theme) => ({
  cardTitle: {
    marginTop: '0',
    minHeight: 'auto',
    fontWeight: '500',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    color: theme.palette.type === 'dark' ? '#e0e0e0' : '#333333',
  },
  cardCategory: {
    color: theme.palette.type === 'dark' ? '#a0a0a0' : '#999999',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  fiatValue: {
    color: '#85BB65 !important',
    fontWeight: 500,
  },
  fgValue: {
    color: '#ff9800 !important',
    fontWeight: 500,
  },
  analyticsItem: {
    textAlign: 'center',
    padding: '16px',
    backgroundColor: theme.palette.type === 'dark' ? '#2d2d2d' : '#ffffff',
    border: theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
    borderRadius: '4px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  analyticsLabel: {
    fontSize: '0.875rem',
    color: theme.palette.type === 'dark' ? '#a0a0a0' : theme.palette.text.secondary,
    marginBottom: '4px',
  },
  analyticsValue: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: theme.palette.type === 'dark' ? '#e0e0e0' : theme.palette.text.primary,
  },
  bestDaySection: {
    marginTop: '48px',
    padding: '16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.type === 'dark' ? '#363636' : '#ffffff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    height: 'auto',
    width: '100%',
  },
  bestDayHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    borderBottom:
      theme.palette.type === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.1)'
        : `1px solid ${theme.palette.divider}`,
  },
  bestDayItems: {
    padding: '16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.type === 'dark' ? '#2d2d2d' : '#f5f5f5',
  },
  scrollableSection: {
    maxHeight: '250px',
    overflowY: 'auto',
    padding: '8px',
    backgroundColor: theme.palette.type === 'dark' ? '#2d2d2d' : '#ffffff',
    borderColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : theme.palette.divider,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.type === 'dark' ? '#2d2d2d' : '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.type === 'dark' ? '#666' : '#ccc',
      borderRadius: '4px',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom:
      theme.palette.type === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.1)'
        : `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
    },
  },
  itemInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  ocrString: {
    fontSize: '0.75rem',
    color: theme.palette.type === 'dark' ? '#a0a0a0' : theme.palette.text.secondary,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  saleDetails: {
    color: theme.palette.type === 'dark' ? '#a0a0a0' : theme.palette.text.secondary,
  },
});

export default analyticsPageStyle;
