import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import config from '../../config/config.json';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import { FormControlLabel, Switch } from '@mui/material';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
}));

export default function SettingsPage(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.settings'));
  const sessionToken = localStorage.getItem('session_token') || '';

  const [userForumLink, setUserForumLink] = useState<string>('');
  const [userPaypalEmail, setUserPaypalEmail] = useState<string>('');
  const [defaultGameName, setDefaultGameName] = useState<string>('');
  const [defaultGamePassword, setDefaultGamePassword] = useState<string>('');
  const [settingsValues, setSettingsValues] = useState<Record<string, string>>({});
  const [muleGameName, setMuleGameName] = useState<string>('');
  const [muleGamePassword, setMuleGamePassword] = useState<string>('');
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showHelpBubble, setShowHelpBubble] = useState<number>(
    parseInt(localStorage.getItem('show_help_bubble') || '1', 10)
  );
  const [showMuleBubble, setShowMuleBubble] = useState<number>(
    parseInt(localStorage.getItem('show_mule_bubble') || '1', 10)
  );
  const [showScrollableIconLeft, setShowScrollableIconLeft] = useState<number>(
    parseInt(localStorage.getItem('show_scrollable_icon_left') || '1', 10)
  );
  const [showScrollableIconRight, setShowScrollableIconRight] = useState<number>(
    parseInt(localStorage.getItem('show_scrollable_icon_right') || '1', 10)
  );
  const [showTrackerBubble, setShowTrackerBubble] = useState<number>(
    parseInt(localStorage.getItem('show_tracker_bubble') || '1', 10)
  );

  async function getAdminSettings() {
    const url = config.backend_base_url + 'admin/settings';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setSettingsValues(response.data);

          if (response.data['forum_link']) {
            setUserForumLink(response.data['forum_link']);
          }
          if (response.data['paypal_email']) {
            setUserPaypalEmail(response.data['paypal_email']);
          }
          if (response.data['mule_game_name']) {
            setDefaultGameName(response.data['mule_game_name']);
            if (muleGameName === '') {
              setMuleGameName(response.data['mule_game_name']);
            }
          }
          if (response.data['mule_game_password']) {
            setDefaultGamePassword(response.data['mule_game_password']);
            if (muleGamePassword === '') {
              setMuleGamePassword(response.data['mule_game_password']);
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data['Message']);
      });
  }

  async function updateAdminSettings() {
    const url = config.backend_base_url + 'admin/settings/update';
    await axios
      .post(
        url,
        {
          paypal_email: userPaypalEmail,
          forum_link: userForumLink,
          mule_game_name: defaultGameName,
          mule_game_password: defaultGamePassword,
          show_help_bubble: showHelpBubble,
          show_mule_bubble: showMuleBubble,
          show_tracker_bubble: showTrackerBubble,
          show_scrollable_icon_left: showScrollableIconLeft,
          show_scrollable_icon_right: showScrollableIconRight,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null) {
          getAdminSettings();
          localStorage.setItem('show_help_bubble', showHelpBubble.toString());
          localStorage.setItem('show_mule_bubble', showMuleBubble.toString());
          localStorage.setItem('show_tracker_bubble', showTrackerBubble.toString());
          localStorage.setItem('show_scrollable_icon_left', showScrollableIconLeft.toString());
          localStorage.setItem('show_scrollable_icon_right', showScrollableIconRight.toString());
          toast.success('Successfully updated Settings!');
        }
      })
      .catch((error) => {
        toast.error(error.response.data['Message']);
      });
  }

  const handleTabChange = () => {
    // The NavPills component will handle the URL parameter update automatically
  };

  if (!sessionToken) {
    return <Navigate to="/" replace />;
  }

  if (loadingData) {
    getAdminSettings();
    setLoadingData(false);
  }

  return (
    <div>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <NavPills
              queryKey={'t'}
              alignCenter
              fullWidth
              onChange={handleTabChange}
              color="primary"
              tabs={[
                {
                  tabName: 'settings',
                  tabButton: t('navigation.settings'),
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color={'primary'}
                          size={'lg'}
                          fullWidth={true}
                          onClick={updateAdminSettings}
                        >
                          💾{t('ui.buttons.save')}
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t('game.mule.customSettings')}</h4>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Game Name"
                          id="defaultGameName"
                          value={defaultGameName}
                          inputProps={{
                            type: 'text',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(params: any) => {
                            settingsValues['mule_game_name'] = params.target.value;
                            setSettingsValues(settingsValues);
                            setDefaultGameName(params.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Game Password"
                          id="defaultGamePassword"
                          value={defaultGamePassword}
                          inputProps={{
                            type: 'text',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(params: any) => {
                            settingsValues['mule_game_password'] = params.target.value;
                            setSettingsValues(settingsValues);
                            setDefaultGamePassword(params.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t('game.mule.paymentDetails')}</h4>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={t('game.mule.paypalEmail')}
                          id="paypalEmail"
                          value={userPaypalEmail}
                          onChange={(params: any) => {
                            setUserPaypalEmail(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        <br />
                        <CustomInput
                          labelText={t('game.mule.forumLink')}
                          id="forumLink"
                          value={userForumLink}
                          onChange={(params: any) => {
                            setUserForumLink(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'ui',
                  tabButton: '🖥️ ' + t('navigation.ui'),
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color={'primary'}
                          size={'lg'}
                          fullWidth={true}
                          onClick={updateAdminSettings}
                        >
                          💾{t('ui.buttons.save')}
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t('settings.ui.bubbleSettings')}</h4>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showHelpBubble === 1}
                              onChange={(e) => setShowHelpBubble(e.target.checked ? 1 : 0)}
                              color="primary"
                            />
                          }
                          label={t('settings.ui.showHelpBubble')}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showMuleBubble === 1}
                              onChange={(e) => setShowMuleBubble(e.target.checked ? 1 : 0)}
                              color="primary"
                            />
                          }
                          label={t('settings.ui.showMuleBubble')}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showTrackerBubble === 1}
                              onChange={(e) => setShowTrackerBubble(e.target.checked ? 1 : 0)}
                              color="primary"
                            />
                          }
                          label={t('settings.ui.showTrackerBubble')}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showScrollableIconLeft === 1}
                              onChange={(e) => setShowScrollableIconLeft(e.target.checked ? 1 : 0)}
                              color="primary"
                            />
                          }
                          label={t('settings.ui.showScrollableIconUp')}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showScrollableIconRight === 1}
                              onChange={(e) => setShowScrollableIconRight(e.target.checked ? 1 : 0)}
                              color="primary"
                            />
                          }
                          label={t('settings.ui.showScrollableIconDown')}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <BubbleContainer pageKey="settingsPage" helpText={t('help.settingsPage')} />
    </div>
  );
}
