import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks';
import React from 'react';
import { CookieBanner } from 'components/CookieBanner';
import { FloatingNav } from 'components/FloatingNav/FloatingNav';

const Layout = (props: { theme: any }) => {
  return (
    <>
      <Header rightLinks={<HeaderLinks theme={props.theme} />} color="dark" theme={props.theme} />
      <main>
        <Outlet />
      </main>
      <Footer />
      <CookieBanner />
      <FloatingNav />
    </>
  );
};

export default Layout;
