import { useState, useEffect } from 'react';

export interface CookiePreferences {
  essential: boolean;
  analytics: boolean;
  marketing: boolean;
}

const COOKIE_CONSENT_KEY = 'cookieConsent';

const defaultPreferences: CookiePreferences = {
  essential: true,
  analytics: false,
  marketing: false,
};

export function useCookieConsent() {
  const [preferences, setPreferences] = useState<CookiePreferences>(defaultPreferences);
  const [hasConsented, setHasConsented] = useState(false);

  useEffect(() => {
    const savedConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (savedConsent) {
      const parsedConsent = JSON.parse(savedConsent);
      setPreferences(parsedConsent);
      setHasConsented(true);
    }
  }, []);

  const updatePreferences = (newPreferences: Partial<CookiePreferences>, shouldSave = false) => {
    const updatedPreferences = {
      ...preferences,
      ...newPreferences,
      essential: true, // Essential cookies cannot be disabled
    };

    if (shouldSave) {
      localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(updatedPreferences));
      setHasConsented(true);
    }

    setPreferences(updatedPreferences);
  };

  const savePreferences = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(preferences));
    setHasConsented(true);
  };

  const acceptAll = () => {
    const allAccepted = {
      essential: true,
      analytics: true,
      marketing: true,
    };
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(allAccepted));
    setPreferences(allAccepted);
    setHasConsented(true);
  };

  const declineAll = () => {
    const allDeclined = {
      essential: true,
      analytics: false,
      marketing: false,
    };
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(allDeclined));
    setPreferences(allDeclined);
    setHasConsented(true);
  };

  const resetConsent = () => {
    localStorage.removeItem(COOKIE_CONSENT_KEY);
    setPreferences(defaultPreferences);
    setHasConsented(false);
  };

  return {
    preferences,
    hasConsented,
    updatePreferences,
    savePreferences,
    acceptAll,
    declineAll,
    resetConsent,
  };
}
