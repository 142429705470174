import React from 'react';
import { Box, Button, FormControlLabel, Checkbox, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface TradeListGenerateControlsProps {
  maxCountDisplayed: number;
  displayPrices: boolean;
  showAmount: boolean;
  includeZeroStock: boolean;
  hideZeroPrefix: boolean;
  generatedText: string;
  showPriceOptions?: boolean;
  showGroupOptions?: boolean;
  displayGroups?: boolean;
  counterSymbol: string;
  priceSeparator: string;
  onMaxCountChange: (value: number) => void;
  onDisplayPricesChange: (checked: boolean) => void;
  onShowAmountChange: (checked: boolean) => void;
  onIncludeZeroStockChange: (checked: boolean) => void;
  onHideZeroPrefixChange: (checked: boolean) => void;
  onDisplayGroupsChange?: (checked: boolean) => void;
  onCounterSymbolChange: (value: string) => void;
  onPriceSeparatorChange: (value: string) => void;
  onGenerateClick: () => void;
}

export function TradeListGenerateControls({
  maxCountDisplayed,
  displayPrices,
  showAmount,
  includeZeroStock,
  hideZeroPrefix,
  generatedText,
  showPriceOptions = true,
  showGroupOptions = false,
  displayGroups = true,
  counterSymbol,
  priceSeparator,
  onMaxCountChange,
  onDisplayPricesChange,
  onShowAmountChange,
  onIncludeZeroStockChange,
  onHideZeroPrefixChange,
  onDisplayGroupsChange,
  onCounterSymbolChange,
  onPriceSeparatorChange,
  onGenerateClick,
}: TradeListGenerateControlsProps) {
  const { t } = useTranslation();

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generatedText);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Box mb={2}>
      <GridContainer spacing={2}>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText={t('tradeLists.generateList.maxCount')}
            id="maxCount"
            value={maxCountDisplayed.toString()}
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value > 0) {
                onMaxCountChange(value);
              }
            }}
            inputProps={{
              type: 'number',
              min: '1',
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText={t('tradeLists.generateList.counterSymbol')}
            id="counterSymbol"
            value={counterSymbol}
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => onCounterSymbolChange(e.target.value)}
            inputProps={{
              type: 'text',
              maxLength: 2,
            }}
          />
        </GridItem>
        {showPriceOptions && (
          <>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText={t('tradeLists.generateList.priceSeparator')}
                id="priceSeparator"
                value={priceSeparator}
                formControlProps={{
                  fullWidth: true,
                }}
                onChange={(e) => onPriceSeparatorChange(e.target.value)}
                inputProps={{
                  type: 'text',
                  maxLength: 2,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayPrices}
                    onChange={(e) => onDisplayPricesChange(e.target.checked)}
                    color="primary"
                  />
                }
                label={t('tradeLists.generateList.displayPrices')}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </GridItem>
          </>
        )}
        <GridItem xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAmount}
                onChange={(e) => onShowAmountChange(e.target.checked)}
                color="primary"
              />
            }
            label={t('tradeLists.generateList.showAmount')}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeZeroStock}
                onChange={(e) => onIncludeZeroStockChange(e.target.checked)}
                color="primary"
              />
            }
            label={t('tradeLists.generateList.includeZeroStock')}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          />
        </GridItem>
        {includeZeroStock && (
          <GridItem xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideZeroPrefix}
                  onChange={(e) => onHideZeroPrefixChange(e.target.checked)}
                  color="primary"
                />
              }
              label={t('tradeLists.generateList.hideZeroPrefix')}
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            />
          </GridItem>
        )}
        {showGroupOptions && (
          <GridItem xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayGroups}
                  onChange={(e) => onDisplayGroupsChange?.(e.target.checked)}
                  color="primary"
                />
              }
              label={t('tradeLists.generateList.displayGroups')}
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            />
          </GridItem>
        )}
        <GridItem xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onGenerateClick}
            fullWidth
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
              '&:hover': {
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.primary.dark : undefined,
              },
            }}
          >
            📜 {t('tradeLists.generateList.button')}
          </Button>
        </GridItem>
        {generatedText && (
          <GridItem xs={12}>
            <Box
              mt={2}
              p={2}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
                borderRadius: 1,
                whiteSpace: 'pre-wrap',
                fontFamily: 'monospace',
                color: (theme) => theme.palette.text.primary,
                position: 'relative',
              }}
            >
              {generatedText}
              <Tooltip title={t('tradeLists.generateList.copiedToClipboard')} placement="top">
                <IconButton
                  onClick={handleCopyToClipboard}
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </GridItem>
        )}
      </GridContainer>
    </Box>
  );
}

export default TradeListGenerateControls;
