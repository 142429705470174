import { useEffect } from 'react';

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    const hostname = window.location.hostname;
    const baseTitle =
      hostname === 'localhost'
        ? `local - ${title}`
        : hostname === '77.21.112.94'
          ? `testing - ${title}`
          : title;

    document.title = `${baseTitle} - d2rs.io`;

    return () => {
      document.title = 'd2rs.io'; // Reset on unmount
    };
  }, [title]);
};

export default useDocumentTitle;
