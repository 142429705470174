import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import NavPills from 'components/NavPills/NavPills.js';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import axios from 'axios';
import config from '../../config/config.json';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Checkbox from '@mui/material/Checkbox';
import { RequireAuth } from 'components/Auth/RequireAuth';
import useDocumentTitle from '../../hooks/useDocumentTitle';

interface User {
  id: number;
  name: string;
  email: string;
  discord_id: string;
  forum_link: string;
  initiation_link: string;
  paypal_email: string;
  token: string;

  // Permissions
  is_staff: number;
  is_d2rs: number;
  is_sourcing: number;
  can_deliver: number;
  can_drop: number;
  can_drop_fg: number;
  can_drop_fiat: number;
  can_edit_prices: number;
  can_list: number;
  can_prescreen: number;

  // Game Related
  ladder: number;
  hardcore: number;
  has_hardcore_access: number;

  // Node Related
  home_node: number;
  current_node: string;

  // Financial
  current_credit: string;
  due_amount: string;
  fg_due_amount: string;
  max_due_allowed: string;

  // Status
  disabled: number;
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
}));

export const USER_PAGE_NAME = 'UserOverviewPage';

export default function UserOverviewPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.userOverview'));
  const sessionToken = localStorage.getItem('session_token');
  const queryClient = useQueryClient();

  const {
    data: usersData = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await axios.get<User[]>(`${config.backend_base_url}staff/users`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data || [];
    },
    enabled: !!sessionToken,
  });

  const updateUserMutation = useMutation({
    mutationFn: async (user: User) => {
      const response = await axios.put(`${config.backend_base_url}staff/user/update`, user, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      toast.success('User updated successfully');
    },
    onError: (error) => {
      toast.error('Failed to update user');
      console.error('Update error:', error);
    },
  });

  const handleBooleanFieldChange = (params: GridRenderCellParams, field: keyof User) => {
    const user = params.row as User;
    const updatedUser = {
      ...user,
      [field]: user[field] ? 0 : 1,
    };
    updateUserMutation.mutate(updatedUser);
  };

  const userColumns: GridColDef[] = [
    // Basic Info
    {
      field: 'id',
      headerName: t('user.fields.id'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'name',
      headerName: t('user.fields.name'),
      flex: 2,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'email',
      headerName: t('user.fields.email'),
      flex: 2,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'discord_id',
      headerName: t('user.fields.discordId'),
      flex: 2,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'forum_link',
      headerName: t('user.fields.forumLink'),
      flex: 2,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'paypal_email',
      headerName: t('user.fields.paypalEmail'),
      flex: 2,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },

    // Permissions
    {
      field: 'is_staff',
      headerName: t('user.fields.isStaff'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'is_staff')}
        />
      ),
    },
    {
      field: 'is_d2rs',
      headerName: t('user.fields.isD2RS'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'is_d2rs')}
        />
      ),
    },
    {
      field: 'is_sourcing',
      headerName: t('user.fields.isSourcing'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'is_sourcing')}
        />
      ),
    },
    {
      field: 'can_deliver',
      headerName: t('user.fields.canDeliver'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_deliver')}
        />
      ),
    },
    {
      field: 'can_drop',
      headerName: t('user.fields.canDrop'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_drop')}
        />
      ),
    },
    {
      field: 'can_drop_fg',
      headerName: t('user.fields.canDropFG'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_drop_fg')}
        />
      ),
    },
    {
      field: 'can_drop_fiat',
      headerName: t('user.fields.canDropFiat'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_drop_fiat')}
        />
      ),
    },
    {
      field: 'can_edit_prices',
      headerName: t('user.fields.canEditPrices'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_edit_prices')}
        />
      ),
    },
    {
      field: 'can_list',
      headerName: t('user.fields.canList'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_list')}
        />
      ),
    },
    {
      field: 'can_prescreen',
      headerName: t('user.fields.canPrescreen'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'can_prescreen')}
        />
      ),
    },

    // Game Related
    {
      field: 'ladder',
      headerName: t('user.fields.ladder'),
      flex: 1,
      type: 'boolean',
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      field: 'hardcore',
      headerName: t('user.fields.hardcore'),
      flex: 1,
      type: 'boolean',
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      field: 'has_hardcore_access',
      headerName: t('user.fields.hasHardcoreAccess'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'has_hardcore_access')}
        />
      ),
    },

    // Node Related
    {
      field: 'home_node',
      headerName: t('user.fields.homeNode'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'current_node',
      headerName: t('user.fields.currentNode'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
    },

    // Financial
    {
      field: 'current_credit',
      headerName: t('user.fields.currentCredit'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {parseFloat(params.value || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: 'due_amount',
      headerName: t('user.fields.dueAmount'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {parseFloat(params.value || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: 'fg_due_amount',
      headerName: t('user.fields.fgDueAmount'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {parseFloat(params.value || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: 'max_due_allowed',
      headerName: t('user.fields.maxDueAllowed'),
      flex: 1,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value
            ? parseFloat(params.value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'}
        </div>
      ),
    },

    // Status
    {
      field: 'disabled',
      headerName: t('user.fields.disabled'),
      flex: 1,
      type: 'boolean',
      editable: true,
      renderHeader: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={() => handleBooleanFieldChange(params, 'disabled')}
        />
      ),
    },
  ];

  const userTableContent = (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.dataGridContainer}>
          <DataGrid
            autoHeight
            loading={isLoading}
            columns={userColumns}
            rows={usersData}
            getRowHeight={() => 'auto'}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            sx={
              {
                '& .MuiDataGrid-root': {
                  width: '100%',
                },
                '& .MuiDataGrid-main': {
                  overflow: 'hidden',
                },
                '& .MuiDataGrid-columnHeaders': {
                  minHeight: '60px !important',
                  maxHeight: 'none !important',
                  lineHeight: '1.2 !important',
                  whiteSpace: 'normal',
                },
                '& .MuiDataGrid-columnHeader': {
                  height: 'auto !important',
                  whiteSpace: 'normal',
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    overflow: 'hidden',
                  },
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal !important',
                  padding: '8px',
                  minHeight: 'auto !important',
                  height: 'auto !important',
                  overflow: 'hidden !important',
                  '&:focus': {
                    outline: 'none',
                  },
                  '&:focus-within': {
                    outline: 'none',
                  },
                },
                '& .MuiDataGrid-row': {
                  maxHeight: 'none !important',
                  minHeight: 'auto !important',
                },
                '& .MuiDataGrid-renderCell': {
                  overflow: 'hidden !important',
                  whiteSpace: 'normal !important',
                  wordWrap: 'break-word !important',
                  display: 'block !important',
                },
              } as const
            }
          />
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          color="primary"
          onClick={() => {
            refetch();
          }}
        >
          {t('common.refresh')}
        </Button>
      </GridItem>
    </GridContainer>
  );

  return (
    <RequireAuth requiredPermission="is_staff">
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <div className={classes.contentContainer}>
              <NavPills
                queryKey={'t'}
                alignCenter
                fullWidth
                onChange={function () {}}
                color="primary"
                tabs={[
                  {
                    tabName: 'users',
                    tabButton: `User Overview (${usersData?.length || 0})`,
                    tabContent: userTableContent,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </RequireAuth>
  );
}
