import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  Typography,
  Paper,
  Stack,
  TextField,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config/config.json';
import { toast } from 'react-hot-toast';
import yellowIcon from '../../assets/img/yellow_icon.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import hardcoreOn from '../../assets/img/hardcore_on.png';
import hardcoreOff from '../../assets/img/hardcore_off.png';
import ladderOn from '../../assets/img/ladder_on.png';
import ladderOff from '../../assets/img/ladder_off.png';
import { useHeaderLinks } from '../../components/Header/HeaderLinksContext';
import {
  DetailPageData,
  ItemDetailResponse,
  ProductDetailResponse,
  mapItemToDetailPageData,
  mapProductToDetailPageData,
} from '../../models/DetailPage';
import {
  useCreateTracker,
  useDeleteTracker,
  useTrackedItems,
  isItemTracked,
} from '../../stores/trackedItems';
import {
  useCreateProductTracker,
  useDeleteProductTrackerByProduct,
  useTrackedProducts,
  isProductTracked,
} from '../../stores/trackedProducts';

interface ItemDetailProps {
  itemId: string;
  type: 'item' | 'pickit';
  discordId?: string;
  onClose?: () => void;
  isDialog?: boolean;
  currentUserId?: string;
}

export function ItemDetail({
  itemId,
  type,
  onClose,
  isDialog = false,
  currentUserId,
}: ItemDetailProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [offerAmount, setOfferAmount] = React.useState<string>('');
  const [offerError, setOfferError] = React.useState<string>('');
  const [sharedLink, setSharedLink] = useState<string>('');
  const [localDiscordId, setLocalDiscordId] = useState(localStorage.getItem('discord_id') || '');
  const { isD2RS } = useHeaderLinks();

  const { data: trackedItems = [] } = useTrackedItems();
  const { data: trackedProducts = [] } = useTrackedProducts();
  const createItemTracker = useCreateTracker();
  const deleteItemTracker = useDeleteTracker();
  const createProductTracker = useCreateProductTracker();
  const deleteProductTracker = useDeleteProductTrackerByProduct();

  const isTracked =
    type === 'pickit'
      ? isProductTracked(trackedProducts, Number(itemId))
      : isItemTracked(trackedItems, Number(itemId));

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const { data: detail, error } = useQuery<DetailPageData>({
    queryKey: ['item-detail', itemId, type],
    queryFn: async () => {
      const loadingToast = toast.loading(t('common.loading.fetchingData'));
      try {
        const sessionToken = localStorage.getItem('session_token');
        const headers: Record<string, string> = {
          'Content-Type': 'application/json',
        };

        if (sessionToken) {
          headers['session-token'] = sessionToken;
        }

        const endpoint = type === 'item' ? 'api/item/detail' : 'api/product/detail';
        if (type === 'item') {
          const response = await axios.get<ItemDetailResponse>(
            `${config.backend_base_url}${endpoint}/${itemId}`,
            { headers }
          );
          const mappedData = mapItemToDetailPageData(response.data);
          toast.dismiss(loadingToast);
          return mappedData;
        } else {
          const response = await axios.get<ProductDetailResponse>(
            `${config.backend_base_url}${endpoint}/${itemId}`,
            { headers }
          );
          const mappedData = mapProductToDetailPageData(response.data);
          toast.dismiss(loadingToast);
          return mappedData;
        }
      } catch (error) {
        toast.dismiss(loadingToast);
        throw error;
      }
    },
    enabled: !!itemId,
  });

  const { data: ownOffers } = useQuery({
    queryKey: ['own-offers', itemId],
    queryFn: async () => {
      const createdOffers = JSON.parse(localStorage.getItem('created_offers') || '[]');

      if (!createdOffers.length) {
        return [];
      }

      try {
        const response = await axios.post(`${config.backend_base_url}pss/offers`, {
          offer_ids: createdOffers,
        });

        const offers = Array.isArray(response.data) ? response.data : response.data.offers || [];
        return offers.filter((offer) => {
          const offerItemId = offer.item_id || offer.itemId;
          return offerItemId === parseInt(itemId, 10);
        });
      } catch (error) {
        return [];
      }
    },
    enabled: !!itemId,
  });

  // Set document title when detail data is available
  useDocumentTitle(detail?.title || t('common.loading.fetchingData'));

  const offerActionMutation = useMutation({
    mutationFn: async ({ offerId, action }: { offerId: string; action: 'accept' | 'deny' }) => {
      const sessionToken = localStorage.getItem('session_token');
      const endpoint = action === 'accept' ? 'pss/o/accept' : 'pss/o/decline';
      await axios.post(
        `${config.backend_base_url}${endpoint}`,
        { offer_id: parseInt(offerId, 10) },
        { headers: { 'session-token': sessionToken } }
      );
    },
    onSuccess: (_, { action }) => {
      toast.success(t(`search.item.offer${action === 'accept' ? 'accepted' : 'declined'}`));
      queryClient.invalidateQueries({ queryKey: ['item-detail', itemId, type] });
    },
    onError: (error, { action }) => {
      console.error(`Error ${action}ing offer:`, error);
      toast.error(t('search.errors.offerActionFailed'));
    },
  });

  const handleOfferAction = (offerId: string, action: 'accept' | 'deny') => {
    offerActionMutation.mutate({ offerId, action });
  };

  const updateListingPrice = async (price: string) => {
    if (!price || isNaN(Number(price))) {
      toast.error(t('search.errors.invalidPrice'));
      return;
    }

    const loadingToast = toast.loading(t('search.item.updatingListing'));
    try {
      const sessionToken = localStorage.getItem('session_token');
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
      };

      if (sessionToken) {
        headers['session-token'] = sessionToken;
      }

      await axios.post(
        `${config.backend_base_url}d2rs/listing/update`,
        {
          item_id: parseInt(itemId, 10),
          new_price: price,
        },
        { headers }
      );
      toast.dismiss(loadingToast);
      toast.success(t('search.item.listingUpdated'));
      queryClient.invalidateQueries({ queryKey: ['item-detail', itemId, type] });
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(t('search.errors.listingUpdateFailed'));
    }
  };

  const deleteListing = async () => {
    const loadingToast = toast.loading(t('search.item.deletingListing'));
    try {
      const sessionToken = localStorage.getItem('session_token');
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
      };

      if (sessionToken) {
        headers['session-token'] = sessionToken;
      }

      await axios.post(
        `${config.backend_base_url}d2rs/listing/delete`,
        {
          item_id: parseInt(itemId, 10),
        },
        { headers }
      );
      toast.dismiss(loadingToast);
      toast.success(t('search.item.listingDeleted'));
      queryClient.invalidateQueries({ queryKey: ['item-detail', itemId, type] });
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(t('search.errors.listingDeleteFailed'));
    }
  };

  const handleOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow digits
    if (value === '' || /^\d+$/.test(value)) {
      setOfferAmount(value);
      setOfferError('');
    }
  };

  const setDiscordIDFunction = (newVal: string) => {
    setLocalDiscordId(newVal);
    localStorage.setItem('discord_id', newVal);
  };

  const makeOffer = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!localDiscordId) {
      toast.error(t('search.errors.discordRequired'));
      return;
    }
    if (!offerAmount) {
      setOfferError(t('search.errors.offerRequired'));
      return;
    }
    const numericOffer = parseInt(offerAmount, 10);
    if (isNaN(numericOffer) || numericOffer <= 0) {
      setOfferError(t('search.errors.invalidOffer'));
      return;
    }

    const sessionToken = localStorage.getItem('session_token');
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (sessionToken) {
      headers['session-token'] = sessionToken;
    }

    axios
      .post(
        `${config.backend_base_url}pss/offer`,
        {
          item_id: parseInt(itemId, 10),
          discord_id: localDiscordId,
          offer: offerAmount,
        },
        { headers }
      )
      .then((response) => {
        // Store the offer ID in localStorage
        const createdOffers = JSON.parse(localStorage.getItem('created_offers') || '[]');
        // Handle the case where response.data is just the ID number
        const offerId =
          typeof response.data === 'number'
            ? response.data
            : response.data?.id || response.data?.offer_id;

        if (offerId) {
          createdOffers.push(offerId);
          // Filter out any null values and store only unique IDs
          const uniqueOffers = Array.from(new Set(createdOffers.filter(Boolean)));
          localStorage.setItem('created_offers', JSON.stringify(uniqueOffers));
        }

        toast.success(t('search.item.offerCreated'));
        setOfferError(''); // Clear any errors
        queryClient.invalidateQueries({ queryKey: ['item-detail', itemId, type] });
        queryClient.invalidateQueries({ queryKey: ['own-offers', itemId] });
        setOfferAmount(''); // Clear the input field
      })
      .catch(() => {
        toast.error(t('search.errors.createOfferFailed'));
      });
  };

  const handleShare = () => {
    const baseUrl = window.location.origin;
    const path = type === 'item' ? '/i' : '/p';
    const url = `${baseUrl}${path}/${itemId}`;
    setSharedLink(url);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success(t('common.copiedToClipboard'));
      })
      .catch(() => {
        toast.error(t('common.clipboardError'));
      });
  };

  const handleTrackToggle = () => {
    const sessionToken = localStorage.getItem('session_token');
    if (!sessionToken) {
      toast.error(t('auth.mustLoginToTrack'));
      return;
    }

    if (type === 'pickit') {
      if (isTracked) {
        deleteProductTracker.mutate({ product_id: Number(itemId) });
      } else {
        createProductTracker.mutate({ product_id: Number(itemId) });
      }
    } else {
      if (isTracked) {
        // Find the tracker ID for this item
        const tracker = trackedItems.find((item) => Number(item.item_id) === Number(itemId));
        if (tracker) {
          deleteItemTracker.mutate({ id: tracker.id });
        } else {
          toast.error(t('common.errors.trackerNotFound'));
        }
      } else {
        createItemTracker.mutate({ item_id: Number(itemId) });
      }
    }
  };

  if (error) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '200px' }}
      >
        <Grid item>{t('common.errors.noDataFound')}</Grid>
      </Grid>
    );
  }

  if (!detail) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            {detail.node_status && (
              <Tooltip
                title={
                  <div>
                    <div>🟢 = deliverable now</div>
                    <div>🔴 = deliverable later</div>
                  </div>
                }
              >
                <span style={{ fontSize: '16px' }}>
                  {detail.node_status === 'online' ? '🟢' : '🔴'}
                </span>
              </Tooltip>
            )}
            <Tooltip
              title={
                <img
                  src={detail.imagePath}
                  alt={detail.title}
                  style={{ maxWidth: '300px', maxHeight: '300px' }}
                />
              }
              arrow
            >
              <span style={{ cursor: 'help' }}>{detail.title}</span>
            </Tooltip>
            <Tooltip title={t('common.share')}>
              <IconButton
                onClick={handleShare}
                size="small"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                <ShareIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <Tooltip title={isTracked ? t('common.untrack') : t('common.track')}>
              <IconButton
                onClick={handleTrackToggle}
                size="small"
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: isTracked ? '#FFD700' : (theme) => theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: isTracked ? '#FFD700' : (theme) => theme.palette.primary.dark,
                  },
                }}
              >
                {isTracked ? (
                  <BookmarkIcon sx={{ color: '#000000' }} />
                ) : (
                  <BookmarkBorderIcon sx={{ color: '#FFFFFF' }} />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {detail.context !== '' && (
          <Typography
            variant="h6"
            color="textSecondary"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <span
              style={{
                color: detail.context.toLowerCase().includes('hc') ? 'red' : 'inherit',
              }}
            >
              {detail.context.toLowerCase().includes('scl') && 'Softcore Ladder'}
              {detail.context.toLowerCase().includes('sc') &&
                !detail.context.toLowerCase().includes('l') &&
                'Softcore Non-Ladder'}
              {detail.context.toLowerCase().includes('hcl') && 'Hardcore Ladder'}
              {detail.context.toLowerCase().includes('hc') &&
                !detail.context.toLowerCase().includes('l') &&
                'Hardcore Non-Ladder'}
            </span>
            {detail.context.toLowerCase().includes('scl') && (
              <span style={{ display: 'flex' }}>
                <img style={{ width: '32px' }} src={hardcoreOff} alt="hardcoreOff" />
                <img style={{ width: '32px' }} src={ladderOn} alt="ladderOn" />
              </span>
            )}
            {detail.context.toLowerCase().includes('sc') &&
              !detail.context.toLowerCase().includes('l') && (
                <span style={{ display: 'flex' }}>
                  <img style={{ width: '32px' }} src={hardcoreOff} alt="hardcoreOff" />
                  <img style={{ width: '32px' }} src={ladderOff} alt="ladderOff" />
                </span>
              )}
            {detail.context.toLowerCase().includes('hcl') && (
              <span style={{ display: 'flex' }}>
                <img style={{ width: '32px' }} src={hardcoreOn} alt="hardcoreOn" />
                <img style={{ width: '32px' }} src={ladderOn} alt="ladderOn" />
              </span>
            )}
            {detail.context.toLowerCase().includes('hc') &&
              !detail.context.toLowerCase().includes('l') && (
                <span style={{ display: 'flex' }}>
                  <img style={{ width: '32px' }} src={hardcoreOn} alt="hardcoreOn" />
                  <img style={{ width: '32px' }} src={ladderOff} alt="ladderOff" />
                </span>
              )}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <img
          src={detail.imagePath}
          alt={detail.title}
          style={{
            width: '100%',
            maxHeight: '400px',
            objectFit: 'contain',
            border: '1px solid black',
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        {sharedLink && (
          <Box
            mb={2}
            p={2}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
              borderRadius: 1,
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              color: (theme) => theme.palette.text.primary,
              position: 'relative',
            }}
          >
            {sharedLink}
            <Tooltip title={t('common.copiedToClipboard')} placement="top">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(sharedLink);
                  toast.success(t('common.copiedToClipboard'));
                }}
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <div className="action-buttons">
          {detail.shopLink && (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              href={detail.shopLink}
              target="_blank"
              style={{ marginBottom: '1rem' }}
            >
              <img
                src={yellowIcon}
                alt="shop"
                style={{ width: '20px', height: '20px', marginRight: '8px' }}
              />
              {t('search.vendor.priceWithAmount', {
                amount: detail.price,
              })}
            </Button>
          )}

          {detail.canMakeOffer && (
            <>
              <TextField
                fullWidth
                label={t('search.offers.discordId')}
                value={localDiscordId}
                onChange={(e) => setDiscordIDFunction(e.target.value)}
                placeholder={t('search.offers.enterDiscordId')}
                variant="outlined"
                size="small"
                style={{ marginBottom: '1rem' }}
              />
              <TextField
                fullWidth
                label={t('search.offers.amount')}
                value={offerAmount}
                onChange={handleOfferChange}
                placeholder={t('search.offers.enterAmount')}
                variant="outlined"
                size="small"
                type="number"
                inputProps={{ min: '1', step: '1' }}
                error={!!offerError}
                helperText={offerError}
                style={{ marginBottom: '1rem' }}
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={makeOffer}
                style={{ marginBottom: '1rem' }}
              >
                {t('search.offers.makeOfferAmount')}
              </Button>
            </>
          )}

          {isDialog && onClose && (
            <Button fullWidth color="secondary" variant="outlined" onClick={onClose}>
              {t('common.close')}
            </Button>
          )}
        </div>

        {detail.description && (
          <Box
            mt={2}
            p={2}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
              borderRadius: 1,
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              color: (theme) => theme.palette.text.primary,
              position: 'relative',
            }}
          >
            {detail.description}
            <Tooltip title={t('common.copiedToClipboard')} placement="top">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(detail.description || '');
                  toast.success(t('common.copiedToClipboard'));
                }}
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Grid>

      {/* User's Own Offers Section - Always visible */}
      {type === 'item' && ownOffers && ownOffers.length > 0 && (
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('search.item.yourOffers')}
            </Typography>
            <Stack spacing={2}>
              {ownOffers.map((offer) => (
                <Paper
                  key={offer.id}
                  elevation={1}
                  sx={{
                    padding: '1rem',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                    borderLeft: (theme) => `4px solid ${theme.palette.primary.main}`,
                    opacity: offer.status === 'denied' ? 0.7 : 1,
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <Box>
                          <Typography
                            variant="subtitle2"
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {t('search.item.offerAmount')}: ${offer.amount || offer.offer || '-'}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                            {t('search.item.offerDate')}: {formatDate(offer.created_at)}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {t('search.item.offerStatus')}: {offer.status}
                          </Typography>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Stack>
          </Paper>
        </Grid>
      )}

      {/* Status Section - Only visible for D2RS users */}
      {type === 'item' && isD2RS && (
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" color="warning.main" gutterBottom>
              {t('search.item.itemDetails')}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" component="span">
                {t('search.item.itemStatus')}:
              </Typography>
              <Typography variant="subtitle2" component="span" sx={{ textTransform: 'capitalize' }}>
                {detail.status ? detail.status : '-'}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" component="span">
                {t('search.item.itemNodeName')}:
              </Typography>
              <Typography variant="subtitle2" component="span" sx={{ textTransform: 'capitalize' }}>
                {detail.node_name || '-'}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      )}

      {/* Shop Actions Section - Only visible for D2RS users */}
      {type === 'item' && isD2RS && (
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" color="warning.main" gutterBottom>
              {t('search.vendor.shopOptions')}
            </Typography>

            <Stack spacing={2} mt={2}>
              {/* Listing Actions */}
              {!detail.shopLink && (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    const price = window.prompt(t('search.item.enterListingPrice'));
                    if (price) updateListingPrice(price);
                  }}
                >
                  {t('search.item.listItem')}
                </Button>
              )}
              {detail.shopLink && (
                <>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      const price = window.prompt(t('search.item.enterUpdatePrice'));
                      if (price) updateListingPrice(price);
                    }}
                  >
                    {t('search.item.updatePrice')}
                  </Button>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      if (window.confirm(t('search.item.confirmDelete'))) {
                        deleteListing();
                      }
                    }}
                  >
                    {t('search.item.deleteListing')}
                  </Button>
                </>
              )}
            </Stack>
          </Paper>
        </Grid>
      )}

      {/* Offers Section - Only visible for D2RS users */}
      {type === 'item' && detail.offers && detail.offers.length > 0 && isD2RS && (
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('search.item.currentOffers')}
            </Typography>
            <Stack spacing={2}>
              {detail.offers
                .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                .map((offer) => (
                  <Paper
                    key={offer.id}
                    elevation={1}
                    sx={{
                      padding: '1rem',
                      backgroundColor: (theme) =>
                        offer.discordid === currentUserId
                          ? theme.palette.mode === 'dark'
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light
                          : theme.palette.background.paper,
                      borderLeft: (theme) =>
                        offer.discordid === currentUserId
                          ? `4px solid ${theme.palette.primary.main}`
                          : 'none',
                      opacity: offer.status === 'denied' ? 0.7 : 1,
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={offer.status === 'pending' ? 6 : 12}>
                        <Typography variant="body1">
                          <Box>
                            <Typography
                              variant="subtitle2"
                              component="span"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('search.item.offerAmount')}: ${offer.amount || offer.offer || '-'}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                              {t('search.item.offerDate')}: {formatDate(offer.created_at)}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {t('search.item.offerStatus')}: {offer.status}
                            </Typography>
                            {offer.discordid && (
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {t('search.item.offerBy')}: {offer.discordid}
                              </Typography>
                            )}
                          </Box>
                          {offer.discordid === currentUserId && (
                            <Tooltip title={t('search.item.yourOffer')}>
                              <span style={{ marginLeft: '8px' }}>🎯</span>
                            </Tooltip>
                          )}
                        </Typography>
                      </Grid>
                      {offer.status === 'pending' && (
                        <Grid item xs={12} sm={6}>
                          <Stack direction="row" spacing={1} justifyContent="flex-end">
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              onClick={() => handleOfferAction(offer.id, 'accept')}
                            >
                              {t('common.accept')}
                            </Button>
                            <Button
                              size="small"
                              color="error"
                              variant="outlined"
                              onClick={() => handleOfferAction(offer.id, 'deny')}
                            >
                              {t('common.deny')}
                            </Button>
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                ))}
            </Stack>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
