import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// @mui/material components
import { makeStyles } from '@mui/styles';

// core components

import buttonStyle from 'assets/jss/material-kit-react/components/buttonStyle.js';

const PREFIX = 'CustomButton';

const classes = {
  button: `${PREFIX}-button`,
  round: `${PREFIX}-round`,
  fullWidth: `${PREFIX}-fullWidth`,
  disabled: `${PREFIX}-disabled`,
  simple: `${PREFIX}-simple`,
  block: `${PREFIX}-block`,
  link: `${PREFIX}-link`,
  justIcon: `${PREFIX}-justIcon`,
  primary: `${PREFIX}-primary`,
  info: `${PREFIX}-info`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  white: `${PREFIX}-white`,
  transparent: `${PREFIX}-transparent`,
  sm: `${PREFIX}-sm`,
  lg: `${PREFIX}-lg`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: '#385170',
    color: '#ffffff',
    boxShadow: theme.shadows[2],
    border: 'none',
    borderRadius: '3px',
    position: 'relative',
    padding: '12px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: 0,
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
      boxShadow: theme.shadows[3],
    },
  },
  [`&.${classes.round}`]: {
    borderRadius: '30px',
  },
  [`&.${classes.fullWidth}`]: {
    width: '100%',
  },
  [`&.${classes.disabled}`]: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  [`&.${classes.simple}`]: {
    '&,&:focus,&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },
  },
  [`&.${classes.block}`]: {
    width: '100%',
  },
  [`&.${classes.link}`]: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },
  },
  [`&.${classes.justIcon}`]: {
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
    height: '41px',
    minWidth: '41px',
    width: '41px',
    '& .material-icons': {
      marginRight: '0px',
    },
    '&$lg': {
      height: '57px',
      minWidth: '57px',
      width: '57px',
      lineHeight: '56px',
      '& .material-icons': {
        fontSize: '32px',
        lineHeight: '56px',
      },
    },
    '&$sm': {
      height: '30px',
      minWidth: '30px',
      width: '30px',
      '& .material-icons': {
        fontSize: '17px',
        lineHeight: '29px',
      },
    },
  },
  [`&.${classes.primary}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [`&.${classes.info}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  [`&.${classes.success}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  [`&.${classes.warning}`]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  [`&.${classes.error}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  [`&.${classes.white}`]: {
    '&,&:focus,&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
    },
  },
  [`&.${classes.transparent}`]: {
    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  [`&.${classes.sm}`]: {
    padding: '8px 20px',
    fontSize: '11px',
  },
  [`&.${classes.lg}`]: {
    padding: '16px 40px',
    fontSize: '14px',
  },
}));

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    download,
    ...rest
  } = props;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });

  return (
    <StyledButton {...rest} ref={ref} className={btnClasses} download={download}>
      {children}
    </StyledButton>
  );
});

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'error',
    'white',
    'transparent',
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  download: PropTypes.string,
};

RegularButton.displayName = 'RegularButton';
export default RegularButton;
