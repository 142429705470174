import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';
// @mui/material components
import { makeStyles } from '@mui/styles';
// @material-ui/icons
// core components
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
// sections for this page
import SectionBasics from './Sections/SectionBasics.js';
import SectionNavbars from './Sections/SectionNavbars.js';
import SectionTabs from './Sections/SectionTabs.js';
import SectionPills from './Sections/SectionPills.js';
import SectionNotifications from './Sections/SectionNotifications.js';
import SectionTypography from './Sections/SectionTypography.js';
import SectionJavascript from './Sections/SectionJavascript.js';
import SectionCarousel from './Sections/SectionCarousel.js';
import SectionCompletedExamples from './Sections/SectionCompletedExamples.js';
import SectionLogin from './Sections/SectionLogin.js';
import SectionExamples from './Sections/SectionExamples.js';
import SectionDownload from './Sections/SectionDownload.js';

import styles from 'assets/jss/material-kit-react/views/components.js';

const useStyles = makeStyles(styles);

export default function Components() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <SectionBasics />
      <SectionNavbars />
      <SectionTabs />
      <SectionPills />
      <SectionTypography />
      <SectionJavascript />
      <SectionCarousel />
      <SectionCompletedExamples />
      <SectionLogin />
      <GridItem md={12} className={classes.textCenter}>
        <Link to={'/login-page'} className={classes.link}>
          <Button color="primary" size="lg" simple>
            View Login Page
          </Button>
        </Link>
      </GridItem>
      <SectionExamples />
      <SectionDownload />
    </div>
  );
}
