import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config/config.json';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface RequireAuthProps {
  children: React.ReactNode;
  requiredPermission?: 'is_staff' | 'is_sourcing' | 'is_d2rs' | 'can_prescreen';
}

export function RequireAuth({ children, requiredPermission }: RequireAuthProps) {
  const { t } = useTranslation();
  const sessionToken = localStorage.getItem('session_token');

  const { data: profile, isLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await axios.get(`${config.backend_base_url}profile`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    enabled: !!sessionToken,
  });

  useEffect(() => {
    let loadingToast: string | undefined;
    if (isLoading) {
      loadingToast = toast.loading(t('common.loading.checkingPermissions'));
    }
    return () => {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    };
  }, [isLoading, t]);

  if (!sessionToken) {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return null;
  }

  if (!profile) {
    return <Navigate to="/" replace />;
  }

  // Check required permission
  if (requiredPermission) {
    const hasPermission = profile.user[`${requiredPermission}`] === 1;
    if (!hasPermission) {
      return <Navigate to="/" replace />;
    }
  }

  return <>{children}</>;
}
