import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScrollableIcon } from '../ScrollableIcon/ScrollableIcon';

const useStyles = makeStyles((theme: any) => ({
  floatingNav: {
    position: 'fixed',
    left: '20px',
    bottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    zIndex: 99999,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  navButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '8px',
    borderRadius: '50%',
    border: 'none',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    position: 'relative',
    zIndex: 99999,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export const FloatingNav = () => {
  const classes = useStyles();
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [showDownArrow, setShowDownArrow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const checkVisibility = () => {
      const header = document.querySelector('header');
      const footer = document.querySelector('footer');
      const windowHeight = window.innerHeight;

      if (header) {
        const headerRect = header.getBoundingClientRect();
        setShowUpArrow(headerRect.bottom < 0);
      }

      if (footer) {
        const footerRect = footer.getBoundingClientRect();
        // Consider footer visible if any part of it is in view (with a 20px buffer)
        const isFooterVisible = footerRect.top < windowHeight + 20 && footerRect.bottom > -20;
        setShowDownArrow(!isFooterVisible);
      }
    };

    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    const throttledCheck = () => {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          checkVisibility();
          timeoutId = undefined;
        }, 100);
      }
    };

    window.addEventListener('scroll', throttledCheck);
    window.addEventListener('resize', throttledCheck);
    checkVisibility();

    return () => {
      window.removeEventListener('scroll', throttledCheck);
      window.removeEventListener('resize', throttledCheck);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <div className={classes.floatingNav}>
      {showUpArrow && (
        <Tooltip title={t('navigation.toTop')} placement="right">
          <ScrollableIcon
            id="top"
            position="left"
            className={classes.navButton}
            style={{ cursor: 'pointer' }}
          >
            <KeyboardArrowUpIcon />
          </ScrollableIcon>
        </Tooltip>
      )}
      {showDownArrow && (
        <Tooltip title={t('navigation.toBottom')} placement="right">
          <ScrollableIcon
            id="bottom"
            position="right"
            className={classes.navButton}
            style={{ cursor: 'pointer' }}
          >
            <KeyboardArrowDownIcon />
          </ScrollableIcon>
        </Tooltip>
      )}
    </div>
  );
};
