/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @mui/material components
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { Autocomplete, TextField } from '@mui/material';

// @mui/icons-material
import { Apps, Person } from '@mui/icons-material';

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import config from '../../config/config.json';
import axios from 'axios';
import toast from 'react-hot-toast';
import Search from '@mui/icons-material/Search';
import ladderOn from 'assets/img/ladder_on.png';
import ladderOff from 'assets/img/ladder_off.png';
import hardcoreOn from 'assets/img/hardcore_on.png';
import hardcoreOff from 'assets/img/hardcore_off.png';
import chestImg from 'assets/img/d2rchest.png';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useHeaderLinks } from 'components/Header/HeaderLinksContext';

const useStyles = makeStyles(styles, { name: 'CustomHeaderLinks' });

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const logoutFunc = () => {
    localStorage.clear();
    // history.push("/login")
    window.location.reload(true);
  };

  let sessionToken = localStorage.getItem('session_token');

  let loggedIn = false;
  if (sessionToken) {
    loggedIn = true;
  }

  const {
    disablePlatform,
    currentContext,
    setCurrentContext,
    availableNodes,
    currentNode,
    isLoggedIn,
    isNodeOwner,
    isStaff,
    isD2RS,
    userData,
  } = useHeaderLinks();
  const [inputValue, setInputValue] = useState(currentNode || ''); // Track input text
  const textMeasureRef = useRef(null); // Reference to measure text width
  const [inputWidth, setInputWidth] = useState('auto'); // Dynamically update input width

  useEffect(() => {
    adjustInputWidth();
  }, [inputValue]);

  const adjustInputWidth = () => {
    if (textMeasureRef.current) {
      setInputWidth(`${calculateInputWidth(textMeasureRef.current.offsetWidth) + 2}px`);
    }
  };

  const calculateInputWidth = (width) => {
    if (width <= 150) return width;

    const decrement = Math.floor((width - 150) / 50) * 8;
    return width - decrement;
  };

  let changedText = (value) => {
    setInputValue(value);
  };

  let currentContextFunc = function (event, context) {
    event.preventDefault();
    setCurrentContext(context);
    if (context === 'Softcore Ladder') {
      sendContextChange(0, 1);
    }
    if (context === 'Softcore Non-Ladder') {
      sendContextChange(0, 0);
    }
    if (context === 'Hardcore Ladder') {
      sendContextChange(1, 1);
    }
    if (context === 'Hardcore Non-Ladder') {
      sendContextChange(1, 0);
    }
  };
  let sendContextChange = function (hardcoreValue, ladderValue) {
    axios
      .post(
        config.backend_base_url + 'user/update/contexts',
        {
          hardcore: hardcoreValue,
          ladder: ladderValue,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        localStorage.setItem('hardcore', hardcoreValue);
        localStorage.setItem('ladder', ladderValue);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        window.location.reload(true);
      });
  };
  let currentNodeFunc = function (event, newnodename) {
    event.preventDefault();
    sendNodeChange(newnodename);
  };
  let sendNodeChange = function (newnodename) {
    const nodeName = newnodename.split('(')[0].trim();
    axios
      .post(
        config.backend_base_url + 'user/update/node',
        {
          current_node: nodeName,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        localStorage.setItem('nodename', nodeName);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        window.location.reload(true);
      });
  };

  const replaceCanMuleWithImage = (text, asJSX = true) => {
    if (!text) return '';

    if (!asJSX) {
      // For plain text cases, just remove the CAN_MULE marker
      return text.replace('CAN_MULE', '');
    }

    // For JSX rendering, split and add image without React.Fragment
    const parts = text.split('CAN_MULE');
    if (parts.length === 1) return text;

    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        {parts[0]}
        <img
          style={{
            width: '16px',
            verticalAlign: 'middle',
            paddingTop: '4px',
          }}
          src={chestImg}
          alt="chest"
        />
        {parts[1]}
      </span>
    );
  };

  return (
    <List className={classes.list}>
      {!isLoggedIn && (
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            {t('navigation.publicPage')}
          </Link>
        </ListItem>
      )}
      {!isLoggedIn && (
        <ListItem className={classes.listItem}>
          <Link to="/login" className={classes.dropdownLink}>
            {t('navigation.login')}
          </Link>
        </ListItem>
      )}
      {disablePlatform !== true ? (
        isLoggedIn === true ? (
          <>
            <ListItem className={classes.listItem}>
              <div style={{ display: 'inline-block', position: 'relative' }}>
                {/* Hidden span to measure text width */}
                <span
                  ref={textMeasureRef}
                  style={{
                    position: 'absolute',
                    visibility: 'hidden',
                    whiteSpace: 'pre',
                    font: 'inherit',
                  }}
                >
                  {inputValue || ' '}
                </span>
                <Autocomplete
                  id="node-select"
                  options={availableNodes}
                  value={currentNode}
                  disableClearable
                  onInputChange={(_, value) => changedText(value)}
                  onChange={(event, newValue) => currentNodeFunc(event, newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('game.selectNode')}
                      className={classes.navLink}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: 'inherit',
                          backgroundColor: 'transparent',
                          fontSize: 'inherit',
                          height: 'auto',
                          minHeight: '22px',
                          minWidth: '90px',
                          paddingRight: '15px !important',
                          width: inputWidth,
                          padding: 0,
                          '& fieldset': { border: 'none' },
                          '& .MuiAutocomplete-endAdornment': {
                            color: 'inherit',
                          },
                          '& .MuiAutocomplete-input': {
                            width: 'auto !important',
                            minWidth: '70px',
                            padding: '0 !important',
                            height: '24px',
                            lineHeight: '24px',
                            textOverflow: 'unset',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          display: 'none',
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        fontSize: '13px',
                        minWidth: '150px',
                        padding: '10px 20px',
                      }}
                    >
                      {replaceCanMuleWithImage(option)}
                    </li>
                  )}
                  getOptionLabel={(option) =>
                    `${t('game.node').toUpperCase()}: ${replaceCanMuleWithImage(option, false).toUpperCase().replaceAll(' ', '')}`
                  }
                  sx={{
                    '& .MuiAutocomplete-tag': {
                      color: 'inherit',
                    },
                    '& .MuiInputBase-input': {
                      overflow: 'visible',
                      textOverflow: 'unset',
                      whiteSpace: 'nowrap',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      transition: 'all 50ms ease-in',
                      marginRight: '-18px',
                      width: '24px',
                      height: '24px',
                      color: '#ffffff !important',

                      '&:hover': {
                        backgroundColor: 'transparent !important',
                      },
                      '&:active': {
                        backgroundColor: 'transparent !important',
                      },
                    },
                    '& .MuiAutocomplete-popupIndicatorOpen': {
                      transition: 'all 50ms ease-in',
                      left: '0px',
                      transform: 'unset',
                      '&.Mui-focused': {
                        backgroundColor: 'red !important', // Remove focus background color
                        boxShadow: 'none !important', // Remove any focus shadow
                        outline: 'none !important',
                      },
                    },
                    '& .MuiAutocomplete-popupIndicatorOpen .MuiSvgIcon-root': {
                      width: 0,
                      height: 0,
                      display: 'inline-block',
                      borderTop: '4px solid',
                      transition: 'all 150ms ease-in',
                      borderLeft: '4px solid transparent',
                      marginLeft: '-8px',
                      borderRight: '4px solid transparent',
                      verticalAlign: 'middle',
                      transform: 'rotate(180deg)',
                    },
                    '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
                      width: 0,
                      height: 0,
                      display: 'inline-block',
                      borderTop: '4px solid',
                      transition: 'all 150ms ease-in',
                      borderLeft: '4px solid transparent',
                      marginLeft: '-8px',
                      borderRight: '4px solid transparent',
                      verticalAlign: 'middle',
                    },
                  }}
                  disablePortal // Force dropdown inside parent DOM
                  componentsProps={{
                    popper: {
                      style: {
                        minWidth: '0px !important',
                      },
                      sx: {
                        marginTop: '12px !important',
                        '& .MuiAutocomplete-listbox': {
                          padding: '5px',
                          backgroundColor: `${props.theme.palette.background.default}`,
                        },
                        '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
                          minWidth: '150px',
                          padding: '10px 20px',
                          transition: 'background-color 0.2s ease-in-out',
                          '&:hover': {
                            boxShadow:
                              '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2) !important',
                            backgroundColor: `${props.theme.palette.background.hoverNav} !important`,
                            color: '#ffffff',
                          },
                        },
                        '& .MuiAutocomplete-option[aria-selected="true"]': {
                          backgroundColor: 'transparent !important',
                        },
                      },
                    },
                  }}
                />
              </div>
            </ListItem>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={
                  <span
                    style={{
                      color: currentContext.toLowerCase().includes('hardcore') ? 'red' : 'inherit',
                    }}
                  >
                    {currentContext + ' '}
                    {currentContext.toLowerCase() === 'softcore ladder' && (
                      <>
                        <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                        <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                      </>
                    )}
                    {currentContext.toLowerCase() === 'softcore non-ladder' && (
                      <>
                        <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                        <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                      </>
                    )}
                    {currentContext.toLowerCase() === 'hardcore ladder' && (
                      <>
                        <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                        <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                      </>
                    )}
                    {currentContext.toLowerCase() === 'hardcore non-ladder' && (
                      <>
                        <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                        <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                      </>
                    )}
                  </span>
                }
                buttonProps={{
                  className: classes.navLink,
                  color: 'transparent',
                }}
                dropdownList={[
                  <Link
                    className={classes.dropdownLink}
                    onClick={(event) => currentContextFunc(event, 'Softcore Ladder')}
                  >
                    Softcore Ladder{' '}
                    <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                    <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                  </Link>,
                  <Link
                    className={classes.dropdownLink}
                    onClick={(event) => currentContextFunc(event, 'Softcore Non-Ladder')}
                  >
                    Softcore Non-Ladder{' '}
                    <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                    <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                  </Link>,
                  <Link
                    style={{ color: 'red' }}
                    className={classes.dropdownLink}
                    onClick={(event) => currentContextFunc(event, 'Hardcore Ladder')}
                  >
                    Hardcore Ladder{' '}
                    <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                    <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                  </Link>,
                  <Link
                    style={{ color: 'red' }}
                    className={classes.dropdownLink}
                    onClick={(event) => currentContextFunc(event, 'Hardcore Non-Ladder')}
                  >
                    Hardcore Non-Ladder{' '}
                    <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                    <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                  </Link>,
                ]}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={`${t('navigation.search')} 🛒`}
                buttonProps={{
                  className: classes.navLink,
                  color: 'transparent',
                }}
                buttonIcon={Search}
                dropdownList={[
                  <Link to="/items" className={classes.dropdownLink} style={{ color: '#385170' }}>
                    {t('navigation.privateSearch')} 🛒
                  </Link>,
                  <Link to="/" style={{ color: '#ff9800' }} className={classes.dropdownLink}>
                    {t('navigation.publicPage')} 🛒
                  </Link>,
                ]}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={
                  <span>
                    {t('navigation.pages')}{' '}
                    <img
                      style={{ width: '16px', verticalAlign: 'middle' }}
                      src={chestImg}
                      alt="chest"
                    />
                  </span>
                }
                buttonProps={{
                  className: classes.navLink,
                  color: 'transparent',
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link to="/preset" className={classes.dropdownLink}>
                    {t('navigation.presetData')}
                  </Link>,
                  <Link to="/tradelists" className={classes.dropdownLink}>
                    {t('navigation.tradelist')} 📜
                  </Link>,
                  <Link to="/supplier" className={classes.dropdownLink}>
                    {t('navigation.muleGamesSettings')}{' '}
                    <img
                      style={{ width: '16px', verticalAlign: 'middle' }}
                      src={chestImg}
                      alt="chest"
                    />
                  </Link>,
                  <Link to="/items?t=trades" className={classes.dropdownLink}>
                    {t('navigation.trades')}
                  </Link>,
                  <Link to="/items?t=droppers" className={classes.dropdownLink}>
                    {t('navigation.droppers')}
                  </Link>,
                  <Link to="/sitems" className={classes.dropdownLink}>
                    {t('navigation.suppliedTrashItems')}
                  </Link>,
                  <Link to="/sales" className={classes.dropdownLink}>
                    {t('navigation.salesHistory')}
                  </Link>,
                  <Link to="/analytics" className={classes.dropdownLink}>
                    {t('navigation.analytics')}
                  </Link>,
                ]}
              />
            </ListItem>
            {isNodeOwner && (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  buttonText={`${t('navigation.nodePages')} 👑`}
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent',
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                    <Link to="/node" className={classes.dropdownLink}>
                      {t('navigation.nodeSettingsActions')} 👑
                    </Link>,
                    <Link to="/users" className={classes.dropdownLink}>
                      {t('navigation.nodeUsers')} 👑
                    </Link>,
                  ]}
                />
              </ListItem>
            )}
            {isD2RS && (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  buttonText={t('navigation.d2rs')}
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent',
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                    <Link to="/orders" className={classes.dropdownLink}>
                      {t('navigation.orders')}
                    </Link>,
                    <Link to="/offers" className={classes.dropdownLink}>
                      {t('navigation.offers')}
                    </Link>,
                    userData && userData.user?.can_prescreen === 1 && (
                      <Link
                        to="/prescreen"
                        style={{ color: '#ff9800' }}
                        className={classes.dropdownLink}
                      >
                        {t('navigation.prescreening')} 🔍
                      </Link>
                    ),
                  ]}
                />
              </ListItem>
            )}
            {isStaff && (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  buttonText={t('navigation.staff')}
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent',
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                    <Link to="/staff" className={classes.dropdownLink}>
                      {t('navigation.nodeOverview')}
                    </Link>,
                    <Link to="/staff/users" className={classes.dropdownLink}>
                      {t('navigation.userOverview')}
                    </Link>,
                  ]}
                />
              </ListItem>
            )}
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={userData['navigation_text']}
                buttonProps={{
                  className: classes.navLink,
                  color: 'transparent',
                }}
                buttonIcon={Person}
                dropdownList={[
                  <Link to="/profile" className={classes.dropdownLink}>
                    {t('navigation.profile')}
                  </Link>,
                  <Link to="/settings" className={classes.dropdownLink}>
                    {t('navigation.settings')}
                  </Link>,
                  <Link to={''} className={classes.dropdownLink} onClick={logoutFunc}>
                    {t('navigation.logout')}
                  </Link>,
                ]}
              />
            </ListItem>
          </>
        ) : null
      ) : null}
    </List>
  );
}
