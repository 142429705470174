import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import axios from 'axios';
import config from '../../config/config.json';
import { RequireAuth } from 'components/Auth/RequireAuth';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';

interface User {
  id: number;
  name: string;
}

interface Node {
  id: string;
  host_user_id: string;
  node_name: string;
  monthly_fiat_fee: number;
  is_d2rs_node: boolean;
  fg_due: number;
  fiat_due: number;
  realm: string;
  created_at: string;
  updated_at: string;
  status: string;
  make_rares_available_to_d2rshop: boolean;
  make_rares_available_to_jsp: boolean;
  make_all_items_available_to_d2rshop: boolean;
  use_own_dynamic_pickits: boolean;
  discord_message_id: string;
  disabled: boolean;
}

interface Profile {
  name: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
  gridContainer: {
    height: '100%',
    width: '100%',
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
}));

export default function StaffPage() {
  const classes = useStyles();
  const sessionToken = localStorage.getItem('session_token');
  const [nodeNameForNodeCreation, setNodeNameForNodeCreation] = useState('');
  const [isMehlbotNodeForNodeCreation, setIsMehlbotNodeForNodeCreation] = useState(1);
  const [areRaresShopAvailableForNodeCreation, setAreRaresShopAvailableForNodeCreation] =
    useState(1);
  const [areRaresJSPAvailableForNodeCreation, setAreRaresJSPAvailableForNodeCreation] = useState(1);
  const [postInSalesHistories, setPostInSalesHistories] = useState(1);
  const [postInNewListings, setPostInNewListings] = useState(1);
  const [isEverythingShopAvailableForNodeCreation, setIsEverythingShopAvailableForNodeCreation] =
    useState(1);
  const [chosenUserIDForNodeCreation, setChosenUserForNodeCreation] = useState(0);
  const [defaultRealmForNodeCreation, setDefaultRealmForNodeCreation] = useState('europe');
  const [clientTypeForNodeCreation, setClientTypeForNodeCreation] = useState('dll');
  const [monthlyFiatFeeForNodeCreation, setMonthlyFiatFeeForNodeCreation] = useState(0);
  const [discordMessageIDForNodeCreation, setDiscordMessageIDForNodeCreation] = useState('');
  const [selectedNodeIDs, setSelectedNodeIDs] = useState<GridRowSelectionModel>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [nodeOwnersData, setNodeOwnersData] = useState<Node[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.staff'));

  if (!sessionToken) {
    return <Navigate to="/" replace />;
  }

  const handleSelectChange = (
    event: SelectChangeEvent<number>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setter(Number(event.target.value));
  };

  async function createNewNode() {
    const url = config.backend_base_url + 'staff/node/create';
    await axios
      .post(
        url,
        {
          node_name: nodeNameForNodeCreation.replaceAll(' ', ''),
          host_user_id: chosenUserIDForNodeCreation,
          realm: defaultRealmForNodeCreation,
          client_type: clientTypeForNodeCreation,
          is_d2rs_node: isMehlbotNodeForNodeCreation,
          make_all_items_available_to_d2rshop: isEverythingShopAvailableForNodeCreation,
          make_rares_available_to_d2rshop: areRaresShopAvailableForNodeCreation,
          make_rares_available_to_jsp: areRaresJSPAvailableForNodeCreation,
          monthly_fiat_fee: monthlyFiatFeeForNodeCreation,
          discord_message_id: discordMessageIDForNodeCreation,
          show_in_new_listings: postInNewListings,
          show_in_sales_history: postInSalesHistories,
          status: 'offline',
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then(() => {
        getNodeOwnersData();
        toast.success('Node created.');
        getNodeOwnersData();
      })
      .catch((error) => {
        toast.error('Error occured.', error);
      });
  }

  async function disableNodeFunc() {
    if (selectedNodeIDs.length !== 1) {
      toast.error('Select one node owner at a time');
      return;
    }
    const url = config.backend_base_url + 'staff/node/disable';
    await axios
      .post(
        url,
        {
          node_id: selectedNodeIDs[0],
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then(() => {
        toast.success('Node disabled.');
        getNodeOwnersData();
      })
      .catch(() => {
        toast.error('Error occured.');
      });
  }

  async function getNodeOwnersData() {
    const url = config.backend_base_url + 'staff/nodes';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setNodeOwnersData(response.data);
        } else {
          setNodeOwnersData([]);
        }
      })
      .catch(() => {
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getProfile() {
    await axios
      .get(config.backend_base_url + 'profile', { headers: { 'session-token': sessionToken } })
      .then((response: { data: Profile }) => {
        if (response.data.name !== 'Mehl' && response.data.name !== 'Mehlich') {
          toast.error('Error occured. Please refresh the page');
          window.location.href = '/';
        }
      })
      .catch(() => {
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getUsers() {
    await axios
      .get(config.backend_base_url + 'staff/users', {
        headers: { 'session-token': sessionToken },
      })
      .then((response) => {
        if (response.data != null) {
          setAllUsers(response.data);
        }
      })
      .catch(() => {
        toast.error('Error occured. Please refresh the page');
      });
  }

  if (loadingData) {
    getProfile();
    getUsers();
    getNodeOwnersData();
    setLoadingData(false);
  }

  const nodeOwnersColumns = [
    {
      field: 'host_user_id',
      headerName: 'Host User ID',
      flex: 4,
    },
    {
      field: 'id',
      headerName: 'Node ID',
      flex: 4,
    },
    {
      field: 'node_name',
      headerName: 'Node Name',
      flex: 4,
    },
    {
      field: 'monthly_fiat_fee',
      headerName: 'Monthly Fiat Fee',
      flex: 4,
    },
    {
      field: 'is_d2rs_node',
      headerName: 'Is D2RS Node',
      flex: 4,
    },
    {
      field: 'fg_due',
      headerName: 'FG Due Amount',
      flex: 4,
    },
    {
      field: 'fiat_due',
      headerName: 'Fiat Due Amount',
      flex: 4,
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 4,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 3,
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      flex: 3,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
    },
    {
      field: 'make_rares_available_to_d2rshop',
      headerName: 'Rares D2RShop available',
      flex: 2,
    },
    {
      field: 'make_rares_available_to_jsp',
      headerName: 'Rares JSP available',
      flex: 2,
    },
    {
      field: 'make_all_items_available_to_d2rshop',
      headerName: 'All D2RShop available',
      flex: 2,
    },
    {
      field: 'use_own_dynamic_pickits',
      headerName: 'Use Own DynPickits',
      flex: 2,
    },
    {
      field: 'discord_message_id',
      headerName: 'Discord Message ID',
      flex: 2,
    },
    {
      field: 'disabled',
      headerName: 'Disabled',
      flex: 1,
    },
  ];

  return (
    <RequireAuth requiredPermission="is_staff">
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <div className={classes.contentContainer}>
              <NavPills
                queryKey={'t'}
                alignCenter
                fullWidth
                onChange={function () {}}
                color="primary"
                tabs={[
                  {
                    tabName: 'nodes',
                    tabButton: `Node Overview (${nodeOwnersData.length})`,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <FormControl id="adminBookDueForm" fullWidth={true}>
                            <Autocomplete
                              id="Stat Filters"
                              options={allUsers}
                              getOptionLabel={(option) => option?.id + ': ' + option?.name || ''}
                              renderInput={(params) => (
                                <TextField {...params} label="Search for one of your Node Users" />
                              )}
                              onChange={(event, option) => {
                                event.preventDefault();
                                if (option) {
                                  setChosenUserForNodeCreation(option.id);
                                }
                              }}
                            />
                            <Select
                              id="noderealmas"
                              value={clientTypeForNodeCreation}
                              onChange={(params) => {
                                setClientTypeForNodeCreation(params.target.value);
                              }}
                            >
                              <MenuItem value={0} selected={true}>
                                {'Choose a client'}
                              </MenuItem>
                              <MenuItem value={'dll'}>{'dll 💉'}</MenuItem>
                              <MenuItem value={'pixel️'}>{'pixel 🖼️'}</MenuItem>
                            </Select>
                            <Select
                              id="noderealm"
                              value={defaultRealmForNodeCreation}
                              onChange={(params) => {
                                setDefaultRealmForNodeCreation(params.target.value);
                              }}
                            >
                              <MenuItem value={0} selected={true}>
                                {'Choose a realm'}
                              </MenuItem>
                              <MenuItem value={'europe'}>{'europe'}</MenuItem>
                              <MenuItem value={'americas'}>{'americas'}</MenuItem>
                              <MenuItem value={'asia'}>{'asia'}</MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={isMehlbotNodeForNodeCreation}
                              onChange={(e) =>
                                handleSelectChange(e, setIsMehlbotNodeForNodeCreation)
                              }
                            >
                              <MenuItem value={0} selected={true}>
                                {'Is not a Mehlbot Node'}
                              </MenuItem>
                              <MenuItem value={1}>{'Is a Mehlbot Node'}</MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={isEverythingShopAvailableForNodeCreation}
                              onChange={(e) =>
                                handleSelectChange(e, setIsEverythingShopAvailableForNodeCreation)
                              }
                            >
                              <MenuItem value={0} selected={true}>
                                {'Everything is NOT Shop available'}
                              </MenuItem>
                              <MenuItem value={1}>{'Everything is Shop available'}</MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={areRaresShopAvailableForNodeCreation}
                              onChange={(e) =>
                                handleSelectChange(e, setAreRaresShopAvailableForNodeCreation)
                              }
                            >
                              <MenuItem value={0} selected={true}>
                                {'Rares are NOT Shop available'}
                              </MenuItem>
                              <MenuItem value={1}>{'Rares are Shop available'}</MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={areRaresJSPAvailableForNodeCreation}
                              onChange={(e) =>
                                handleSelectChange(e, setAreRaresJSPAvailableForNodeCreation)
                              }
                            >
                              <MenuItem value={0} selected={true}>
                                {'Rares are NOT JSP available'}
                              </MenuItem>
                              <MenuItem value={1}>{'Rares are JSP available'}</MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={postInNewListings}
                              onChange={(e) => handleSelectChange(e, setPostInNewListings)}
                            >
                              <MenuItem value={0} selected={true}>
                                {'Do NOT show new listings on discord channels'}
                              </MenuItem>
                              <MenuItem value={1}>
                                {'Show new listings on discord channels'}
                              </MenuItem>
                            </Select>
                            <Select
                              id="nodeuserid"
                              value={postInSalesHistories}
                              onChange={(e) => handleSelectChange(e, setPostInSalesHistories)}
                            >
                              <MenuItem value={0} selected={true}>
                                {'Do NOT show sales history on discord channels'}
                              </MenuItem>
                              <MenuItem value={1}>
                                {'Show sales history on discord channels'}
                              </MenuItem>
                            </Select>
                            <CustomInput
                              labelText="Discord Message ID"
                              id="did"
                              value={discordMessageIDForNodeCreation}
                              onChange={(params) => {
                                setDiscordMessageIDForNodeCreation(params.target.value);
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                            <CustomInput
                              labelText="Monthly Fiat Fee"
                              id="bookDueAmount"
                              value={monthlyFiatFeeForNodeCreation.toString()}
                              onChange={(params) => {
                                setMonthlyFiatFeeForNodeCreation(parseInt(params.target.value, 10));
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                            <CustomInput
                              labelText="Node Name"
                              id="bookDueAmount"
                              value={nodeNameForNodeCreation}
                              onChange={(params) => {
                                setNodeNameForNodeCreation(params.target.value.replaceAll(' ', ''));
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                            <Button
                              color="primary"
                              type="submit"
                              form="adminBookDueForm"
                              onClick={createNewNode}
                            >
                              Create Node
                            </Button>
                          </FormControl>
                        </GridItem>
                        <hr />
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ height: '100%', width: '100%' }}>
                            <DataGrid
                              autoHeight
                              columns={nodeOwnersColumns}
                              rows={nodeOwnersData}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 100,
                                  },
                                },
                              }}
                              pageSizeOptions={[10]}
                              checkboxSelection
                              onRowSelectionModelChange={setSelectedNodeIDs}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            color="primary"
                            disabled={selectedNodeIDs.length !== 1}
                            type="submit"
                            fullWidth={true}
                            form="adminBookDueForm"
                            onClick={disableNodeFunc}
                          >
                            Disable Node
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </RequireAuth>
  );
}
