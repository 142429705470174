import React, { useState } from 'react';
// @material-ui/core components
// @material-ui/icons
// core components
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import config from '../../config/config.json';

import { DataGrid } from '@mui/x-data-grid';
import NavPills from '../../components/NavPills/NavPills';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextFields from '@mui/icons-material/TextFields';
import chestImg from '../../assets/img/d2rchest.png';
import { useTranslation } from 'react-i18next';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles(styles);
export default function SupplierPage() {
  const classes = useStyles();
  const [loadRole, setLoadRole] = useState(true);
  const [muleGameAndCharAmount, setMuleGamesAndCharAmount] = useState({
    free_chars: 0,
    max_games: 0,
  });
  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  if (loadRole) {
    setLoadRole(false);
  }

  const [d2rGamesData, setD2rGamesData] = useState([]);

  const [muleGameName, setMuleGameName] = useState('');
  const [muleGamePassword, setMuleGamePassword] = useState('');

  const { t } = useTranslation();
  useDocumentTitle(t('navigation.muleGamesSettings'));

  async function getD2RGames() {
    let url = config.backend_base_url + 'mygames';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setD2rGamesData(response.data);
        } else {
          setD2rGamesData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  async function getAdminSettings() {
    let url = config.backend_base_url + 'admin/settings';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          if (response.data.mule_game_name && muleGameName === '') {
            setMuleGameName(response.data.mule_game_name);
          }
          if (response.data.mule_game_password && muleGamePassword === '') {
            setMuleGamePassword(response.data.mule_game_password);
          }
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  async function getFreeMuleCharAmount() {
    let url = config.backend_base_url + 'mls/mule/char/amount';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setMuleGamesAndCharAmount(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  const d2rColumns = [
    {
      field: 'name',
      headerName: t('search.columns.gameName'),
      flex: 4,
    },
    {
      field: 'password',
      headerName: t('search.columns.password'),
      flex: 4,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 4,
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 4,
    },
    {
      field: 'fiat_only',
      headerName: 'Fiat Only',
      flex: 1,
    },
    {
      field: 'permanent',
      headerName: 'Permanent',
      flex: 1,
    },
  ];

  async function openMuleGameForAdmin() {
    let checkFailed = false;
    if (muleGameName.length < 1) {
      toast.error('You must use a custom Gamename');
      checkFailed = true;
    }
    if (muleGamePassword.length < 1) {
      toast.error('You must use a custom Gamepassword');
      checkFailed = true;
    }
    if (checkFailed) {
      return;
    }

    let url = config.backend_base_url + 'game/open';
    await axios
      .post(
        url,
        {
          fiat_only: fiatOnlyMuling,
          realm: realmMuling,
          game_name: muleGameName,
          game_password: muleGamePassword,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Successfully opened Game.');
        getD2RGames();
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  async function closeMuleGames() {
    let url = config.backend_base_url + 'games/close';
    await axios
      .post(url, {}, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        console.log('Success ========>', response);
        toast.success('Successfully closed Games.');
        getD2RGames();
      })
      .catch((error) => {
        toast.error(error.response.data['Message']);
      });
  }

  let defaultMuleRealm = localStorage.getItem('default_realm');
  if (!defaultMuleRealm) {
    defaultMuleRealm = 'europe';
  }
  const [realmMuling, setRealmMuling] = useState(defaultMuleRealm);
  const [fiatOnlyMuling, setFiatOnlyMuling] = useState(0);
  const [selectedMuleGameIDs, setSelectedMuleGameIDs] = useState([]);
  let sendCloseSelectedMuleGamesToRemove = function () {
    let uniqueIDs = selectedMuleGameIDs.filter((c, index) => {
      return selectedMuleGameIDs.indexOf(c) === index;
    });

    axios
      .post(
        config.backend_base_url + 'game/close',
        {
          game_ids: uniqueIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Successfully closed Games.');
        setSelectedMuleGameIDs([]);
        getD2RGames();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  };

  const [loadingData, setLoadingData] = useState(true);
  if (loadingData) {
    getD2RGames();
    getFreeMuleCharAmount();
    getAdminSettings();
    setLoadingData(false);
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <NavPills
            queryKey={'t'}
            alignCenter
            onChange={function () {}}
            color="primary"
            tabs={[
              {
                tabName: 'games',
                tabButton: t('search.navPills.muleGames'),
                tabContent: (
                  <GridContainer spacing={1}>
                    <GridItem xs={12} sm={12} md={12}>
                      <b>
                        {t('game.mule.serviceCharsAvailable', {
                          count: muleGameAndCharAmount.free_chars,
                          maxGames: muleGameAndCharAmount.max_games,
                        })}
                      </b>
                      <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                          autoHeight
                          getRowWidth={() => 'auto'}
                          columns={d2rColumns}
                          rows={d2rGamesData}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          checkboxSelection
                          onRowSelectionModelChange={(params) => {
                            let newSelection = [];
                            params.forEach(function (item) {
                              let selItem = d2rGamesData.filter(function (selIte) {
                                return selIte.id === item;
                              })[0];
                              newSelection.push(selItem.id);
                            });
                            setSelectedMuleGameIDs(newSelection);
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} xl={12} style={{ paddingTop: 10 }}>
                      <Select
                        value={fiatOnlyMuling}
                        fullWidth={true}
                        onChange={(params) => {
                          setFiatOnlyMuling(params.target.value);
                        }}
                      >
                        <MenuItem value={0}>{t('game.mule.fiatAndFg')}</MenuItem>
                        <MenuItem value={1}>{t('game.mule.fiatOnly')}</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4}>
                      <CustomInput
                        labelText={t('search.game.name')}
                        id="gameName"
                        value={muleGameName}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setMuleGameName(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextFields />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4}>
                      <CustomInput
                        labelText={t('search.game.password')}
                        id="gamePassword"
                        value={muleGamePassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(params) => {
                          setMuleGamePassword(params.target.value);
                        }}
                        inputProps={{
                          type: 'text',
                          endAdornment: (
                            <InputAdornment position="end">
                              <TextFields />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} xl={4} style={{ paddingTop: 10 }}>
                      <Select
                        value={realmMuling}
                        fullWidth={true}
                        onChange={(params) => {
                          setRealmMuling(params.target.value);
                          localStorage.setItem('default_realm', params.target.value);
                        }}
                      >
                        <MenuItem value={'americas'}>{t('search.game.realm.americas')}</MenuItem>
                        <MenuItem value={'europe'}>{t('search.game.realm.europe')}</MenuItem>
                        <MenuItem value={'asia'}>{t('search.game.realm.asia')}</MenuItem>
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="openGameForm" fullWidth={true}>
                        <Button color="primary" type="submit" onClick={openMuleGameForAdmin}>
                          <img
                            style={{ width: '16px', verticalAlign: 'middle' }}
                            src={chestImg}
                            alt={t('game.mule.openGame')}
                          />
                          {t('game.mule.openGame')}
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="closeMuleGamessForm" fullWidth={true}>
                        <Button
                          color="danger"
                          type="submit"
                          disabled={selectedMuleGameIDs.length === 0}
                          onClick={sendCloseSelectedMuleGamesToRemove}
                        >
                          ❌{t('game.mule.closeSelected')}
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl id="closeGamesForm" fullWidth={true}>
                        <Button color="danger" type="submit" onClick={closeMuleGames}>
                          ❌{t('game.mule.closeAll')}
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer
        hideMuleBubble={true}
        pageKey="supplierPage"
        helpText={t('help.supplierPage')}
      />
    </div>
  );
}
