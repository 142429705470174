import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import axios from 'axios';
import config from '../../config/config.json';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { RequireAuth } from 'components/Auth/RequireAuth';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import blueIcon from '../../assets/img/blue_icon.webp';
import { Link } from 'react-router-dom';

interface Offer {
  id: number;
  item_id: number;
  item_name: string;
  item_image: string;
  context: string;
  offer: string;
  status: string;
  created_at: string;
  shoplink?: string;
  discordid?: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
}));

export default function OffersPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.offers'));
  const sessionToken = localStorage.getItem('session_token');
  const queryClient = useQueryClient();

  // Fetch pending offers
  const { data: pendingOffers = [] } = useQuery({
    queryKey: ['pendingOffers'],
    queryFn: async () => {
      const response = await axios.get<Offer[]>(`${config.backend_base_url}pss/staff/offers`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    enabled: !!sessionToken,
  });

  // Accept offer mutation
  const acceptOfferMutation = useMutation({
    mutationFn: async (offerId: number) => {
      if (!sessionToken) {
        throw new Error('No session token available');
      }
      await axios.post(
        `${config.backend_base_url}pss/o/accept`,
        { offer_id: offerId },
        { headers: { 'session-token': sessionToken } }
      );
    },
    onSuccess: () => {
      toast.success(t('search.item.offerAccepted'));
      queryClient.invalidateQueries({ queryKey: ['pendingOffers'] });
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? error.message
          : t('search.errors.acceptOfferFailed', 'Failed to accept offer. Please try again');
      toast.error(message);
    },
  });

  // Decline offer mutation
  const declineOfferMutation = useMutation({
    mutationFn: async (offerId: number) => {
      if (!sessionToken) {
        throw new Error('No session token available');
      }
      await axios.post(
        `${config.backend_base_url}pss/o/decline`,
        { offer_id: offerId },
        { headers: { 'session-token': sessionToken } }
      );
    },
    onSuccess: () => {
      toast.success(t('search.item.offerDeclined'));
      queryClient.invalidateQueries({ queryKey: ['pendingOffers'] });
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? error.message
          : t('search.errors.declineOfferFailed', 'Failed to decline offer. Please try again');
      toast.error(message);
    },
  });

  const columns: GridColDef<Offer>[] = [
    {
      field: 'item_image',
      headerName: t('search.offers.image'),
      flex: 0,
      width: 400,
      type: 'string',
      renderCell: (params) => (
        <div
          style={{
            width: '100%',
            height: '400px',
            padding: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={params.value}
            alt={params.row.item_name}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      ),
    },
    {
      field: 'item_name',
      headerName: t('search.offers.itemName'),
      renderHeader: () => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {t('search.offers.itemName')} (click
          <img
            src={blueIcon}
            alt="View Details"
            style={{
              width: '20px',
              height: '20px',
              margin: '0 4px',
            }}
          />
          icon to view)
        </div>
      ),
      type: 'string',
      flex: 2,
      minWidth: 200,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '8px',
            gap: '8px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ whiteSpace: 'normal', lineHeight: '1.5', fontWeight: 'bold' }}>
              {params.row.item_name}
            </span>
            <Link to={`/i/${params.row.item_id}`} target="_blank">
              <img
                src={blueIcon}
                alt="View Details"
                title={t('search.offers.clickToView', 'Click to view item details')}
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
              />
            </Link>
          </div>
        </div>
      ),
    },
    {
      field: 'context',
      headerName: t('search.offers.context'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div
          style={{
            padding: '8px',
            whiteSpace: 'normal',
            lineHeight: '1.5',
            textTransform: 'uppercase',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'offer',
      headerName: t('search.offers.price'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ padding: '8px' }}>
          <div>Offer: ${params.value}</div>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('search.offers.status'),
      type: 'string',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div
          style={{
            padding: '8px',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'created_at',
      headerName: t('search.offers.createdAt', 'Created At'),
      type: 'dateTime',
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => new Date(params.value),
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = new Intl.DateTimeFormat(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }).format(date);
        return <div style={{ padding: '8px' }}>{formattedDate}</div>;
      },
    },
    {
      field: 'actions',
      headerName: t('search.offers.actions'),
      type: 'actions',
      flex: 0,
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            gap: '8px',
            padding: '8px',
            flexWrap: 'nowrap',
          }}
        >
          <Button
            color="primary"
            size="sm"
            onClick={() => acceptOfferMutation.mutate(params.row.id)}
            disabled={acceptOfferMutation.isPending}
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('search.offers.acceptOffer')}
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => declineOfferMutation.mutate(params.row.id)}
            disabled={declineOfferMutation.isPending}
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('search.offers.declineOffer')}
          </Button>
        </div>
      ),
    },
  ];

  const OfferDataGrid = ({ offers }: { offers: Offer[] }) => (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.dataGridContainer}>
          <DataGrid
            autoHeight
            rows={offers}
            columns={columns}
            getRowHeight={() => 'auto'}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                height: 'auto !important',
                maxHeight: 'none !important',
                lineHeight: 'normal',
                display: 'flex',
                alignItems: 'center',
                overflow: 'visible !important',
              },
              '& .MuiDataGrid-row': {
                maxHeight: 'none !important',
                height: 'auto !important',
              },
              '& .MuiDataGrid-renderCell': {
                overflow: 'visible !important',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              },
            }}
          />
        </div>
      </GridItem>
    </GridContainer>
  );

  return (
    <RequireAuth requiredPermission="is_d2rs">
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <div className={classes.contentContainer}>
              <NavPills
                queryKey={'t'}
                alignCenter
                fullWidth
                onChange={function () {}}
                color="primary"
                tabs={[
                  {
                    tabName: 'pending',
                    tabButton: `${t('search.offers.pending')} (${pendingOffers.length})`,
                    tabContent: <OfferDataGrid offers={pendingOffers} />,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <BubbleContainer pageKey="offersPage" helpText="todo: add help text" />
      </div>
    </RequireAuth>
  );
}
