import { useTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem.js';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles<Theme>(() => ({
  mainContainer: {
    '&&&&': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '48px auto',
      width: 'auto',
    },
  },
  goBackStyle: {
    marginLeft: '24px',
    marginBottom: '24px',
  },
  headLineStyle: {
    fontWeight: '500',
  },
  subHeadLineStyle: {
    fontWeight: '500',
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle('404 - Not Found');

  return (
    <>
      <GridItem md={12} className={classes.mainContainer}>
        <h1 className={classes.headLineStyle}>{t('notFoundPage.notFoundHeadline')}</h1>
        <h2 className={classes.subHeadLineStyle}>{t('notFoundPage.notFoundSubHeadline')}</h2>
        <Link to={'/'} className={classes.goBackStyle}>
          <Button color="primary" size="lg">
            {t('notFoundPage.goBack')}
          </Button>
        </Link>
      </GridItem>
    </>
  );
}
