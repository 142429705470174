import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translations
import translationEN from './locales/en/english.json';
import translationDE from './locales/de/german.json';
import translationKO from './locales/ko/korean.json';
import translationZH from './locales/zh/chinese.json';

export const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  ko: {
    translation: translationKO,
  },
  zh: {
    translation: translationZH,
  },
};

export const availableLanguages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'ko',
    name: '한국어',
  },
  {
    code: 'zh',
    name: '中文',
  },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    load: 'languageOnly',
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
