import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { GB, DE, KR, CN } from 'country-flag-icons/react/3x2';
import { availableLanguages } from '../../i18n';
import LanguageSelectorDropdown from './LanguageSelectorDropdown';

const ListItem = styled('div')(({ theme }) => ({
  float: 'left',
  color: 'inherit',
  position: 'relative',
  display: 'block',
  width: 'auto',
  margin: '0',
  padding: '0',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '&:after': {
      width: 'calc(100% - 30px)',
      content: '""',
      display: 'block',
      height: '1px',
      marginLeft: '15px',
      backgroundColor: '#e5e5e5',
    },
  },
}));

const DropdownLink = styled('button')(() => ({
  color: 'inherit',
  padding: '0.75rem 1.25rem 0.75rem 0.75rem',
  fontWeight: 400,
  fontSize: '12px',
  textTransform: 'uppercase',
  borderRadius: '3px',
  lineHeight: '20px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: 'inherit',
    background: 'rgba(200, 200, 200, 0.2)',
    outline: 'none',
  },
}));

const FlagWrapper = styled('span')(({ theme }) => ({
  width: '24px',
  height: '18px',
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: '100%',
    height: '100%',
  },
}));

interface FlagComponents {
  [key: string]: React.ComponentType;
}

const flagComponents: FlagComponents = {
  en: GB,
  de: DE,
  ko: KR,
  zh: CN,
};

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage && availableLanguages.some((lang) => lang.code === savedLanguage)) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (languageCode: string) => {
    localStorage.setItem('preferredLanguage', languageCode);
    i18n.changeLanguage(languageCode);
  };

  const currentLanguage =
    availableLanguages.find((lang) => lang.code === i18n.language) || availableLanguages[0];

  const getFlagComponent = (languageCode: string) => {
    const FlagComponent = flagComponents[languageCode];
    return FlagComponent ? (
      <FlagWrapper>
        <FlagComponent />
      </FlagWrapper>
    ) : null;
  };

  const dropdownList = availableLanguages.map((language) => (
    <DropdownLink
      key={language.code}
      onClick={() => handleLanguageChange(language.code)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleLanguageChange(language.code);
        }
      }}
      type="button"
      role="menuitem"
    >
      {getFlagComponent(language.code)}
      <Typography variant="inherit">{language.name}</Typography>
    </DropdownLink>
  ));

  return (
    <ListItem>
      <LanguageSelectorDropdown
        noLiPadding
        buttonText={getFlagComponent(currentLanguage.code)}
        buttonProps={{
          className: DropdownLink,
          color: 'transparent',
        }}
        dropdownList={dropdownList}
      />
    </ListItem>
  );
};

export default LanguageSelector;
