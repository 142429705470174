import { container, title } from 'assets/jss/material-kit-react.js';
import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js';

const profilePageStyle = {
  container,
  profile: {
    textAlign: 'center',
    '& img': {
      maxWidth: '160px',
      width: '100%',
      margin: '0 auto',
      transform: 'translate3d(0, -50%, 0)',
    },
  },
  description: {
    margin: '1.071rem auto 0',
    maxWidth: '600px',
    color: '#999',
    textAlign: 'center !important',
  },
  name: {
    marginTop: '-80px',
  },
  ...imagesStyle,
  main: {
    position: 'relative',
    zIndex: '3',
  },
  modal: {
    backgroundImage: 'none !important',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  socials: {
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
    color: '#999',
  },
  navWrapper: {
    margin: '20px auto 50px auto',
    textAlign: 'center',
  },
  grid: {
    paddingLeft: '4px !important',
    paddingRight: '4px !important',
    paddingTop: '10px !important',
    maxHeight: '67px !important',
  },
  searchWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',

    '@media (max-width: 1366px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
  },
};

export default profilePageStyle;
