import { defaultFont } from 'assets/jss/material-kit-react.js';
import tooltip from 'assets/jss/material-kit-react/tooltipsStyle.js';

const headerLinksStyle = (theme) => ({
  list: {
    '&&&&': {
      ...defaultFont,
      fontSize: '14px',
      margin: 0,
      padding: '0 !important',
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  listItem: {
    '&&&&': {
      float: 'left',
      position: 'relative',
      display: 'block',
      margin: '0',
      padding: '0',
      width: 'auto',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '&:after': {
          width: 'calc(100% - 30px)',
          content: '""',
          display: 'block',
          height: '1px',
          marginLeft: '15px',
          backgroundColor: '#e5e5e5',
        },
      },
    },
  },
  listItemText: {
    '&&&&': {
      padding: '0 !important',
    },
  },
  navLink: {
    '&&&&': {
      color: 'inherit',
      padding: '0.9375rem',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '3px',
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'rgba(200, 200, 200, 0.2)',
      },
    },
  },
  notificationNavLink: {
    '&&&&': {
      padding: '0.9375rem',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      textDecoration: 'none',
      display: 'inline-flex',
      top: '4px',
    },
  },
  registerNavLink: {
    '&&&&': {
      top: '3px',
      position: 'relative',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      textDecoration: 'none',
      display: 'inline-flex',
    },
  },
  navLinkActive: {
    '&&&&': {
      color: 'inherit',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  icons: {
    '&&&&': {
      width: '20px',
      height: '20px',
      marginRight: '3px',
    },
  },
  socialIcons: {
    '&&&&': {
      position: 'relative',
      fontSize: '20px !important',
      marginRight: '4px',
    },
  },
  dropdownLink: {
    '&&&&': {
      '&,&:hover,&:focus': {
        color: 'inherit',
        textDecoration: 'none',
        display: 'block',
        padding: '10px 20px',
      },
    },
  },
  marginRight5: {
    '&&&&': {
      marginRight: '5px',
    },
  },
  ...tooltip,
});

export default headerLinksStyle;
