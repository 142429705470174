import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledAlert = styled(Alert)(({ theme, color }) => ({
  position: 'relative',
  padding: '20px 15px',
  lineHeight: '20px',
  marginBottom: '20px',
  fontSize: '14px',
  borderRadius: 0,
  maxWidth: '100%',
  minWidth: 'auto',
  boxShadow:
    '0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)',
  '& .MuiAlert-message': {
    padding: 0,
    display: 'block',
    maxWidth: '89%',
  },
  '& .MuiAlert-icon': {
    marginRight: theme.spacing(2),
    padding: 0,
    fontSize: '24px',
    opacity: 0.9,
  },
  '& .MuiAlert-action': {
    marginRight: -8,
    padding: 0,
    height: 'auto',
  },
}));

export default function SnackbarContent(props) {
  const { message, color = 'info', close, icon } = props;
  const [alert, setAlert] = React.useState(true);

  const handleClose = () => {
    setAlert(false);
  };

  if (!alert) return null;

  return (
    <StyledAlert
      severity={color}
      icon={icon}
      action={
        close ? (
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null
      }
    >
      {message}
    </StyledAlert>
  );
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  close: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
