import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Paper, Typography, IconButton, Box, Grid, Badge, Tooltip, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import blueIcon from '../../assets/img/blue_icon.webp';
import yellowIcon from '../../assets/img/yellow_icon.png';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { useTrackedItems } from '../../stores/trackedItems';
import { useTrackedProducts } from '../../stores/trackedProducts';
import config from '../../config/config.json';
import NavPills from '../../components/NavPills/NavPills';
import { toast } from 'react-hot-toast';

interface TrackerBubbleProps {
  hasBottomBubble?: boolean;
  bottomBubblesCount?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 99999,
    pointerEvents: 'none',
  },
  trackerContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    minWidth: '300px',
    '@media (min-width: 600px)': {
      minWidth: '400px',
    },
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  contentWrapper: {
    width: '100%',
    maxHeight: 'calc(100vh - 240px)',
    marginBottom: '8px',
    marginTop: '64px',
    position: 'relative',
    bottom: 'auto',
    overflowY: 'auto',
  },
  trackerContent: {
    padding: '16px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  headerBox: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  trackerButton: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.open': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    width: 56,
    height: 56,
    borderRadius: '50%',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      right: 4,
      top: 4,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
}));

export default function TrackerBubble({
  hasBottomBubble = true,
  bottomBubblesCount = 0,
}: TrackerBubbleProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const sessionToken = localStorage.getItem('session_token');
  const { data: trackedItems = [], isLoading: isLoadingItems } = useTrackedItems();
  const { data: trackedProducts = [], isLoading: isLoadingProducts } = useTrackedProducts();

  const handleToggle = () => {
    if (!sessionToken) {
      toast.error(t('auth.mustLoginToTrack'));
      return;
    }
    setIsOpen(!isOpen);
  };

  const itemColumns = [
    {
      field: 'name',
      headerName: t('search.columns.name'),
      flex: 1,
      renderCell: (params: any) => {
        const skinPath = params.row.skin_image_path
          ? `${config.backend_base_url}skin/${params.row.skin_image_path}`
          : undefined;
        const imagePath = `${config.backend_base_url}image/${params.row.image_path}`;
        return (
          <Tooltip
            title={
              <div>
                {params.row.name}
                <br />
                <img
                  src={imagePath}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.row.name}
                />
              </div>
            }
            arrow
            PopperProps={{
              sx: {
                zIndex: 10000,
              },
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img
                src={skinPath || imagePath}
                alt={params.row.name}
                style={{ width: 32, height: 32, objectFit: 'contain' }}
              />
              <Typography>{params.row.name}</Typography>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('search.columns.actions'),
      width: 120,
      sortable: false,
      renderCell: (params: any) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 1, position: 'relative', zIndex: 1 }}
        >
          <Tooltip
            title={t('search.viewDetails')}
            PopperProps={{
              sx: {
                zIndex: 10000,
              },
            }}
          >
            <Link href={`/i/${params.row.item_id}`} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={blueIcon}
                  alt={t('search.viewDetails')}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Box>
            </Link>
          </Tooltip>
          {params.row.shop_product_link && (
            <Tooltip
              title={t('search.viewShop')}
              PopperProps={{
                sx: {
                  zIndex: 10000,
                },
              }}
            >
              <Link
                href={params.row.shop_product_link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={yellowIcon}
                    alt={t('search.viewShop')}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const productColumns = [
    {
      field: 'name',
      headerName: t('search.columns.name'),
      flex: 1,
      renderCell: (params: any) => {
        const skinPath = params.row.skin_image_path
          ? `${config.backend_base_url}skin/${params.row.skin_image_path}`
          : undefined;
        const imagePath = `${config.backend_base_url}image/${params.row.image_path}`;
        return (
          <Tooltip
            title={
              <div>
                {params.row.name}
                <br />
                <img
                  src={imagePath}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.row.name}
                />
              </div>
            }
            arrow
            PopperProps={{
              sx: {
                zIndex: 10000,
              },
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img
                src={skinPath || imagePath}
                alt={params.row.name}
                style={{ width: 32, height: 32, objectFit: 'contain' }}
              />
              <Typography>{params.row.name}</Typography>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('search.columns.actions'),
      width: 120,
      sortable: false,
      renderCell: (params: any) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 1, position: 'relative', zIndex: 1 }}
        >
          <Tooltip
            title={t('search.viewDetails')}
            PopperProps={{
              sx: {
                zIndex: 10000,
              },
            }}
          >
            <Link
              href={`/p/${params.row.product_id}`}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={blueIcon}
                  alt={t('search.viewDetails')}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Box>
            </Link>
          </Tooltip>
          {params.row.shop_product_link && (
            <Tooltip
              title={t('search.viewShop')}
              PopperProps={{
                sx: {
                  zIndex: 10000,
                },
              }}
            >
              <Link
                href={params.row.shop_product_link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={yellowIcon}
                    alt={t('search.viewShop')}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box
      className={classes.root}
      sx={{ bottom: hasBottomBubble ? `${bottomBubblesCount * 72 + 16}px` : '16px' }}
    >
      <Box className={classes.trackerContainer}>
        {isOpen && sessionToken && (
          <Paper className={classes.contentWrapper} elevation={4}>
            <Box className={classes.trackerContent}>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">{t('search.trackedItems')}</Typography>
                      <IconButton size="small" onClick={handleToggle} aria-label="close tracker">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <NavPills
                      queryKey="t"
                      alignCenter
                      color="primary"
                      tabs={[
                        {
                          tabName: 'items',
                          tabButton: `${t('tracker.items')} (${trackedItems.length})`,
                          tabContent: (
                            <Box>
                              {isLoadingItems ? (
                                <Box sx={{ textAlign: 'center', p: 2 }}>
                                  {t('common.loading.fetchingData')}
                                </Box>
                              ) : trackedItems.length > 0 ? (
                                <div className={classes.dataGridContainer}>
                                  <DataGrid
                                    rows={trackedItems}
                                    columns={itemColumns}
                                    autoHeight
                                    getRowHeight={() => 'auto'}
                                    initialState={{
                                      pagination: {
                                        paginationModel: {
                                          pageSize: 10,
                                        },
                                      },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                    disableRowSelectionOnClick
                                    sx={{
                                      '& .MuiDataGrid-root': {
                                        width: '100%',
                                      },
                                      '& .MuiDataGrid-main': {
                                        overflow: 'hidden',
                                      },
                                      '& .MuiDataGrid-columnHeaders': {
                                        minHeight: '60px !important',
                                        maxHeight: 'none !important',
                                        lineHeight: '1.2 !important',
                                        whiteSpace: 'normal',
                                      },
                                      '& .MuiDataGrid-columnHeader': {
                                        height: 'auto !important',
                                        whiteSpace: 'normal',
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                          whiteSpace: 'normal',
                                          lineHeight: '1.2',
                                          overflow: 'hidden',
                                        },
                                      },
                                      '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal !important',
                                        padding: '8px',
                                        minHeight: 'auto !important',
                                        height: 'auto !important',
                                        overflow: 'hidden !important',
                                        '&:focus': {
                                          outline: 'none',
                                        },
                                        '&:focus-within': {
                                          outline: 'none',
                                        },
                                      },
                                      '& .MuiDataGrid-row': {
                                        maxHeight: 'none !important',
                                        minHeight: 'auto !important',
                                      },
                                      '& .MuiDataGrid-renderCell': {
                                        overflow: 'hidden !important',
                                        whiteSpace: 'normal !important',
                                        wordWrap: 'break-word !important',
                                        display: 'block !important',
                                      },
                                    }}
                                  />
                                </div>
                              ) : (
                                <Box sx={{ textAlign: 'center', p: 2 }}>{t('tracker.noItems')}</Box>
                              )}
                            </Box>
                          ),
                        },
                        {
                          tabName: 'products',
                          tabButton: `${t('tracker.products')} (${trackedProducts.length})`,
                          tabContent: (
                            <Box>
                              {isLoadingProducts ? (
                                <Box sx={{ textAlign: 'center', p: 2 }}>
                                  {t('common.loading.fetchingData')}
                                </Box>
                              ) : trackedProducts.length > 0 ? (
                                <div className={classes.dataGridContainer}>
                                  <DataGrid
                                    rows={trackedProducts}
                                    columns={productColumns}
                                    autoHeight
                                    getRowHeight={() => 'auto'}
                                    initialState={{
                                      pagination: {
                                        paginationModel: {
                                          pageSize: 10,
                                        },
                                      },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                    disableRowSelectionOnClick
                                    sx={{
                                      '& .MuiDataGrid-root': {
                                        width: '100%',
                                      },
                                      '& .MuiDataGrid-main': {
                                        overflow: 'hidden',
                                      },
                                      '& .MuiDataGrid-columnHeaders': {
                                        minHeight: '60px !important',
                                        maxHeight: 'none !important',
                                        lineHeight: '1.2 !important',
                                        whiteSpace: 'normal',
                                      },
                                      '& .MuiDataGrid-columnHeader': {
                                        height: 'auto !important',
                                        whiteSpace: 'normal',
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                          whiteSpace: 'normal',
                                          lineHeight: '1.2',
                                          overflow: 'hidden',
                                        },
                                      },
                                      '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal !important',
                                        padding: '8px',
                                        minHeight: 'auto !important',
                                        height: 'auto !important',
                                        overflow: 'hidden !important',
                                        '&:focus': {
                                          outline: 'none',
                                        },
                                        '&:focus-within': {
                                          outline: 'none',
                                        },
                                      },
                                      '& .MuiDataGrid-row': {
                                        maxHeight: 'none !important',
                                        minHeight: 'auto !important',
                                      },
                                      '& .MuiDataGrid-renderCell': {
                                        overflow: 'hidden !important',
                                        whiteSpace: 'normal !important',
                                        wordWrap: 'break-word !important',
                                        display: 'block !important',
                                      },
                                    }}
                                  />
                                </div>
                              ) : (
                                <Box sx={{ textAlign: 'center', p: 2 }}>
                                  {t('tracker.noProducts')}
                                </Box>
                              )}
                            </Box>
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        )}
        <Badge
          badgeContent={sessionToken ? trackedItems.length + trackedProducts.length : 0}
          color="primary"
          className={classes.badge}
          max={99}
        >
          {sessionToken ? (
            <IconButton
              className={`${classes.trackerButton} ${isOpen ? 'open' : ''}`}
              onClick={handleToggle}
              aria-label={isOpen ? 'close tracker' : 'show tracker'}
              size="large"
            >
              {isOpen ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </IconButton>
          ) : (
            <Tooltip title={t('auth.needLoginToTrack')}>
              <IconButton
                className={`${classes.trackerButton} ${isOpen ? 'open' : ''}`}
                onClick={handleToggle}
                aria-label={isOpen ? 'close tracker' : 'show tracker'}
                size="large"
              >
                {isOpen ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              </IconButton>
            </Tooltip>
          )}
        </Badge>
      </Box>
    </Box>
  );
}
