import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import { DataGrid, GridRowId } from '@mui/x-data-grid';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import axios from 'axios';
import config from '../../config/config.json';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { RequireAuth } from 'components/Auth/RequireAuth';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { IconButton, Tooltip, Select, MenuItem, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneIcon from '@mui/icons-material/Done';
import { toast } from 'react-hot-toast';

interface Order {
  id: number;
  claimer_name: string;
  created_at: string;
  customer_email: string;
  customer_lastname: string;
  customer_name: string;
  d2r_shop_created_at: string;
  d2rs_order_id: string;
  d2rs_order_url: string;
  delivery_due_compensation: string;
  delivery_due_reward: string;
  sourcer_fg_due_reward: string;
  status: string;
  title: string;
  updated_at: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
  gridContainer: {
    height: '100%',
    width: '100%',
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
}));

export default function StaffOrderPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const sessionToken = localStorage.getItem('session_token');
  const queryClient = useQueryClient();
  useDocumentTitle(t('navigation.orders'));

  const [selectedOrders, setSelectedOrders] = useState<GridRowId[]>([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState<string>('');

  const updateOrderStatusMutation = useMutation({
    mutationFn: async ({ orderId, newStatus }: { orderId: number; newStatus: string }) => {
      const response = await axios.post(
        `${config.backend_base_url}d2rs/orders/${orderId}/status`,
        { status: newStatus },
        { headers: { 'session-token': sessionToken } }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      toast.success(t('orders.status_updated'));
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || t('common.error_occurred'));
    },
  });

  const { data: orders = [], isLoading: isLoadingOrders } = useQuery({
    queryKey: ['orders'],
    queryFn: async () => {
      const response = await axios.get<Order[]>(`${config.backend_base_url}d2rs/orders`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    enabled: !!sessionToken,
  });

  const filteredOrders = useMemo(() => {
    return {
      unclaimed: orders.filter((order) => order.status === 'unclaimed'),
      inProgress: orders.filter((order) => order.status === 'in_progress'),
      waitingForDelivery: orders.filter((order) => order.status === 'waiting_for_delivery'),
      delivered: orders.filter((order) => order.status === 'delivered'),
    };
  }, [orders]);

  if (!sessionToken) {
    return <Navigate to="/" replace />;
  }

  const handleStatusChange = (orderId: number, newStatus: string) => {
    updateOrderStatusMutation.mutate({ orderId, newStatus });
  };

  const getActionButtons = (params: any) => {
    const nextStatus = (() => {
      switch (params.row.status) {
        case 'unclaimed':
          return {
            status: 'in_progress',
            icon: <PlayArrowIcon />,
            tooltip: t('orders.start_processing'),
          };
        case 'in_progress':
          return {
            status: 'waiting_for_delivery',
            icon: <LocalShippingIcon />,
            tooltip: t('orders.mark_ready_for_delivery'),
          };
        case 'waiting_for_delivery':
          return {
            status: 'delivered',
            icon: <DoneIcon />,
            tooltip: t('orders.mark_delivered'),
          };
        default:
          return null;
      }
    })();

    if (!nextStatus) return null;

    return (
      <Tooltip title={nextStatus.tooltip}>
        <IconButton
          onClick={() => handleStatusChange(params.row.id, nextStatus.status)}
          size="small"
          color="primary"
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
        >
          {nextStatus.icon}
        </IconButton>
      </Tooltip>
    );
  };

  const orderColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'd2rs_order_id',
      headerName: 'D2RS Order ID',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'claimer_name',
      headerName: 'Claimed By',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'customer_lastname',
      headerName: 'Customer Last Name',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'customer_email',
      headerName: 'Customer Email',
      flex: 3,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'sourcer_fg_due_reward',
      headerName: 'Sourcing FG Due Reward',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {parseFloat(params.value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: 'delivery_due_reward',
      headerName: 'Delivery $ Due Reward',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {parseFloat(params.value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: 'delivery_due_compensation',
      headerName: 'Due Compensation',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {new Date(params.value).toLocaleString()}
        </div>
      ),
    },
    {
      field: 'd2r_shop_created_at',
      headerName: 'Shop Created At',
      flex: 2,
      renderCell: (params) => {
        const date = new Date(params.value);
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {date.getFullYear() === 1 ? '-' : date.toLocaleString()}
          </div>
        );
      },
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {new Date(params.value).toLocaleString()}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => <div>{getActionButtons(params)}</div>,
    },
  ];

  const OrderDataGrid = ({ orders }: { orders: Order[] }) => {
    const getNextStatus = (currentStatus: string) => {
      switch (currentStatus) {
        case 'unclaimed':
          return { value: 'in_progress', label: t('orders.start_processing') };
        case 'in_progress':
          return { value: 'waiting_for_delivery', label: t('orders.mark_ready_for_delivery') };
        case 'waiting_for_delivery':
          return { value: 'delivered', label: t('orders.mark_delivered') };
        default:
          return null;
      }
    };

    const currentStatus = orders[0]?.status || '';
    const nextStatus = getNextStatus(currentStatus);

    return (
      <GridContainer justify="center">
        {selectedOrders.length > 0 && nextStatus && (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer spacing={1} style={{ marginBottom: '10px' }}>
              <GridItem xs={12} sm={8} md={8}>
                <Select
                  value={selectedBulkAction}
                  onChange={(e) => setSelectedBulkAction(e.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {t('actions.selectBulkAction')}
                  </MenuItem>
                  <MenuItem value={nextStatus.value}>{nextStatus.label}</MenuItem>
                </Select>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Button
                  color="primary"
                  onClick={() => {
                    if (selectedBulkAction) {
                      selectedOrders.forEach((orderId) => {
                        handleStatusChange(Number(orderId), selectedBulkAction);
                      });
                      setSelectedOrders([]);
                      setSelectedBulkAction('');
                    }
                  }}
                  disabled={!selectedBulkAction}
                  fullWidth
                >
                  {t('actions.applyToSelected')} ({selectedOrders.length})
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
              autoHeight
              loading={isLoadingOrders}
              columns={orderColumns}
              rows={orders}
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              rowSelectionModel={selectedOrders}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedOrders(newSelection);
              }}
              sx={{
                '& .MuiDataGrid-root': {
                  width: '100%',
                },
                '& .MuiDataGrid-main': {
                  overflow: 'hidden',
                },
                '& .MuiDataGrid-columnHeaders': {
                  minHeight: '60px !important',
                  maxHeight: 'none !important',
                  lineHeight: '1.2 !important',
                  whiteSpace: 'normal',
                },
                '& .MuiDataGrid-columnHeader': {
                  height: 'auto !important',
                  whiteSpace: 'normal',
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    overflow: 'hidden',
                  },
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal !important',
                  padding: '8px',
                  minHeight: 'auto !important',
                  height: 'auto !important',
                  overflow: 'hidden !important',
                  '&:focus': {
                    outline: 'none',
                  },
                  '&:focus-within': {
                    outline: 'none',
                  },
                },
                '& .MuiDataGrid-row': {
                  maxHeight: 'none !important',
                  minHeight: 'auto !important',
                },
                '& .MuiDataGrid-renderCell': {
                  overflow: 'hidden !important',
                  whiteSpace: 'normal !important',
                  wordWrap: 'break-word !important',
                  display: 'block !important',
                },
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <RequireAuth requiredPermission="is_d2rs">
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <div className={classes.contentContainer}>
              <NavPills
                queryKey={'t'}
                alignCenter
                fullWidth
                onChange={() => {
                  setSelectedOrders([]);
                  setSelectedBulkAction('');
                }}
                color="primary"
                tabs={[
                  {
                    tabName: 'unclaimed',
                    tabButton: `Unclaimed (${filteredOrders.unclaimed.length})`,
                    tabContent: <OrderDataGrid orders={filteredOrders.unclaimed} />,
                  },
                  {
                    tabName: 'in_progress',
                    tabButton: `In Progress (${filteredOrders.inProgress.length})`,
                    tabContent: <OrderDataGrid orders={filteredOrders.inProgress} />,
                  },
                  {
                    tabName: 'waiting_for_delivery',
                    tabButton: `Waiting for Delivery (${filteredOrders.waitingForDelivery.length})`,
                    tabContent: <OrderDataGrid orders={filteredOrders.waitingForDelivery} />,
                  },
                  {
                    tabName: 'delivered',
                    tabButton: `Delivered (${filteredOrders.delivered.length})`,
                    tabContent: <OrderDataGrid orders={filteredOrders.delivered} />,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <BubbleContainer pageKey="staffOrderPage" helpText="todo: add help text" />
      </div>
    </RequireAuth>
  );
}
