import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import config from '../../config/config.json';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import FormControl from '@mui/material/FormControl';
import { IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styles from 'assets/jss/material-kit-react/views/profilePage.js';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
  },
  container: {
    ...styles.container,
    position: 'relative',
    zIndex: 3,
  },
  title: {
    display: 'inline-block',
    color: theme.palette.text.primary,
  },
  contentContainer: {
    width: '80vw',
    maxWidth: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    zIndex: 1100,
    '& .MuiTabs-root': {
      zIndex: 1200,
    },
    '& .MuiTab-root': {
      zIndex: 1200,
    },
  },
  flexCenter: {
    justifyContent: 'center',
    display: 'flex',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
    '& .MuiDataGrid-root': {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      },
    },
  },
  link: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

interface DueData {
  name: string;
  due_link: string;
  due_amount: number;
  current_credit: number;
  max_due_allowed: number;
  fg_due_amount: number;
  paypal_link: string;
  forum_link?: string;
  mule_game_name?: string;
  mule_game_password?: string;
}

interface DueBooking {
  id: number;
  amount: number;
  currency: string;
  comment: string;
  created_at: string;
  status: string;
}

interface IssueData {
  id: number;
  comment: string;
  created_at: string;
}

interface NodeData {
  id: number;
  node_id: number;
  node_name: string;
  can_drop: string;
  can_mule: string;
}

export default function ProfilePage(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.profile'));

  const sessionToken = localStorage.getItem('session_token') || '';

  const [muleGameName, setMuleGameName] = useState<string>('');
  const [muleGamePassword, setMuleGamePassword] = useState<string>('');

  const [bookDueAmount, setBookDueAmount] = useState<string>('');
  const [bookDueComment, setBookDueComment] = useState<string>('');

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [data, setData] = useState<DueData>({
    name: '',
    due_link: '',
    due_amount: 0,
    current_credit: 0,
    max_due_allowed: 0,
    fg_due_amount: 0,
    paypal_link: '',
  });
  const [dueBookings, setDueBookings] = useState<DueBooking[]>([]);

  const dueBookingsColumns: GridColDef[] = [
    {
      field: 'amount',
      headerName: t('due.columns.amount'),
      flex: 4,
    },
    {
      field: 'currency',
      headerName: t('due.columns.currency'),
      flex: 1,
    },
    {
      field: 'comment',
      headerName: t('due.columns.comment'),
      flex: 6,
    },
    {
      field: 'created_at',
      headerName: t('due.columns.createdAt'),
      flex: 3,
    },
    {
      field: 'status',
      headerName: t('due.columns.status'),
      flex: 2,
    },
  ];

  const [dueSubmitted, setDueSubmitted] = useState<boolean>(false);

  const [issuesData, setIssuesData] = useState<IssueData[]>([]);
  const issuesColumns: GridColDef[] = [
    {
      field: 'comment',
      headerName: t('due.columns.comment'),
      flex: 6,
    },
    {
      field: 'created_at',
      headerName: t('due.columns.createdAt'),
      flex: 1,
    },
  ];

  const [myNodesData, setMyNodesData] = useState<NodeData[]>([]);
  const myNodesDataColumns: GridColDef[] = [
    {
      field: 'node_id',
      headerName: t('due.columns.nodeId'),
      flex: 6,
    },
    {
      field: 'node_name',
      headerName: t('due.columns.nodeName'),
      flex: 6,
    },
    {
      field: 'can_drop',
      headerName: t('due.columns.canDrop'),
      flex: 6,
    },
    {
      field: 'can_mule',
      headerName: t('due.columns.canMule'),
      flex: 6,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderCellFuncActionList,
    },
  ];

  function renderCellFuncActionList(params: any) {
    const removeSelfFunc = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      const api = params.api;
      const thisRow: { [key: string]: any } = {};

      api
        .getAllColumns()
        .filter((c: any) => c.field !== '__check__' && !!c)
        .forEach((c: any) => (thisRow[c.field] = params.row[c.field]));
      removeSelfFromNode(thisRow.node_id);
    };

    return (
      <IconButton size="small" onClick={removeSelfFunc} title={t('due.actions.remove')}>
        <span>❌</span>
      </IconButton>
    );
  }

  function removeSelfFromNode(nodeID: number) {
    const url = config.backend_base_url + 'ml/nodeaccess/remove';
    const comment = window.prompt(t('due.actions.enterReason'), '');
    if (comment === '') {
      return;
    }
    axios
      .post(
        url,
        {
          node_id: nodeID,
          comment: comment,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          getMyNodesData();
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  const [allowedCurrencies] = useState<string[]>(['fg', '$']);
  const defaultCurrency = 'fg';
  const [currency, setCurrency] = useState<string>(defaultCurrency);

  async function bookDue() {
    const url = config.backend_base_url + 'book/due';
    await axios
      .post(
        url,
        {
          amount: bookDueAmount,
          currency: currency,
          comment: bookDueComment,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then(() => {
        setDueSubmitted(true);
        toast.success('Booking created for review.');
        getProfile();
        getDueBookings();
      })
      .catch(() => {
        toast.error('Error occurred.');
      });
  }

  async function getIssuesData() {
    const url = config.backend_base_url + 'ml/issues/mine';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setIssuesData(response.data);
        } else {
          setIssuesData([]);
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  async function getMyNodesData() {
    const url = config.backend_base_url + 'ml/nodeaccess/mine';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setMyNodesData(response.data);
        } else {
          setMyNodesData([]);
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  async function getProfile() {
    await axios
      .get(config.backend_base_url + 'profile', {
        headers: { 'session-token': sessionToken },
      })
      .then((response) => {
        if (response.data != null) {
          setData(response.data);
          if (response.data['mule_game_name']) {
            if (muleGameName === '') {
              setMuleGameName(response.data['mule_game_name']);
            }
          }
          if (response.data['mule_game_password']) {
            if (muleGamePassword === '') {
              setMuleGamePassword(response.data['mule_game_password']);
            }
          }
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  async function getAdminSettings() {
    const url = config.backend_base_url + 'admin/settings';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          if (response.data['mule_game_name']) {
            if (muleGameName === '') {
              setMuleGameName(response.data['mule_game_name']);
            }
          }
          if (response.data['mule_game_password']) {
            if (muleGamePassword === '') {
              setMuleGamePassword(response.data['mule_game_password']);
            }
          }
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  async function getDueBookings() {
    await axios
      .get(config.backend_base_url + 'duebookings/mine', {
        headers: { 'session-token': sessionToken },
      })
      .then((response) => {
        if (response.data != null) {
          setDueBookings(response.data);
        }
      })
      .catch(() => {
        toast.error('Error occurred. Please refresh the page');
      });
  }

  if (!sessionToken) {
    return <Navigate to="/" replace />;
  }

  if (loadingData) {
    getDueBookings();
    getProfile();
    getIssuesData();
    getMyNodesData();
    getAdminSettings();
    setLoadingData(false);
  }

  return (
    <div>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <NavPills
              queryKey={'t'}
              alignCenter
              fullWidth
              onChange={() => {}}
              color="primary"
              tabs={[
                {
                  tabName: 'details',
                  tabButton: t('due.details'),
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.flexCenter}>
                          <h3 className={classNames(classes.title, styles.title)}>
                            {t('common.hello')}, {data.name}!
                          </h3>
                        </div>
                        <div className={classes.flexCenter}>
                          <h4>
                            <a
                              className={classes.link}
                              target="_blank"
                              href={data.paypal_link}
                              rel="noreferrer"
                            >
                              {data.current_credit} $ {t('due.credit')}{' '}
                            </a>
                          </h4>
                        </div>
                        <div className={classes.flexCenter}>
                          <h4>
                            <a
                              className={classes.link}
                              target="_blank"
                              href={data.paypal_link}
                              rel="noreferrer"
                            >
                              {data.due_amount} {t('due.dollarDue')}{' '}
                            </a>
                          </h4>
                        </div>
                        <div className={classes.flexCenter}>
                          <h4>
                            <a
                              className={classes.link}
                              target="_blank"
                              href={data.due_link}
                              rel="noreferrer"
                            >
                              {data.fg_due_amount} {t('due.fgDue')}{' '}
                            </a>
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'bookings',
                  tabButton: t('due.bookings') + ' (' + dueBookings.length + ')',
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl id="adminBookDueForm" fullWidth>
                          <Select
                            id="currency"
                            value={currency}
                            onChange={(params: SelectChangeEvent<string>) => {
                              setCurrency(params.target.value);
                            }}
                          >
                            {allowedCurrencies.map((cur, key) => (
                              <MenuItem value={cur} key={key} selected={key === 0}>
                                {cur}
                              </MenuItem>
                            ))}
                          </Select>
                          {currency === 'fg' && (
                            <CustomInput
                              labelText={t('due.goldLog')}
                              id="goldlog"
                              value={bookDueComment}
                              onChange={(params: any) => {
                                setBookDueComment(params.target.value);
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                          )}
                          {currency === '$' && (
                            <CustomInput
                              labelText={t('due.amount')}
                              id="bookDueAmount"
                              value={bookDueAmount}
                              onChange={(params: any) => {
                                setBookDueAmount(params.target.value);
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                          )}
                          {currency === '$' && (
                            <CustomInput
                              labelText={t('due.comment')}
                              id="bookDueComment"
                              value={bookDueComment}
                              onChange={(params: any) => {
                                setBookDueComment(params.target.value);
                              }}
                              inputProps={{
                                type: 'text',
                              }}
                              required
                            />
                          )}
                          <Button
                            disabled={dueSubmitted || bookDueComment === ''}
                            color="primary"
                            type="submit"
                            form="adminBookDueForm"
                            onClick={bookDue}
                          >
                            {t('due.bookDue')}
                          </Button>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                          <DataGrid
                            autoHeight
                            getRowId={(row) => row.id}
                            columns={dueBookingsColumns}
                            rows={dueBookings}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 10,
                                  page: 0,
                                },
                              },
                            }}
                            pageSizeOptions={[10]}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'issues',
                  tabButton: 'Issues (' + issuesData.length + ')',
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                          <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                              <DataGrid
                                autoHeight
                                getRowId={(row) => row.id}
                                columns={issuesColumns}
                                rows={issuesData}
                                initialState={{
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 10,
                                      page: 0,
                                    },
                                  },
                                }}
                                pageSizeOptions={[10]}
                              />
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'nodes',
                  tabButton: t('due.myNodes') + ' (' + myNodesData.length + ')',
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.gridContainer}>
                          <div className={classes.dataGridContainer}>
                            <DataGrid
                              autoHeight
                              getRowId={(row) => row.id}
                              columns={myNodesDataColumns}
                              rows={myNodesData}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 100,
                                    page: 0,
                                  },
                                },
                                columns: {
                                  columnVisibilityModel: {
                                    node_id: false,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 25, 50, 100]}
                            />
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <BubbleContainer pageKey="duePage" helpText={t('help.duePage')} />
    </div>
  );
}
