import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import CustomInput from '../../components/CustomInput/CustomInput';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import blueIcon from '../../assets/img/blue_icon.webp';
import yellowIcon from '../../assets/img/yellow_icon.png';

import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Close from '@mui/icons-material/Close';
import config from '../../config/config.json';
import NavPills from '../../components/NavPills/NavPills';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { default as Autotextfield } from '@mui/material/TextField/TextField';
import { RequireAuth } from 'components/Auth/RequireAuth';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
const useStyles = makeStyles(styles);
export default function RareItemPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.prescreening'));

  const sessionToken = localStorage.getItem('session_token');
  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [mlSelection, setMLSelection] = useState([]);
  const [addRarityFilterID, setAddRarityFilterID] = useState('0');
  const [addTypeFilterID, setAddTypeFilterID] = useState('0');
  const [addNewItemsFilterID, setAddNewItemsFilterID] = useState('0');
  const [addEthItemsFilterID, setAddEthItemsFilterID] = useState('0');
  const [unidFilterID, setUnidFilterID] = useState('0');
  const [limitFilter, setLimitFilter] = useState(10000);
  const [addStatFilterID, setAddStatFilterID] = useState('0');
  const [addStatFilterMin, setAddStatFilterMin] = useState(1);
  const [addStatFilterMax, setAddStatFilterMax] = useState(10000);

  const [oldCheck, setOldCheck] = useState(addStatFilterID);
  React.useEffect(() => {
    if (oldCheck !== addStatFilterID) {
      setOldCheck(addStatFilterID);
      addStatRequirementForSearch();
    }
  });
  const [searchStatFilters, setSearchStatFilters] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [searchNewItemsFilters] = useState([
    {
      id: 1,
      name: 'New Items only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude New Items',
      selected: false,
    },
  ]);
  const [searchEthItemsFilters] = useState([
    {
      id: 1,
      name: 'Eth Items Only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude Eth Items',
      selected: false,
    },
  ]);
  const [searchUnidItemsFilters] = useState([
    {
      id: 1,
      name: 'Unid Items Only',
      selected: false,
    },
    {
      id: 2,
      name: 'Exclude Unid Items',
      selected: false,
    },
  ]);

  const [itemData, setItemData] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  function clearSearch() {
    setSearchTerm('');
    setAddRarityFilterID(0);
    setAddTypeFilterID(0);
    setAddNewItemsFilterID(0);
    setAddEthItemsFilterID(0);
    setUnidFilterID(0);
    setLimitFilter(1000);
    setSearchStatFilters([]);
    setItemData([]);
  }
  const [savedSearchSent, setSavedSearchSent] = useState(false);
  const [oldSavedSearchSent, setOldSavedSearchSent] = useState(false);
  React.useEffect(() => {
    if (oldSavedSearchSent !== savedSearchSent) {
      setOldSavedSearchSent(savedSearchSent);
      getItemsSearch();
    }
  });

  function onStatFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddStatFilterID(values.id);
  }

  function onTypeFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddTypeFilterID(values.id);
  }

  function onRarityFilterChange(event, values) {
    event.preventDefault();
    if (!values) {
      return;
    }
    setAddRarityFilterID(values.id);
  }

  function activateSelectedAsSearch(searchTerm) {
    setSearchTerm(searchTerm);
    setSavedSearchSent(!savedSearchSent);
  }
  async function getSavedSearches() {
    let url = config.backend_base_url + 'ml/search/mine';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setSavedSearches(response.data);
        } else {
          setSavedSearches([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  function getItemsSearch() {
    let url = config.backend_base_url + 'rares/search';
    const loading = toast.loading(
      'Loading data.. this can take a minute, please wait for this notification to disappear'
    );
    axios
      .post(
        url,
        {
          search_term: searchTerm,
          rarity_filter_id: parseInt(addRarityFilterID),
          type_filter_id: parseInt(addTypeFilterID),
          new_items_filter: parseInt(addNewItemsFilterID),
          eth_items_filter: parseInt(addEthItemsFilterID),
          unid_filter: parseInt(unidFilterID),
          stat_filters: searchStatFilters,
          limit: parseInt(limitFilter),
          only_own_stock: 0,
          include_unpriced_items: 1,
          include_uncartable_items: 1,
          include_listed_items: 1,
          prescreen: 1,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          toast.dismiss(loading);
          toast.success('Search successful [' + response.data.length + ']');
          setItemData(response.data);
        } else {
          toast.dismiss(loading);
          toast.error('Search found no matches');
          setItemData([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemStats() {
    let url = config.backend_base_url + 'stats/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemStats(response.data);
        } else {
          setItemStats([]);
        }
        setLoadingItems(false);
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemRarities() {
    let url = config.backend_base_url + 'rarities/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemRarities(response.data);
        } else {
          setItemRarities([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  async function getItemTypes() {
    let url = config.backend_base_url + 'categories/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemTypes(response.data);
        } else {
          setItemTypes([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured. Please refresh the page');
      });
  }

  if (loadingItems) {
    getItemsSearch();
    getItemStats();
    getItemRarities();
    getItemTypes();
    getSavedSearches();
    setLoadingItems(false);
  }

  const [activeItem, setActiveItem] = useState({
    id: 1,
    name: 'Selected Item Name',
    image_path: 'https://d2r-member.com/image/' + 'JAH_RUNE.png',
    skin_image_path: 'https://d2r-member.com/skin/' + 'JAH_RUNE.png',
  });

  const [itemStats, setItemStats] = useState([]);
  const [itemRarities, setItemRarities] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);

  function getColorFromRarity(rarName) {
    const textColorMap = {
      WHITE: '#FFFFFF',
      SET: '#00FF00',
      MAGIC: '#4169E1',
      UNIQUE: '#A59263',
      CRAFTED: '#FFA800',
      RARE: '#FFFF00',
    };

    return textColorMap[rarName.toUpperCase()] || '#FFFFFF';
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'note',
      headerName: 'Note',
    },
    {
      field: 'min_price',
      headerName: 'Minimum Price',
    },
    {
      field: 'name',
      headerName: 'Item Name',
      flex: 6,
      renderCell: (params) => {
        const skinpath = config.backend_base_url + 'skin/' + params.row.skin_image_path;
        const path = config.backend_base_url + 'image/' + params.row.image_path;
        const rarityColor = getColorFromRarity(params.row.original_rarity);
        return (
          <Tooltip
            title={
              <div>
                {params.value}
                <br />
                <img
                  src={path}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.value}
                />
              </div>
            }
            arrow
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  marginRight: '4px',
                  backgroundColor: rarityColor,
                }}
              />
              <img
                style={{ maxHeight: '32px', maxWidth: '32px', marginRight: '4px' }}
                src={skinpath}
                alt={''}
              />
              {params.row.name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'stats_description',
      headerName: 'Stats',
      flex: 14,
      renderCell: (params) => {
        const path = config.backend_base_url + 'image/' + params.row.image_path;
        return (
          <Tooltip
            title={
              <div>
                {params.value}
                <br />
                <img
                  src={path}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt={params.row.name}
                />
              </div>
            }
            arrow
          >
            <div>{params.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'shareholder_name',
      headerName: 'Owner',
      flex: 3,
    },
    {
      field: 'reseller_name',
      headerName: 'Reseller',
      flex: 3,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 3,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 3,
    },
    {
      field: 'fg_price',
      headerName: 'Fg price',
    },
    {
      field: 'fiat_price',
      headerName: '$ price',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      displayName: 'Actions',
      renderCell: renderCellFuncActionList,
    },
    {
      field: 'shop_action_btn',
      headerName: 'Shop Actions',
      flex: 1,
      displayName: 'Shop Actions',
      renderCell: renderCellFuncShopActionList,
    },
    {
      field: 'prescreen_action_btn',
      headerName: 'Prescreen',
      flex: 1,
      renderCell: renderCellFuncPrescreenAction,
    },
  ];

  function addNoteToItem(itID, note) {
    let url = config.backend_base_url + 'note/add';
    if (note === '') {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
          note: note,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Added note:' + note);
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  function addMinPriceToItem(itID, minPrice) {
    let url = config.backend_base_url + 'minprice/add';
    if (minPrice === '') {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    if (isNaN(minPrice)) {
      toast.error('Price needs to be a valid whole number');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
          min_price: minPrice,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Added minimum price:' + minPrice);
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }

  let sendSelectedTrashItems = function (itemIDs) {
    axios
      .post(
        config.backend_base_url + 'trash/add',
        {
          item_ids: itemIDs,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        toast.success('Marked items as trash');
        getItemsSearch();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  let sendRemoveItemsFunc = function (ids) {
    axios
      .post(
        config.backend_base_url + 'cart/remove',
        {
          item_ids: ids,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.status);
        getItemsSearch();
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occured.');
      });
  };

  function addPrescreenToItem(itID) {
    let url = config.backend_base_url + 'prescreen/release';

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Released item from prescreening');
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.message);
      });
  }
  function retakePicFunc(itID) {
    let url = config.backend_base_url + 'pic/generate';
    const loadingT = toast.loading('generating picture..');
    axios
      .post(
        url,
        {
          item_id: itID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.dismiss(loadingT);
          toast.success('picture successfully generated. rehover to see the result');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loadingT);
        toast.error(error.message);
      });
  }

  function renderCellFuncActionList(params) {
    const trashItems = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      sendSelectedTrashItems([params.row.id]);
    };

    const cart = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      sendSelectedItems([params.row.id]);
    };

    const addNote = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt('Enter the note you want to add to the item');
      if (!resp) {
        return;
      }
      addNoteToItem(params.row.id, resp);
    };

    const addMinPrice = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt(
        'Enter the minimum price you want to add to the item. Currently that price will not be enforced, but it will be visible to sellers'
      );
      if (!resp) {
        return;
      }
      addMinPriceToItem(params.row.id, resp);
    };

    const removeFromCart = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      if (params.row.status !== 'in_cart') {
        toast.error("Can only remove items with 'in_cart' status");
        return;
      }
      sendRemoveItemsFunc([params.row.id]);
    };

    const retakePic = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      retakePicFunc(params.row.id);
    };

    let infoText = 'ID: ' + params.row.id;
    infoText += '<br/>  Note: ' + params.row.note;
    infoText += '<br/>  Minimum Price: ' + params.row.min_price + ' fg';
    infoText += '<br/>  Status: ' + params.row.status;
    const path = config.backend_base_url + 'image/' + params.row.image_path;

    let trashD = (
      <Tooltip
        title={
          <div>
            Mark this item as TRASH
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={trashItems}>🗑️</div>
      </Tooltip>
    );

    let noteD = (
      <Tooltip
        title={
          <div>
            Add a note to this item
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addNote}>✍️</div>
      </Tooltip>
    );

    let minPriceD = (
      <Tooltip
        title={
          <div>
            Add a minimum price to this item
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addMinPrice}>🏷️</div>
      </Tooltip>
    );

    let infoD = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: infoText }} />
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div>🇮</div>
      </Tooltip>
    );

    let cartD = (
      <Tooltip
        title={
          <div>
            Add this item to cart
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={cart}>🛒</div>
      </Tooltip>
    );

    let removeFromCartD = (
      <Tooltip
        title={
          <div>
            Remove Item from Cart
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={removeFromCart}>❌</div>
      </Tooltip>
    );

    let picD = (
      <Tooltip
        title={
          <div>
            Generate picture
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={retakePic}>🖼️</div>
      </Tooltip>
    );

    const viewD = (
      <Tooltip title={'VIEW this item on this nodes shop'} arrow>
        <a
          href={params.row.d2rshopproductlink}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={yellowIcon} alt="View on shop" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    const viewD2rsD = (
      <Tooltip title={'VIEW this item on D2RS'} arrow>
        <a
          href={`/i/${params.row.id}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={blueIcon} alt="View on D2RS" style={{ width: '16px', height: '16px' }} />
        </a>
      </Tooltip>
    );

    return (
      <span>
        {infoD}
        {picD}
        {noteD}
        {params.row.status !== 'sold' && params.row.status !== 'in_drop_order' && minPriceD}
        {params.row.marked_as_trash === 0 &&
          params.row.status !== 'sold' &&
          params.row.status !== 'in_drop_order' &&
          params.row.d2rshopproductlink === '' &&
          trashD}
        {params.row.status === 'available' && cartD}
        {params.row.status === 'in_cart' && removeFromCartD}
        {params.row.d2rshopproductlink !== '' && viewD}
        {viewD2rsD}
      </span>
    );
  }

  function renderCellFuncShopActionList(params) {
    const list = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt(
        'Enter the price of this item in $. !CAREFUL! After pressing OK, the item will be listed on SHOP for the price you entered.'
      );
      if (!resp) {
        return;
      }
      updateListingPrice(params.row.id, resp, 'listing');
    };
    const update = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      const resp = window.prompt(
        'Enter the updated price of this item. !CAREFUL! After pressing OK, the price change will be published on SHOP'
      );
      if (!resp) {
        return;
      }
      updateListingPrice(params.row.id, resp, 'updating');
    };
    const delist = (e) => {
      e.stopPropagation(); // don't select this row after clicking

      if (
        !window.confirm('!CAREFUL! After pressing OK, the item will be deleted on SHOP. Continue?')
      ) {
        return;
      }
      deleteListing(params.row.id);
    };

    let path = config.backend_base_url + 'image/' + params.row.image_path;

    let deleteD = (
      <Tooltip
        title={
          <div>
            DELETE this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={delist}>🇩</div>
      </Tooltip>
    );
    let updateD = (
      <Tooltip
        title={
          <div>
            UPDATE this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={update}>🇺</div>
      </Tooltip>
    );
    let listD = (
      <Tooltip
        title={
          <div>
            LIST this item on this nodes shop
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={list}>💱</div>
      </Tooltip>
    );

    const viewD = (
      <Tooltip title={'VIEW this item on this nodes shop'} arrow>
        <a
          href={params.row.d2rshopproductlink}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none', color: 'inherit' }}
          onClick={(e) => e.stopPropagation()}
        >
          👁️‍
        </a>
      </Tooltip>
    );
    return (
      <span>
        {(params.row.status === 'available' || params.row.status === 'in_cart') &&
          params.row.d2rshopproductlink === '' &&
          listD}
        {params.row.d2rshopproductlink !== '' && updateD}
        {params.row.d2rshopproductlink !== '' && deleteD}
        {params.row.d2rshopproductlink !== '' && viewD}
      </span>
    );
  }

  function renderCellFuncPrescreenAction(params) {
    const addPrescreen = (e) => {
      e.stopPropagation(); // don't select this row after clicking
      addPrescreenToItem(params.row.id);
    };

    const path = config.backend_base_url + 'image/' + params.row.image_path;

    let prescreenD = (
      <Tooltip
        title={
          <div>
            Release item from prescreening
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={addPrescreen}>👌</div>
      </Tooltip>
    );

    return <span>{prescreenD}</span>;
  }

  function getItemsSearchByEnter(event) {
    if (event.key === 'Enter') {
      getItemsSearch();
    }
  }

  let cellClick = function (params, event) {
    event.defaultMuiPrevented = true;
    setActiveItem({
      id: params.row.id,
      name: params.row.name,
      image_path: 'https://d2r-member.com/image/' + params.row.image_path,
      skin_image_path: 'https://d2r-member.com/skin/' + params.row.skin_image_path,
    });
  };

  let addStatRequirementForSearch = function () {
    let statName = '';

    itemStats.forEach(function (it) {
      if (it.id === addStatFilterID) {
        statName = it.name;
      }
    });

    setSearchStatFilters(
      searchStatFilters.concat({
        id: addStatFilterID,
        name: statName,
        min: parseInt(addStatFilterMin),
        max: parseInt(addStatFilterMax),
      })
    );
  };

  function updateListingPrice(itID, newPrice, action) {
    let url = config.backend_base_url + 'd2rs/listing/update';
    if (isNaN(newPrice)) {
      toast.error('Price Input is not a number');
      return;
    }
    if (newPrice === '' || newPrice === null) {
      return;
    }

    if (isNaN(itID)) {
      toast.error('Faulty ID');
      return;
    }

    const loadingT = toast.loading(
      action + ' product on SHOP, this can take a moment. Please wait for the next notification...'
    );
    axios
      .post(
        url,
        {
          item_id: itID,
          new_price: newPrice,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.dismiss(loadingT);
          toast.success(
            'Successfully ' + action + ' item on SHOP! It can take a few minutes to be visible.'
          );
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.dismiss(loadingT);
        toast.error(error.message);
      });
  }

  function deleteListing(itemID) {
    if (isNaN(itemID)) {
      toast.error('Faulty ID');
      return;
    }

    let url = config.backend_base_url + 'd2rs/listing/delete';
    axios
      .post(
        url,
        {
          item_id: itemID,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Success!');
          getItemsSearch();
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(error.response.data['Message']);
      });
  }

  function sendSelectedItems(itemIDs) {
    axios
      .post(
        config.backend_base_url + 'cart/add',
        {
          item_ids: itemIDs,
          suffix: '',
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        console.log('Success ========>', response.data);
        getItemsSearch();
        toast.success('Added to cart!');
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error('Error occurred while adding to cart');
      });
  }

  return (
    <RequireAuth requiredPermission="can_prescreen">
      <div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div
            className={classes.container}
            style={{
              width: '80vw',
              maxWidth: '80vw',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
            }}
          >
            <NavPills
              queryKey={'t'}
              alignCenter
              fullWidth
              onChange={function () {}}
              color="primary"
              tabs={[
                {
                  tabName: 'prescreening',
                  tabButton: 'Prescreening (' + itemData.length + ')',
                  tabContent: (
                    <GridContainer spacing={1}>
                      <GridItem md={2} xl={2} style={{ paddingTop: 10 }}>
                        <Autocomplete
                          id="savedSearchesAutocomplete"
                          options={savedSearches}
                          getOptionLabel={(option) =>
                            '' + option.search_count + 'x ' + option.search_name
                          }
                          renderInput={(params) => (
                            <Autotextfield
                              {...params}
                              label="Search For Saved Searches and choose one to activate it.."
                            />
                          )}
                          onChange={(event, selectedOption) =>
                            activateSelectedAsSearch(selectedOption.search_term)
                          }
                        />
                      </GridItem>
                      <GridItem md={1} xl={1}>
                        <CustomInput
                          labelText="Stat Min Value"
                          id="minValue"
                          value={addStatFilterMin}
                          onChange={(params) => {
                            setAddStatFilterMin(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem md={1} xl={1}>
                        <CustomInput
                          labelText="Stat Max Value"
                          id="maxValue"
                          value={addStatFilterMax}
                          onChange={(params) => {
                            setAddStatFilterMax(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem md={2} xl={2} style={{ paddingTop: 10 }}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemStats}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Stat Filter Search" />
                          )}
                          onChange={onStatFilterChange}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemRarities}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Rarity Filter" />
                          )}
                          onChange={onRarityFilterChange}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                        <Autocomplete
                          id="searchForStatAutocomplete"
                          options={itemTypes}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <Autotextfield {...params} label="Type Filter" />
                          )}
                          onChange={onTypeFilterChange}
                        />
                      </GridItem>
                      <GridItem md={1} xl={1} style={{ paddingTop: 10 }}>
                        <Select
                          id="newItemsFilter"
                          fullWidth={true}
                          value={addNewItemsFilterID}
                          onChange={(params) => {
                            setAddNewItemsFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>New Filter</MenuItem>
                          {searchNewItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem md={1} xl={1} style={{ paddingTop: 10 }}>
                        <Select
                          id="unidItemsFilter"
                          fullWidth={true}
                          value={unidFilterID}
                          onChange={(params) => {
                            setUnidFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>Unid Filter</MenuItem>
                          {searchUnidItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1} xl={1} style={{ paddingTop: 10 }}>
                        <Select
                          id="ethItemsFilter"
                          fullWidth={true}
                          value={addEthItemsFilterID}
                          onChange={(params) => {
                            setAddEthItemsFilterID(params.target.value);
                          }}
                        >
                          <MenuItem value={'0'}>Eth Filter</MenuItem>
                          {searchEthItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1} xl={1}>
                        <CustomInput
                          labelText="Search Limit..."
                          id="limitFilter"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={limitFilter}
                          onChange={(params) => {
                            setLimitFilter(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                            autoComplete: 'off',
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        {searchStatFilters.map((filter, key) => (
                          <Button
                            onClick={() =>
                              setSearchStatFilters(
                                searchStatFilters.filter(function (it) {
                                  return it.id !== filter.id;
                                })
                              )
                            }
                            key={key}
                            color="primary"
                            size="sm"
                          >
                            <div style={{ contentAlign: 'center' }}>
                              <Close></Close>
                              {filter.name}: {filter.min} - {filter.max}
                            </div>
                          </Button>
                        ))}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} xl={6}>
                        <CustomInput
                          labelText="Search Term..."
                          id="searchTerm"
                          value={searchTerm}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onKeyDown={getItemsSearchByEnter}
                          onChange={(params) => {
                            setSearchTerm(params.target.value);
                          }}
                          inputProps={{
                            type: 'text',
                            endAdornment: (
                              <InputAdornment position="end">
                                <Search className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                        <Button
                          color="primary"
                          fullWidth={true}
                          type="submit"
                          form="searchParamsForm"
                          onClick={getItemsSearch}
                        >
                          Get Search Results ({itemData.length})
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} xl={3} style={{ paddingTop: 10 }}>
                        <Button
                          color="danger"
                          fullWidth={true}
                          type="submit"
                          form="searchParamsForm"
                          onClick={clearSearch}
                        >
                          ❌Clear Search
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                          <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            getRowWidth={() => 'auto'}
                            columns={columns}
                            rows={itemData}
                            pageSize={100}
                            rowsPerPageOptions={[10]}
                            onCellClick={cellClick}
                            checkboxSelection
                            selectionModel={mlSelection}
                            onRowSelectionModelChange={(params) => {
                              setMLSelection(params);
                            }}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  id: false,
                                  min_price: false,
                                  ocr_string: false,
                                  skin_image_path: false,
                                },
                              },
                            }}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} xl={12}>
                        <div>
                          <b>{activeItem.name}</b>
                        </div>
                        <img
                          src={activeItem.skin_image_path}
                          style={{ border: '1px solid black' }}
                          alt={activeItem.name}
                        />
                        <img
                          src={activeItem.image_path}
                          style={{ border: '1px solid black' }}
                          alt={activeItem.name}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <BubbleContainer hideHelpBubble={true} />
      </div>
    </RequireAuth>
  );
}
