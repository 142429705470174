import axios from 'axios';
import config from '../config/config.json';
import { toast } from 'react-hot-toast';

interface UserProfile {
  name: string;
  d2rs_perms?: number;
  // Add other profile fields as needed
}

interface UserType {
  is_staff: number;
  is_node_owner: number;
  deploying: number;
  node_name?: string;
  allowed_nodes?: string[];
  ladder: number;
  hardcore: number;
  can_list: number;
}

interface UserPermissions {
  // Add permission fields as needed
  [key: string]: any;
}

class UserService {
  private static instance: UserService;
  private sessionToken: string | null = null;
  private userProfile: UserProfile | null = null;
  private userType: UserType | null = null;
  private userPermissions: UserPermissions | null = null;

  private constructor() {
    this.sessionToken = localStorage.getItem('session_token');
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public getSessionToken(): string | null {
    return this.sessionToken;
  }

  public isLoggedIn(): boolean {
    return !!this.sessionToken;
  }

  public async getProfile(): Promise<UserProfile | null> {
    if (!this.sessionToken) return null;

    if (this.userProfile) return this.userProfile;

    try {
      const response = await axios.get(config.backend_base_url + 'profile', {
        headers: { 'session-token': this.sessionToken },
      });

      if (response.data) {
        this.userProfile = response.data;
        return this.userProfile;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error('Error occurred while fetching user profile');
    }

    return null;
  }

  public async getUserType(): Promise<UserType | null> {
    if (!this.sessionToken) return null;

    if (this.userType) return this.userType;

    try {
      const response = await axios.get(config.backend_base_url + 'user/type', {
        headers: { 'session-token': this.sessionToken },
      });

      if (response.data) {
        this.userType = response.data;
        return this.userType;
      }
    } catch (error) {
      console.error('Error fetching user type:', error);
      toast.error('Error occurred while fetching user type');
    }

    return null;
  }

  public async getUserPermissions(): Promise<UserPermissions | null> {
    if (!this.sessionToken) return null;

    if (this.userPermissions) return this.userPermissions;

    try {
      const response = await axios.get(config.backend_base_url + 'users/permissions', {
        headers: { 'session-token': this.sessionToken },
      });

      if (response.data) {
        this.userPermissions = response.data;
        return this.userPermissions;
      }
    } catch (error) {
      console.error('Error fetching user permissions:', error);
      toast.error('Error occurred while fetching user permissions');
    }

    return null;
  }

  public async isStaff(): Promise<boolean> {
    const userType = await this.getUserType();
    return userType?.is_staff === 1;
  }

  public async isNodeOwner(): Promise<boolean> {
    const userType = await this.getUserType();
    return userType?.is_node_owner === 1;
  }

  public async isDeploying(): Promise<boolean> {
    const userType = await this.getUserType();
    return userType?.deploying === 1;
  }

  public async getCurrentNode(): Promise<string | undefined> {
    const userType = await this.getUserType();
    return userType?.node_name;
  }

  public async getAllowedNodes(): Promise<string[]> {
    const userType = await this.getUserType();
    return userType?.allowed_nodes || [];
  }

  public async isLadder(): Promise<boolean> {
    const userType = await this.getUserType();
    return userType?.ladder === 1;
  }

  public async isHardcore(): Promise<boolean> {
    const userType = await this.getUserType();
    return userType?.hardcore === 1;
  }

  public logout(): void {
    localStorage.clear();
    this.sessionToken = null;
    this.userProfile = null;
    this.userType = null;
    this.userPermissions = null;
    window.location.reload();
  }

  public clearCache(): void {
    this.userProfile = null;
    this.userType = null;
    this.userPermissions = null;
  }
}

export default UserService;
