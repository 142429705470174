import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-kit-react/views/profilePage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import config from '../../config/config.json';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import NavPills from '../../components/NavPills/NavPills';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import { MenuItem, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import UserService from '../../services/UserService';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import blueIcon from '../../assets/img/blue_icon.webp';
import yellowIcon from '../../assets/img/yellow_icon.png';

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function PresetPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.presetData'));
  const classes = useStyles();
  const [itemTypes, setItemTypes] = useState([]);
  const [itemRarities, setItemRarities] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const sessionToken = localStorage.getItem('session_token');
  const [canList, setCanList] = useState(false);

  if (!sessionToken) {
    return <Navigate to="/login" replace />;
  }

  const [customers, setCustomers] = useState([]);
  const [itemPacks, setItemPacks] = useState([]);
  const [file, setFile] = useState();
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  const handleTabChange = () => {};

  function handleSubmit(event) {
    event.preventDefault();
    const url = config.backend_base_url + 'ml/pack/create';
    const formData = new FormData();
    formData.append('create_packs', file);
    formData.append('create_packs_name', file.name);
    if (!file.name.includes('.json')) {
      toast.error(t('errors.invalidJsonFile'));
      return;
    }
    const rconfig = {
      headers: {
        'content-type': 'multipart/form-data',
        'session-token': sessionToken,
      },
    };
    axios.post(url, formData, rconfig).then((response) => {
      console.log(response);
      getUsersItemPacks();
    });
  }
  function handleCustomersSubmit(event) {
    event.preventDefault();
    const url = config.backend_base_url + 'ml/customers/create';
    const formData = new FormData();
    formData.append('create_customers', file);
    formData.append('create_customers_name', file.name);
    if (!file.name.includes('.json')) {
      toast.error(t('errors.invalidJsonFile'));
      return;
    }
    const rconfig = {
      headers: {
        'content-type': 'multipart/form-data',
        'session-token': sessionToken,
      },
    };
    axios.post(url, formData, rconfig).then((response) => {
      console.log(response);
      getUsersCustomers();
    });
  }
  const [searchApplied, setSearchApplied] = useState(false);
  const [itemStatsFilterModal, setItemStatsModal] = useState(false);
  const [addEntryModal, setAddEntryModal] = useState(false);

  const [addStatFilterID, setAddStatFilterID] = useState('0');
  const [addStatFilterMin, setAddStatFilterMin] = useState(1);
  const [addStatFilterMax, setAddStatFilterMax] = useState(999999);
  const [newEntryStatFilters, setNewEntryStatFilters] = useState([]);
  const [itemStats, setItemStats] = useState([]);

  const [searchEthItemsFilters] = useState([
    {
      id: 1,
      name: t('filters.ethItemsOnly'),
      selected: false,
    },
    {
      id: 2,
      name: t('filters.excludeEthItems'),
      selected: false,
    },
  ]);
  const [searchUnidItemsFilters] = useState([
    {
      id: 1,
      name: t('filters.unidItemsOnly'),
      selected: false,
    },
    {
      id: 2,
      name: t('filters.excludeUnidItems'),
      selected: false,
    },
  ]);

  function getUsersItemPacks() {
    //this redirects the user somewhere else if we are not an admin
    let url = config.backend_base_url + 'ml/pack/get';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setItemPacks(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }
  function getUsersCustomers() {
    //this redirects the user somewhere else if we are not an admin
    let url = config.backend_base_url + 'ml/customers/get';
    axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null) {
          setCustomers(response.data);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }
  async function checkUserPermissions() {
    const userService = UserService.getInstance();
    const userType = await userService.getUserType();
    if (userType?.can_list === 1) {
      setCanList(true);
      setDynamicPickitColumns(
        dynamicPickitBaseColumns.concat({
          field: 'shop',
          headerName: 'Shop',
          flex: 1,
          displayName: 'Shop',
          renderCell: renderShopActions,
        })
      );
    }
  }

  function renderActions(params) {
    let path = 'https://d2r-member.com/image/' + params.row.product_image_path;
    const onClick = (e) => {
      e.stopPropagation();
      const thisRow = params.row;
      updatePickitPrice(
        thisRow.id,
        thisRow.fg_price,
        thisRow.early_ladder_price,
        thisRow.mid_ladder_price,
        thisRow.late_ladder_price,
        thisRow.pickit_nip,
        thisRow.pickit_gid,
        thisRow.pickit_keke,
        thisRow.pickit_in,
        thisRow.keep_limit
      );
    };

    const updateD = (
      <Tooltip
        title={
          <div>
            Update
            <br />
            <img src={path} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div onClick={onClick}>🇺</div>
      </Tooltip>
    );

    return <span>{updateD}</span>;
  }

  function renderShopActions(params) {
    let historyText = t('history.id') + ': ' + params.row.id;
    historyText += '<br/>' + t('history.productName') + ': ' + params.row.shop_product_name;
    historyText += '<br/>' + t('history.currentPrice') + ': ' + params.row.fg_price;
    historyText += '<br/>' + t('history.earlyLadder') + ': ' + params.row.early_ladder_price;
    historyText += '<br/>' + t('history.midLadder') + ': ' + params.row.mid_ladder_price;
    historyText += '<br/>' + t('history.lateLadder') + ': ' + params.row.late_ladder_price;
    historyText += '<br/>' + t('history.productFactor') + ': ' + params.row.shop_price_factor;
    historyText += '<br/>' + t('history.keepLimit') + ': ' + params.row.keep_limit;
    historyText += '<br/>' + t('history.nipLine') + ': ' + params.row.pickit_nip;
    historyText +=
      '<br/>' +
      t('history.updatedAt') +
      ': ' +
      params.row.updated_at.toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    let path = 'https://d2r-member.com/image/' + params.row.product_image_path;

    const viewD = params.row.shop_product_link ? (
      <Button
        color="transparent"
        size="sm"
        href={params.row.shop_product_link}
        target="_blank"
        rel="noreferrer"
        style={{ minWidth: '32px', padding: '5px', marginBottom: '4px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <img src={yellowIcon} alt="View in Shop" style={{ width: '16px', height: '16px' }} />
      </Button>
    ) : null;

    const viewD2rsD = (
      <Button
        color="transparent"
        size="sm"
        href={`/p/${params.row.id}`}
        target="_blank"
        rel="noreferrer"
        style={{ minWidth: '32px', padding: '5px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <img src={blueIcon} alt="View on D2RS" style={{ width: '16px', height: '16px' }} />
      </Button>
    );

    const informationD = (
      <Tooltip
        title={
          <div>
            <span dangerouslySetInnerHTML={{ __html: historyText }} />
            <img src={path} alt={params.row.name} />
          </div>
        }
        arrow
      >
        <div>🇮</div>
      </Tooltip>
    );
    return (
      <span>
        {informationD}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          {viewD}
          {viewD2rsD}
        </div>
      </span>
    );
  }

  function renderCellFuncPriceFactor(params) {
    const onClick = (e) => {
      e.stopPropagation();
      const thisRow = params.row;
      updatePickitShopPriceFactor(thisRow.id, thisRow.shop_price_factor);
    };

    return (
      <Button color={'primary'} fullWidth={true} onClick={onClick}>
        {t('search.buttons.update')}
      </Button>
    );
  }
  renderCellFuncPriceFactor.displayName = 'Update';

  function renderCellFuncStatUpdate(params) {
    const onClick = (e) => {
      e.stopPropagation();
      const thisRow = params.row;
      updateStatRank(thisRow.id, thisRow.mlb_rank);
    };

    return (
      <Button color={'primary'} fullWidth={true} onClick={onClick}>
        {t('search.buttons.update')}
      </Button>
    );
  }
  renderCellFuncStatUpdate.displayName = 'Update';

  const [statColumns] = useState([
    {
      field: 'id',
      headerName: t('table.id'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: t('table.title'),
      flex: 2,
    },
    {
      field: 'mlb_rank',
      headerName: t('table.rank'),
      flex: 2,
      editable: true,
    },
    {
      field: 'update_btn',
      headerName: t('table.update'),
      flex: 2,
      displayName: t('table.update'),
      renderCell: renderCellFuncStatUpdate,
    },
  ]);

  const [dynamicPickitBaseColumns] = useState([
    {
      field: 'id',
      headerName: t('table.id'),
      flex: 1,
      displayName: t('table.hide'),
    },
    {
      field: 'title',
      headerName: t('table.title'),
      flex: 10,
      renderCell: renderTitle,
    },
    {
      field: 'fg_price',
      headerName: t('table.currentPrice'),
      flex: 2,
      editable: true,
    },
    {
      field: 'actions',
      headerName: t('table.actions'),
      flex: 1,
      displayName: t('table.update'),
      renderCell: renderActions,
    },
    {
      field: 'early_ladder_price',
      headerName: t('table.earlyLadderPrice'),
      flex: 2,
      editable: true,
    },
    {
      field: 'mid_ladder_price',
      headerName: t('table.midLadderPrice'),
      flex: 2,
      editable: true,
    },
    {
      field: 'late_ladder_price',
      headerName: t('table.lateLadderPrice'),
      flex: 2,
      editable: true,
    },
    {
      field: 'shop_price_factor',
      headerName: t('table.factor'),
      flex: 1,
      editable: true,
    },
    {
      field: 'pickit_nip',
      headerName: t('table.nipLine'),
      flex: 1,
      editable: true,
    },
    {
      field: 'pickit_gid',
      headerName: t('table.gidLine'),
      flex: 1,
      editable: true,
    },
    {
      field: 'pickit_keke',
      headerName: t('table.kekeLine'),
      flex: 1,
      editable: true,
    },
    {
      field: 'pickit_in',
      headerName: t('table.inLine'),
      flex: 1,
      editable: true,
    },
    {
      field: 'keep_limit',
      headerName: t('table.keepLimit'),
      flex: 1,
      editable: true,
    },
    {
      field: 'shop_product_link',
      headerName: t('table.link'),
      flex: 1,
      editable: false,
    },
  ]);

  function getColorFromRarity(rarName) {
    const textColorMap = {
      WHITE: '#FFFFFF',
      SET: '#00FF00',
      MAGIC: '#4169E1',
      UNIQUE: '#A59263',
      CRAFTED: '#FFA800',
      RARE: '#FFFF00',
    };

    return textColorMap[rarName.toUpperCase()] || '#FFFFFF';
  }

  function renderTitle(params) {
    let productimagepath = 'https://d2r-member.com/image/' + params.row.product_image_path;
    let skinpath = 'https://d2r-member.com/skin/' + params.row.skin_image_path;
    const rarityColor = getColorFromRarity('');

    return (
      <Tooltip title={<img src={productimagepath} alt={t('tooltips.itemImage')} />} arrow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              marginRight: '4px',
              backgroundColor: rarityColor,
            }}
          />
          <img
            style={{ maxHeight: '64px', maxWidth: '64px', marginRight: '4px' }}
            src={skinpath}
            alt={t('tooltips.itemSkin')}
          />
          {params.row.title}
        </div>
      </Tooltip>
    );
  }

  const [dynamicPickitColumns, setDynamicPickitColumns] = useState(dynamicPickitBaseColumns);
  const [dynamicPickitEntries, setDynamicPickitEntries] = useState([]);

  // const [sTitle, setSTitle] = useState("");
  const [bulkChangeValue, setBulkChangeValue] = useState(0);
  const [sTerm, setSTerm] = useState('');
  const [limitFilter, setLimitFilter] = useState(10000);
  const [addTypeFilterID, setAddTypeFilterID] = useState('0');
  const [addRarityFilterID, setAddRarityFilterID] = useState('0');
  const [addEthItemsFilterID, setAddEthItemsFilterID] = useState('0');
  const [addUnidItemsFilterID, setAddUnidItemsFilterID] = useState('0');

  async function getDynamicPickitEntries() {
    const fetchingNotification = toast.loading(t('loading.fetchingData'));
    let url = config.backend_base_url + 'ml/pickit/dynamic/search';
    await axios
      .post(
        url,
        {
          term: sTerm,
          ethereal: parseInt(addEthItemsFilterID),
          unidentified: parseInt(addUnidItemsFilterID),
          type_id: parseInt(addTypeFilterID),
          rarity_id: parseInt(addRarityFilterID),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.data != null) {
          setSearchApplied(true);
          setDynamicPickitEntries(response.data);
        } else {
          setDynamicPickitEntries([]);
        }
        toast.dismiss(fetchingNotification);
        toast.success(t('success.doneLoadingPickit'));
      })
      .catch((error) => {
        setDynamicPickitEntries([]);
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  function updatePickitPrice(
    pickitID,
    newPrice,
    early,
    mid,
    late,
    nip,
    gid,
    keke,
    iNLine,
    keepLimit
  ) {
    const keepAsInt = parseInt(keepLimit);
    let url = config.backend_base_url + 'ml/pickit/dynamic/update';
    axios
      .post(
        url,
        {
          dynamic_pickit_id: pickitID,
          new_fg_price: newPrice,
          early_ladder_price: early,
          mid_ladder_price: mid,
          late_ladder_price: late,
          pickit_nip: nip,
          pickit_gid: gid,
          pickit_keke: keke,
          pickit_in: iNLine,
          keep_limit: keepAsInt,
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully updated [' + response.data + '] item prices');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  function updatePickitShopPriceFactor(pickitID, shopPriceFactor) {
    let url = config.backend_base_url + 'd2rs/product/pricefactor/update';
    axios
      .post(
        url,
        {
          dynamic_pickit_id: pickitID,
          shop_price_factor: parseInt(shopPriceFactor),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully updated price');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  function updateStatRank(statID, newRank) {
    let url = config.backend_base_url + 'ml/stat/rank/update';
    axios
      .post(
        url,
        {
          stat_id: parseInt(statID),
          new_rank: parseInt(newRank),
        },
        { headers: { 'session-token': sessionToken } }
      )
      .then((response) => {
        if (response.status) {
          toast.success('Successfully updated Stat rank');
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  async function getItemStats() {
    //todo: after template id change, call the url that gets the item stats by template, if template has variable stats only those will come, otherwise all stats will be returned there
    let url = config.backend_base_url + 'stats/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemStats(response.data);
          setAddStatFilterID(response.data[0].id);
        } else {
          setItemStats([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  async function getItemRarities() {
    let url = config.backend_base_url + 'rarities/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemRarities(response.data);
        } else {
          setItemRarities([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  async function getItemTypes() {
    let url = config.backend_base_url + 'types/all';
    await axios
      .get(url, { headers: { 'session-token': sessionToken } })
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          setItemTypes(response.data);
        } else {
          setItemTypes([]);
        }
      })
      .catch((error) => {
        console.log('Error ========>', error);
        toast.error(t('errors.refreshPage'));
      });
  }

  function getDynPickitSearchByEnter(event) {
    if (event.key === 'Enter') {
      getDynamicPickitEntries();
    }
  }

  let addStatRequirementToNewEntry = function () {
    let statName = '';
    itemStats.forEach(function (it) {
      if (it.id === addStatFilterID) {
        statName = it.name;
      }
    });

    setNewEntryStatFilters(
      newEntryStatFilters.concat({
        id: addStatFilterID,
        name: statName,
        min: parseInt(addStatFilterMin),
        max: parseInt(addStatFilterMax),
      })
    );
    setAddStatFilterID('');
    setItemStatsModal(false);
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        const headerComputedStyle = window.getComputedStyle(headerRef.current);
        const headerHeight = headerRef.current.offsetHeight;
        const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
        const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

        // Calculate total height including padding
        const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) * 2;

        setHeaderHeight(totalHeight);
      }
    };

    updateHeaderHeight(); // Initial call to set height

    const intervalId = setInterval(() => {
      updateHeaderHeight();
    }, 1000); // Run every second

    // Stop updating after 10 seconds
    setTimeout(() => {
      clearInterval(intervalId);
    }, 10000);

    window.addEventListener('resize', updateHeaderHeight); // Update height on resize

    return () => {
      clearInterval(intervalId); // Cleanup interval on unmount
      window.removeEventListener('resize', updateHeaderHeight); // Cleanup listener on unmount
    };
  }, []);

  if (loadData) {
    checkUserPermissions();
    getItemStats();
    getItemRarities();
    getItemTypes();
    getDynamicPickitEntries();
    getUsersItemPacks();
    getUsersCustomers();
    setLoadData(false);
  }

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            width: '80vw',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <Dialog
            disableEnforceFocus
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={addEntryModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddEntryModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setAddEntryModal(false)}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>{t('modals.pickitEntryDetails')}</h4>
            </DialogTitle>
          </Dialog>

          <Dialog
            disableEnforceFocus
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={itemStatsFilterModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setItemStatsModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setItemStatsModal(false)}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>{t('modals.itemStatsFilter')}</h4>
            </DialogTitle>
            <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
              <FormControl id="itemStatsFilterForm">
                <Select
                  id="itemStatFilter"
                  value={addStatFilterID}
                  onChange={(params) => {
                    setAddStatFilterID(params.target.value);
                  }}
                >
                  {itemStats.map((stat, key) => (
                    <MenuItem key={key} value={stat.id}>
                      {stat.name}
                    </MenuItem>
                  ))}
                </Select>
                <CustomInput
                  labelText={t('form.minValue')}
                  id="minValue"
                  value={addStatFilterMin}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(params) => {
                    setAddStatFilterMin(params.target.value);
                  }}
                  inputProps={{
                    type: 'number',
                  }}
                  required
                />
                <CustomInput
                  labelText={t('form.maxValue')}
                  id="maxValue"
                  value={addStatFilterMax}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(params) => {
                    setAddStatFilterMax(params.target.value);
                  }}
                  inputProps={{
                    type: 'number',
                  }}
                  required
                />
                <Button
                  color="primary"
                  type="submit"
                  form="itemStatsFilterForm"
                  onClick={addStatRequirementToNewEntry}
                >
                  {t('search.buttons.addStatFilter')}
                </Button>
              </FormControl>
            </DialogContent>
          </Dialog>
          <NavPills
            queryKey={'t'}
            alignCenter
            color="primary"
            onChange={handleTabChange}
            tabs={[
              {
                tabName: 'dynPickits',
                tabButton: t('search.tabs.dynamicPickitEntries', {
                  count: dynamicPickitEntries.length,
                }),
                tabContent: (
                  <div>
                    <GridContainer spacing={1} style={{ marginBottom: '12px' }}>
                      <GridItem md={3} xl={3}>
                        <Select
                          id="unidItemsFilter"
                          fullWidth={true}
                          value={addUnidItemsFilterID}
                          onChange={(params) => {
                            setAddUnidItemsFilterID(params.target.value);
                            setSearchApplied(false);
                          }}
                        >
                          <MenuItem value={'0'}>
                            <b>{t('search.filters.unidItemFilter')}</b>
                          </MenuItem>
                          {searchUnidItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} xl={3}>
                        <Select
                          id="ethItemsFilter"
                          fullWidth={true}
                          value={addEthItemsFilterID}
                          onChange={(params) => {
                            setAddEthItemsFilterID(params.target.value);
                            setSearchApplied(false);
                          }}
                        >
                          <MenuItem value={'0'}>
                            <b>{t('search.filters.ethItemFilter')}</b>
                          </MenuItem>
                          {searchEthItemsFilters.map((boolFilter, key) => (
                            <MenuItem key={key} value={boolFilter.id}>
                              {boolFilter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} xl={3}>
                        <Select
                          id="itemRarityFilter"
                          fullWidth={true}
                          value={addRarityFilterID}
                          onChange={(params) => {
                            setAddRarityFilterID(params.target.value);
                            setSearchApplied(false);
                          }}
                        >
                          <MenuItem value={'0'}>
                            <b>{t('search.filters.rarity')}</b>
                          </MenuItem>
                          {itemRarities.map((rar, key) => (
                            <MenuItem key={key} value={rar.id}>
                              {rar.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} xl={3}>
                        <Select
                          id="itemTypeFilter"
                          fullWidth={true}
                          value={addTypeFilterID}
                          onChange={(params) => {
                            setAddTypeFilterID(params.target.value);
                            setSearchApplied(false);
                          }}
                        >
                          <MenuItem value={'0'}>
                            <b>{t('search.filters.type')}</b>
                          </MenuItem>
                          {itemTypes.map((typ, key) => (
                            <MenuItem key={key} value={typ.id}>
                              {typ.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={1}>
                      <GridItem xs={12} sm={12} md={8} xl={8}>
                        <CustomInput
                          labelText={t('search.term')}
                          id="searchTerm"
                          value={sTerm}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onKeyDown={getDynPickitSearchByEnter}
                          onChange={(params) => {
                            setSTerm(params.target.value);
                            setSearchApplied(false);
                          }}
                          inputProps={{
                            type: 'text',
                            endAdornment: (
                              <InputAdornment position="end">
                                <Search className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} xl={4}>
                        <CustomInput
                          labelText={t('search.limit')}
                          id="limitFilter"
                          value={limitFilter}
                          onChange={(params) => {
                            setLimitFilter(params.target.value);
                            setSearchApplied(false);
                          }}
                          inputProps={{
                            type: 'number',
                            autoComplete: 'off',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={1}>
                      <GridItem xs={12} sm={12}>
                        <Button
                          color="primary"
                          fullWidth={true}
                          type="submit"
                          form="searchParamsForm"
                          onClick={getDynamicPickitEntries}
                        >
                          {t('search.buttons.applySearch')}
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12}>
                        {t('search.adjustResults', { count: dynamicPickitEntries.length })} <br />
                        <CustomInput
                          labelText={t('search.changeValue')}
                          id="changeValue"
                          value={bulkChangeValue}
                          onChange={(params) => {
                            setBulkChangeValue(params.target.value);
                          }}
                          inputProps={{
                            type: 'number',
                          }}
                        />{' '}
                        <br />
                        <Button
                          color="primary"
                          type="submit"
                          form="searchParamsForm"
                          onClick={getDynamicPickitEntries}
                          disabled={!searchApplied || true}
                        >
                          {t('search.buttons.increasePercent')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          form="searchParamsForm"
                          onClick={getDynamicPickitEntries}
                          disabled={!searchApplied || true}
                        >
                          {t('search.buttons.increaseFlat')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          form="searchParamsForm"
                          onClick={getDynamicPickitEntries}
                          disabled={!searchApplied || true}
                        >
                          {t('search.buttons.decreasePercent')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          form="searchParamsForm"
                          onClick={getDynamicPickitEntries}
                          disabled={!searchApplied || true}
                        >
                          {t('search.buttons.decreaseFlat')}
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                          <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            getRowWidth={() => 'auto'}
                            columns={dynamicPickitColumns}
                            rows={dynamicPickitEntries}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  id: false,
                                  early_ladder_price: false,
                                  mid_ladder_price: false,
                                  late_ladder_price: false,
                                  shop_price_factor: false,
                                  pickit_nip: false,
                                  pickit_gid: false,
                                  pickit_keke: false,
                                  pickit_in: false,
                                  keep_limit: false,
                                  shop_product_link: false,
                                },
                              },
                            }}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: 'stats',
                tabButton: t('search.tabs.statRanking', { count: itemStats.length }),
                tabContent: (
                  <div>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: '800px', width: '100%' }}>
                          <DataGrid
                            columns={statColumns}
                            rows={itemStats}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: 'packs',
                tabButton: t('search.tabs.itemPacks', { count: itemPacks.length }),
                tabContent: (
                  <div>
                    <GridItem xs={12} sm={12} md={12}>
                      <h2>{t('packs.descriptionTitle')}</h2>
                      <h3>{t('packs.descriptionSubtitle')}</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <form>
                        <input type="file" onChange={handleChange} />
                        <br />
                        <Button color={'primary'} fullWidth={true} onClick={handleSubmit}>
                          {t('search.buttons.uploadNewItemPacks')}
                        </Button>
                      </form>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4>{t('packs.yourPacks', { count: itemPacks.length })}</h4>
                    </GridItem>
                    <GridContainer justify="center">
                      {itemPacks.map((pack, key) => (
                        <GridItem xs={12} sm={12} md={12} key={key}>
                          <b>{pack.title}</b>
                          {pack.entries.map((en, key) => (
                            <i key={key}>
                              {key === 0 ? '' : ', '}
                              {en.limit}x {'"'}
                              {en.search_term}
                              {'"'}
                            </i>
                          ))}
                        </GridItem>
                      ))}
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: 'customers',
                tabButton: t('search.tabs.customers', { count: customers.length }),
                tabContent: (
                  <div>
                    <GridItem xs={12} sm={12} md={12}>
                      <h2>{t('customers.descriptionTitle')}</h2>
                      <h3>{t('customers.descriptionSubtitle')}</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <br />
                      <form>
                        <input type="file" onChange={handleChange} />
                        <br />
                        <Button color={'primary'} fullWidth={true} onClick={handleCustomersSubmit}>
                          {t('search.buttons.uploadNewCustomerData')}
                        </Button>
                      </form>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4>{t('customers.yourCustomers', { count: customers.length })}</h4>
                    </GridItem>
                    <GridContainer justify="center">
                      {customers.map((cust, key) => (
                        <GridItem xs={12} sm={12} md={12} key={key}>
                          <b>{cust.label}</b>:
                          {' ' + cust.game_realm + '/' + cust.game_name + '/' + cust.game_password}
                          <br />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BubbleContainer pageKey="presetPage" helpText={t('help.presetPage')} />
    </div>
  );
}
