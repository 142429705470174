export interface Offer {
  id: string;
  item_id: number;
  item_name: string;
  item_image: string;
  offer: string;
  status: 'pending' | 'accepted' | 'denied';
  shoplink: string;
  discordid: string;
  discord_id: string;
  context: string;
  created_at: string;
  amount: string;
}

export interface DetailPageData {
  id: string;
  title: string;
  context: string;
  imagePath: string;
  skinImagePath?: string;
  shopLink?: string;
  price?: string;
  description?: string;
  canMakeOffer: boolean;
  showViewInShop: boolean;
  offers?: Offer[];
  status?: 'available' | 'in_cart' | 'sold' | 'in_drop_order';
  node_status?: string;
  node_name?: string;
  isFavorite?: boolean;
}

export interface ItemDetailResponse {
  id: number;
  context: string;
  suffix: string;
  skin_name: string;
  d2rshopproductid: string;
  d2rshopproductlink: string;
  name: string;
  history_text: string;
  min_price: string;
  image_path: string;
  skin_image_path: string;
  stats_description: string;
  ethereal: number;
  fiat_price: string;
  fg_price: string;
  status: string;
  unid: number;
  account_id: number;
  char_name: string;
  reseller_name: string;
  created_at: string;
  carted_at: string;
  sold_by: string;
  shareholder_name: string;
  node_name: string;
  node_status: string;
  note: string;
  original_rarity: string;
  ocr_string: string;
  shareholder_id: number;
  node_id: number;
  prescreened: number;
  marked_as_trash: number;
  upped: number;
  double_upped: number;
  offers?: Offer[];
}

export interface ProductDetailResponse {
  id: number;
  node_id: number;
  shop_product_link: string;
  shop_product_name: string;
  shop_price_factor: number;
  title: string;
  search_equivalent: string;
  search_term: string;
  rarity_id: number;
  type_id: number;
  ethereal: number;
  unid: number;
  fiat_only: number;
  keep_limit: number;
  fg_price: string;
  early_ladder_price: string;
  mid_ladder_price: string;
  late_ladder_price: string;
  last_update_user_id: number;
  pickit_nip: string;
  pickit_gid: string;
  pickit_keke: string;
  pickit_in: string;
  skin_image_path: string;
  product_image_path: string;
  ladder: number;
  hardcore: number;
  stats: null | Array<{ stat_description?: string }>;
  created_at: string;
  updated_at: string;
}

export function mapItemToDetailPageData(response: ItemDetailResponse): DetailPageData {
  return {
    id: response.id.toString(),
    title: response.name,
    context: response.context,
    imagePath: `https://d2r-member.com/image/${response.image_path}`,
    skinImagePath: response.skin_image_path
      ? `https://d2r-member.com/skin/${response.skin_image_path}`
      : undefined,
    shopLink: response.d2rshopproductlink,
    price: response.fiat_price?.replaceAll('.00', ''),
    description: response.stats_description,
    canMakeOffer: true,
    showViewInShop: !!response.d2rshopproductlink,
    node_status: response.node_status,
    node_name: response.node_name,
    status: (response.status || 'available') as DetailPageData['status'],
    offers: response.offers?.map((offer) => ({
      id: offer.id.toString(),
      item_id: response.id,
      item_name: response.name,
      item_image: response.image_path,
      offer: offer.offer,
      status: (offer.status || 'pending') as Offer['status'],
      shoplink: response.d2rshopproductlink,
      discordid: offer.discordid || '',
      discord_id: offer.discordid || '',
      context: response.context,
      created_at: offer.created_at,
      amount: offer.offer,
    })),
  };
}

export function mapProductToDetailPageData(response: ProductDetailResponse): DetailPageData {
  return {
    id: response.id.toString(),
    title: response.shop_product_name,
    context: '',
    imagePath: `https://d2r-member.com/image/${response.product_image_path}`,
    skinImagePath: response.skin_image_path
      ? `https://d2r-member.com/skin/${response.skin_image_path}`
      : undefined,
    shopLink: response.shop_product_link,
    price: response.fg_price?.replaceAll('.00', ''),
    description: response.title,
    canMakeOffer: false,
    showViewInShop: true,
  };
}
